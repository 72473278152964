import { useStaticQuery, graphql } from 'gatsby';
/**
 * Loads all the navigations
 */
export const useNavigations = () => {
   const queries = useStaticQuery(
      graphql`
         query {
            allMatixMdx(filter: { frontmatter: { type: { eq: "navigations" } } }) {
               nodes {
                  locale
                  ...NavigationThemeGroupeBt
               }
            }
         }
      `
   );

   return queries;
};
