import React from 'react';
import c from 'classnames';

export const CarouselHeroSectionWrapper = (props) => {
   return (
      <section id={'CarouselHero'} {...props} className={c(props.className ?? '', 'mt-8')}>
         {props.children}
      </section>
   );
};
