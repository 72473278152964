import React from 'react';
import { MdStringRenderer } from '../../../../md-string-renderer';
import { ImageProps, Image } from '../../../../image';
import { Link } from '../../../../link';
interface CardProps {
   title: string;
   text: string;
   btnMainMd: string;
   image: ImageProps;
}

export const Card = (props: CardProps) => {
   const urlFromTitle = props.title?.match && props.title?.match(/\(([^)]+)\)+$/);

   if (urlFromTitle && urlFromTitle.length) {
      return (
         <Link to={urlFromTitle[1]}>
            <Result {...props} />
         </Link>
      );
   } else {
      return <Result {...props} />;
   }
};

const Result = (props: any) => {
   return (
      <div className="group flex items-center justify-center relative h-60 md:h-80 shadow-2xl transition-all transform duration-500 ease-in-out hover:scale-95">
         <div className="absolute inset-0 z-10 w-full bg-primary-light bg-opacity-50 h-full group-hover:bg-primary group-hover:bg-opacity-50" />
         {props.title ? (
            <div className="z-10 px-10 font-bold text-primary text-4xl md:text-6xl group-hover:z-20">
               <MdStringRenderer source={props.title} />
            </div>
         ) : null}
         {props.image ? (
            <div className="absolute inset-0 w-full">
               <Image {...props.image} className="object-cover w-full h-full" />
            </div>
         ) : null}
      </div>
   );
};
export default Card;
