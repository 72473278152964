import { MdStringRenderer } from '../../../../md-string-renderer';
import { ImageWithTextProps } from '../image-with-text.section-renderer';
import { Image } from '../../../../image';
import React, { useState, useRef } from 'react';
import c from 'classnames';
import { useTrail, a, useSpring, animated } from '@react-spring/web';

const Trail: React.FC<{ open: boolean }> = ({ open, children }) => {
   const items = React.Children.toArray(children);
   const trail = useTrail(items.length, {
      config: { mass: 5, tension: 1000, friction: 500 },
      opacity: open ? 1 : 0,
      x: open ? 0 : 20,
      height: open ? 75 : 0,
      from: { opacity: 0, x: 20, height: 0 },
      delay: 1000,
   });
   return (
      <div>
         {trail.map(({ height }, index) => (
            <a.div key={index} className="overflow-hidden relative w-full transition-all">
               <a.div style={{ height }}>{items[index]}</a.div>
            </a.div>
         ))}
      </div>
   );
};

export const ImageWithText = (props: ImageWithTextProps) => {
   const { imageWithTextImages, text, title, position = 'bottom', btnMainMd } = props;
   const n = useRef(0);
   const styles = useSpring({
      from: { x: -100, opacity: 0 },
      to: { x: 0, opacity: 1 },
      config: { duration: 1000 },
   });
   return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
         <div className="flex flex-col my-5">
            {title ? (
               <>
                  <div className="text-2xl md:text-4xl text-center mb-6">
                     <MdStringRenderer source={title} />
                  </div>
                  <div className="border-primary-200 xl:border-opacity-75 border-t-2 w-1/2 mb-5 mx-auto shadow-lg h-0"></div>
               </>
            ) : null}
            <div className={c('flex flex-row md:bg-transparent xl:mb-5 xl:pt-0 ', position === 'right' ? 'md:flex-row-reverse' : '')}>
               <div className="flex items-center flex-wrap xl:justify-between justify-center w-full gap-2 xl:w-2/3 mx-auto">
                  {imageWithTextImages.map((image) => (
                     <div className="object-cover w-40 y-40">
                        <animated.div style={{ ...styles }}>
                           <Image {...image} className="mx-auto" />
                           {image.title ? (
                              <div className="text-center">
                                 <MdStringRenderer source={image.title} />
                              </div>
                           ) : null}
                        </animated.div>
                     </div>
                  ))}
               </div>
            </div>
         </div>
      </>
   );
};
