import React from 'react';
import { SlideCarouselSpringProps } from '../interface';
import { Image } from '../../../image';
export interface ThumbnailDefaultProps extends React.HTMLAttributes<HTMLElement>, SlideCarouselSpringProps {}
export const ThumbnailSpring1 = (props: ThumbnailDefaultProps) => {
   return (
      <div className="w-20 h-20 my-2 md:mb-5 cursor-pointer" style={{ touchAction: 'pan-x', userSelect: 'none' }} onClick={props.onClick}>
         <Image className="h-full w-full object-fit" {...props.image} />
      </div>
   );
};
