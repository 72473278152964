import React, { useEffect, useState } from 'react';
import algoliasearch, { SearchIndex } from 'algoliasearch/lite';
import produce from 'immer';
import { useDebounce } from '@matix/gatsby-theme-core';

export const useAlgolia = (appId, apiKey, indexName) => {
   const initialState = {
      client: null,
      index: null,
      searchTerm: '',
      searchInit: false,
      isSearching: false,
      searchResults: { hits: [], nbHits: 0 },
   } as {
      index: SearchIndex;
      searchTerm: string;
      isSearching: boolean;
      searchInit: boolean;
      searchResults: { hits: []; nbHits: number };
   };
   const [state, updateState] = useState(initialState);
   const debounceSearchTerm = useDebounce(state.searchTerm, 500);

   useEffect(() => {
      const client = algoliasearch(appId, apiKey);
      const index = client.initIndex(indexName);
      updateState(
         produce((draft) => {
            draft.client = client;
            draft.index = index;
         })
      );
   }, [appId, apiKey, indexName]);

   useEffect(
      () => {
         if (state.index) {
            updateState(
               produce((draft) => {
                  draft.searchInit = true;
                  if (debounceSearchTerm === '') {
                     draft.searchInit = false;
                     draft.searchResults = { hits: [] };
                  }
                  draft.isSeaching = true;
               })
            );
            if (debounceSearchTerm) {
               state.index.search(debounceSearchTerm).then((r) => {
                  updateState(
                     produce((draft) => {
                        draft.isSeaching = false;
                        draft.searchResults = r;
                     })
                  );
               });
            }
         }
      },
      [debounceSearchTerm, state.index] // Only call effect if debounced search term changes
   );

   return {
      isSearching: () => state.isSearching,
      searchResults: state.searchResults,
      hasNoResults: () => {
         return state.searchInit && state.searchTerm !== '' && !state.isSearching && state.searchResults?.nbHits === 0;
      },
      search: (query) => {
         updateState(
            produce((draft) => {
               draft.searchTerm = query;
            })
         );
      },
   };
};
