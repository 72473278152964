import React from 'react';
import { MdStringRenderer } from '../../../../md-string-renderer';
import { ImageProps, Image } from '../../../../image';
import { Link } from '../../../../link';

interface CardProps {
   title: string;
   text: string;
   btnMainMd: string;
   image: ImageProps;
}

export const Card = (props: CardProps) => {
   const urlFromTitle = props.title?.match && props.title?.match(/\(([^)]+)\)+$/);

   if (urlFromTitle && urlFromTitle.length) {
      return (
         <Link to={urlFromTitle[1]}>
            <Result {...props} />
         </Link>
      );
   } else {
      return <Result {...props} />;
   }
};

const Result = (props: CardProps) => {
   return (
      <div className="flex flex-col hover:shadow-2xl h-96">
         {/* Card Header */}
         <div className="relative border-1 border-black h-full">
            {props.title ? (
               <div className="absolute w-full text-center flex flex-col p-3 z-10 bottom-0 text-white">
                  {props.title ? (
                     <div className="bg-black opacity-80 p-2 mb-8">
                        <MdStringRenderer source={props.title} />
                     </div>
                  ) : null}
               </div>
            ) : null}

            <Image {...props.image} className="object-cover w-full h-full" />
         </div>
      </div>
   );
};
export default Card;
