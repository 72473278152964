import React from 'react';
import Loadable from '@loadable/component';
import { FormWithImageAndText } from './forms-with-text/1/form-with-text';
import Fade from 'react-reveal/Fade';

const Form1 = Loadable(() => import(/* webpackPrefetch: true */ './forms/1/form'));
const Form2 = Loadable(() => import(/* webpackPrefetch: true */ './forms/2/form'));
const Form3 = Loadable(() => import(/* webpackPrefetch: true */ './forms/3/form'));

export type FontSize = 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | '7xl';
export type TextAlignment = 'left' | 'justify' | 'right' | 'center';
export type Position = 'top' | 'bottom' | 'right' | 'left';
export type FormStates = { formValue; formState; setFormValueI: FormBaseProps['setFormValueI']; setFormStateI: FormBaseProps['setFormStateI'] };
export interface FormProps {
   name: string;
   title: string;
   fields: FieldProps[];
   sendButtonTitle: string;
   cancelButtonTitle: string;
   successMessage: string;
   errorMessage: string;
   requiredMsg: string;
   className?: string;
}
export interface FormHandlerOverride {
   handleSubmitOverride?: (e, states?: FormStates) => void;
   handleChangeOverride?: (e, key, states?: FormStates) => void;
}
export interface FormBaseProps extends FormProps {
   handleChange: (e, key, states?: FormStates) => void;
   setFormValueI: (value: any) => void;
   setFormStateI: (state: any) => void;
   formState: any;
   formValue: any;
}

export interface FieldProps {
   type: string;
   key: string;
   required: boolean;
   placeHolder: string;
   className?: string;
   label: string;
   inputType: string;
   numberOfLine: string;
   multipleFile: boolean;
   options: SelectOption[];
   shopifyId: string;
}

export interface SelectOption {
   value: string;
   product: ProductOptions;
}
export interface ProductOptions {
   label: string;
   shopifyId: string;
}

export const FormSectionRenderer = (props) => {
   if (props.type === 'formWithText') {
      return <Fade>{props.style === '1' && <FormWithImageAndText {...props} />}</Fade>;
   } else {
      return (
         <>
            {props.form?.frontmatter?.style === '1' && <Form1 {...props.form.frontmatter} />}
            {props.form?.frontmatter?.style === '2' && <Form2 {...props.form.frontmatter} />}
            {props.form?.frontmatter?.style === '3' && <Form3 {...props.form.frontmatter} />}
         </>
      );
   }
};
export default FormSectionRenderer;
