import React from 'react';
import { WidgetRegistration } from '@matix/gatsby-theme-core';
import Loadable from '@loadable/component';
const EventRenderer = Loadable(() => import(/* webpackPrefetch: true */ './event/event-renderer'));
const CardEventSectionRenderer = Loadable(() => import(/* webpackPrefetch: true */ './cardEvents/card-event.renderer'));
export const registerWidgets = (widgetRegistration: WidgetRegistration) => {
   return widgetRegistration
      .register('blogEvent', (props) => <EventRenderer {...props} />)
      .register('cardEvent', (props) => <CardEventSectionRenderer {...props} />);
};
