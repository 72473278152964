import React from 'react';
import { MdStringRenderer, Link, Phone, Envelope, Marker, FormSectionRenderer, Fax, Social } from '@matix/gatsby-theme-core';

export const renderNavItemLeaf = (leaf) => {
   const pagePath = leaf[leaf.type]?.frontmatter?.pagePath;
   switch (leaf.type) {
      case 'phone':
         return (
            <div className="flex mx-auto md:mx-0 hover:opacity-90">
               <Phone className="h-4 w-4 mr-4 flex-initial" />
               <div className="flex-initial">
                  <Link to={`tel:${leaf.title}`}>{leaf.title}</Link>
               </div>
            </div>
         );
      case 'fax':
         return (
            <div className="flex items-center py-2 md:mx-0 hover:underline">
               <Fax className="h-6 w-6 mr-2 flex-initial" />
               <div className="flex-initial">
                  <Link to={`tel:${leaf.title}`}>{leaf.title}</Link>
               </div>
            </div>
         );
      case 'email':
         return (
            <div className="flex mx-auto md:mx-0 hover:opacity-90">
               <Envelope className="flex-initial h-4 w-4 mr-4" />
               <div className="flex-initial">
                  <Link to={`${leaf.title}`}>{leaf.title}</Link>
               </div>
            </div>
         );
      case 'address':
         return (
            <div className="flex flex-wrap justify-center mx-auto md:mx-0 md:justify-start hover:opacity-90">
               <Marker className="flex-initial flex-shrink-0 h-4 w-4 mr-4" />
               <div className="flex-initial">
                  <MdStringRenderer className="flex-initial" source={leaf.title} />
               </div>
            </div>
         );
      case 'linkSocial':
         return (
            <div className="flex items-center py-2 xl:mx-0 hover:underline">
               <div className="flex-initial bg-white">
                  <Link to={`${leaf.link}`}>
                     <Social className="flex-initial h-4 w-4 mr-4" iconName={leaf.title} />
                  </Link>
               </div>
            </div>
         );
      case 'link':
         return <MdStringRenderer className="flex-1 md:flex-initial hover:opacity-90" source={`[${leaf.title}](${leaf.link})`} />;
      case 'form':
         return (
            <div className="">
               <MdStringRenderer source={leaf.title} />
               <FormSectionRenderer {...leaf} />
            </div>
         );
      case 'collection':
      case 'article':
      case 'page':
      case 'policy':
         // something with a pagePath
         return (
            <Link className="flex-1 md:flex-initial hover:opacity-90" to={`${pagePath}`}>
               {leaf.title}
            </Link>
         );
      default:
         return null;
   }
};
