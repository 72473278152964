import React from 'react';
import { MdStringRenderer } from '../../../../md-string-renderer';
import { ImageProps, Image } from '../../../../image';
import { CardsInternalProps } from '../card.section-renderer';
import { Link } from '../../../../link';

export const Card = (props: CardsInternalProps) => {
   const urlFromBtn = props.btnMainMd?.match(/\(([^)]+)\)+$/);

   if (urlFromBtn && urlFromBtn.length) {
      return (
         <Link to={urlFromBtn[1]}>
            <Result {...props} />
         </Link>
      );
   } else {
      return <Result {...props} />;
   }
};

const Result = (props: CardsInternalProps) => {
   return (
      <div className="p-3 relative flex flex-col md:mx-2 xl:mx-10 text-justify">
         <div className="flex items-center">
            {props.image && props.image.url ? (
               <div className="w-16 h-16 mx-auto">
                  <Image {...props.image} className="object-cover object-center h-full" />
               </div>
            ) : (
               <div className="w-10 h-10 border-2 border-primary-500 text-primary-500 rounded-full grid place-content-center">{props.idx}</div>
            )}

            {props.title ? (
               <div className="flex-1 py-2 ml-4 text-xl text-left">
                  <MdStringRenderer source={props.title} />
               </div>
            ) : null}
         </div>
         {props.text ? (
            <div className="flex-grow mt-5">
               <MdStringRenderer source={props.text} />
            </div>
         ) : null}
         {props.btnMainMd ? (
            <div className="flex-none py-2 mx-auto flex items-center">
               <button className="my-5 py-2 px-6 rounded-md bg-primary-light text-primary-light hover:bg-secondary-dark hover:text-secondary-dark hover:border-secondary-dark">
                  <MdStringRenderer source={props.btnMainMd} />
               </button>
            </div>
         ) : null}
      </div>
   );
};
export default Card;
