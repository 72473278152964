import { useStaticQuery, graphql } from 'gatsby';
/**
 * Loads all the navigations
 */
export const useSiteMeta = () => {
   const queries = useStaticQuery(
      graphql`
         query {
            allMatixSiteMeta(filter: { frontmatter: { type: { eq: "siteMeta" } } }) {
               nodes {
                  locale
                  frontmatter {
                     companyName
                     banner
                     shopifyShopName
                     shopifyStoreAccessToken
                     siteUrl
                     companySloguan
                     phone
                     postalAddress
                     email

                     seo {
                        titleSeo
                        descriptionSeo
                        keywords
                        defaultAlt
                     }

                     siteMetaImage {
                        logoImage {
                           url
                        }
                        faviconImage {
                           url
                        }
                     }

                     social {
                        socialImage {
                           url
                        }
                        shareNetworks
                        shareThisPropertyId
                        showOnPageTypes
                        defaultShareSubject
                        defaultShareMessage
                        twitterUsername
                        shareText
                     }
                  }
               }
            }
         }
      `
   );

   return queries;
};
