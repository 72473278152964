import React from 'react';

export const Duration = (props: React.SVGProps<SVGSVGElement>) => {
   return (
      <div>
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" {...props}>
            <g id="Layer_1">
               <path
                  d="M11,40.485V47H7v2h36v-2h-4v-6.515c0-1.871-0.729-3.628-2.051-4.95L26.414,25l10.535-10.535
		C38.271,13.143,39,11.385,39,9.515V3h4V1H7v2h4v6.515c0,1.871,0.729,3.628,2.051,4.95L23.586,25L13.051,35.535
		C11.729,36.857,11,38.615,11,40.485z M14.465,13.05C13.521,12.106,13,10.851,13,9.515V3h24v6.515c0,1.336-0.521,2.592-1.465,3.536
		L25,23.586L14.465,13.05z M25,26.414L35.535,36.95C36.479,37.894,37,39.149,37,40.485V47H13v-6.515
		c0-1.336,0.521-2.592,1.465-3.536L25,26.414z"
               />
            </g>
         </svg>
      </div>
   );
};
