import React from 'react';
import { MdStringRenderer } from '../../../../md-string-renderer';
import { ImageProps, Image } from '../../../../image';
import { CardsInternalProps } from '../card.section-renderer';
import { Link } from '../../../../link';

export const Card = (props: CardsInternalProps) => {
   return (
      <div className="h-full relative flex flex-col space-y-4 md:mx-2 xl:mx-10 text-primary-light">
         {/* header */}
         {props.title ? (
            <div className="py-2 text-xl md:text-2xl">
               <MdStringRenderer source={props.title} />
            </div>
         ) : null}

         {/* img */}
         {props.image && props.image.url ? <Img {...props} /> : null}

         {/* body */}
         {props.text ? (
            <div className="flex-grow text-base 2xl:text-xl">
               <MdStringRenderer source={props.text} />
            </div>
         ) : null}

         {/* footer */}
         {props.btnMainMd ? (
            <div className="flex-none font-semibold py-2 my-5 underline text-right">
               <MdStringRenderer source={props.btnMainMd} />
            </div>
         ) : null}
      </div>
   );
};

const Img = (props: any) => {
   const urlFromBtn = props.btnMainMd?.match(/\(([^)]+)\)+$/);
   if (urlFromBtn && urlFromBtn.length) {
      return (
         <Link to={urlFromBtn[1]}>
            <div className="md:h-72 xl:h-96">
               <Image {...props.image} className="object-cover object-center h-full" />
            </div>
         </Link>
      );
   } else {
      return (
         <div className="md:h-72 xl:h-96">
            <Image {...props.image} className="object-cover object-center h-full" />
         </div>
      );
   }
};
export default Card;
