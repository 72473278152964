import { useStaticQuery, graphql } from 'gatsby';
/**
 * Loads all the translations that are now resolved at buildtime
 */
export const useGlobalTranslation = () => {
   const queries = useStaticQuery(
      graphql`
         query {
            allMatixTranslation(filter: { frontmatter: { identifier: { eq: "global-translation" } } }) {
               edges {
                  node {
                     locale
                     frontmatter {
                        identifier
                        translations {
                           identifier
                           value
                        }
                     }
                  }
               }
            }
         }
      `
   );

   return queries;
};
