export const extractChildMatixAzureImage: any = (data, childMatixAzureImages: any) => {
   if (!data) return;

   for (const i in data) {
      if (!!data[i] && !!data[i].childMatixAzureImage) {
         childMatixAzureImages.push(data[i].childMatixAzureImage);
      }
      if (!!data[i] && typeof data[i] === 'object') {
         extractChildMatixAzureImage(data[i], childMatixAzureImages);
      } else if (!!data[i] && Array.isArray(data[i])) {
         extractChildMatixAzureImage(data[i], childMatixAzureImages);
      }
   }
   return childMatixAzureImages;
};
