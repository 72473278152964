import React from 'react';
import { MdStringRenderer } from '../../../../md-string-renderer';
import { ImageProps, Image } from '../../../../image';
import { Link } from '../../../../link';
import c from 'classnames';
interface CardProps {
   title: string;
   text: string;
   btnMainMd: string;
   image: ImageProps;
}

export const Card = (props: CardProps) => {
   const urlFromBtn = props.btnMainMd?.match(/\(([^)]+)\)+$/);
   const transitionClass = 'transition-all transform duration-500 ease-in-out';
   return (
      <div
         className={c(
            'group h-full relative flex flex-col text-justify md:mx-2 xl:mx-32 px-5 hover:bg-secondary-light hover:text-secondary-light hover:bg-opacity-80',
            transitionClass
         )}
      >
         {urlFromBtn && urlFromBtn.length ? (
            <Link to={urlFromBtn[1]}>
               <div className="rounded-full mx-auto h-40 w-40 overflow-hidden">
                  <Image {...props.image} className={c('object-cover  group-hover:scale-110', transitionClass)} />
               </div>
            </Link>
         ) : (
            <div className="rounded-full mx-auto h-40 w-40 overflow-hidden">
               <Image {...props.image} className={c('object-cover rounded-full h-40 w-40  group-hover:scale-110', transitionClass)} />
            </div>
         )}
         {props.title ? (
            <div className="py-4 text-2xl text-center font-serif">
               <MdStringRenderer source={props.title} />
            </div>
         ) : null}

         {props.text ? (
            <div className="flex-grow py-4 text-base text-center">
               <MdStringRenderer source={props.text} />
            </div>
         ) : null}
         {props.btnMainMd ? (
            <div className="flex-none py-2 mx-auto flex items-center">
               <button className="py-2 px-6 rounded-md bg-primary-dark text-primary-dark">
                  <MdStringRenderer source={props.btnMainMd} />
               </button>
            </div>
         ) : null}
      </div>
   );
};

export default Card;
