import React from 'react';

export const GenericInputRenderer = ({ field, formValue, handleChange }) => {
   return (
      <input
         className={
            'appearance-none bg-formfield text-formfield block w-full border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-formfield-focus focus:text-formfield-focus'
         }
         name={field.label}
         type={field.inputType}
         placeholder={field.placeholder}
         value={formValue[field.key]}
         onChange={(e) => handleChange(e, field.key)}
      />
   );
};
