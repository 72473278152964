import { useStaticQuery, graphql } from 'gatsby';
/**
 * Loads all the navigations
 */
export const useCollectionStructure = () => {
   const queries = useStaticQuery(
      graphql`
         query {
            allMatixShopifyCollection(filter: { frontmatter: { shopifyCollectionParentId: { eq: null } } }) {
               nodes {
                  locale
                  frontmatter {
                     pagePath
                     identifier
                     headline {
                        title
                     }
                     childCollection {
                        ...collectionPageInfoThemeShopify
                        frontmatter {
                           childCollection {
                              ...collectionPageInfoThemeShopify
                              frontmatter {
                                 childCollection {
                                    ...collectionPageInfoThemeShopify
                                    frontmatter {
                                       childCollection {
                                          ...collectionPageInfoThemeShopify
                                       }
                                    }
                                 }
                              }
                           }
                        }
                     }
                  }
               }
            }
         }
      `
   );

   return queries;
};
