import React from 'react';
import { WidgetRegistration } from '@matix/gatsby-theme-core';
import Loadable from '@loadable/component';
const ProductCard = Loadable(() => import(/* webpackPrefetch: true */ './widgets/product-card/product-card.renderer'));
const ProductList = Loadable(() => import(/* webpackPrefetch: true */ './widgets/product-list/product-list.renderer'));
export const registerWidgets = (widgetRegistration: WidgetRegistration) => {
   return widgetRegistration
      .register('shopifyProductCard', (props) => <ProductCard {...props} />)
      .register('shopifyProductList', (props) => <ProductList {...props} />);
};
