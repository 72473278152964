import React from 'react';
import c from 'classnames';
import { Card } from './card';
import { useFilter } from './filterContext';
export const GroupCards = (props) => {
   const { products } = props;
   const { filter } = useFilter();
   return (
      <div className="grid gap-4 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:xl:grid-cols-4 md:pl-4 min-h-60">
         {products &&
            products.map((card, idx: number) => {
               return (
                  <Card
                     key={card.frontmatter.identifier}
                     {...card.frontmatter}
                     {...idx}
                     currentParentCollectionNames={props.currentParentCollectionNames}
                  />
               );
            })}
      </div>
   );
};
