import React, { useState, useRef, useContext } from 'react';
import { MdStringRenderer, Image } from '@matix/gatsby-theme-core';
import c from 'classnames';
import { useTrail, a, useSpring, animated } from '@react-spring/web';

const Trail: React.FC<{ open: boolean }> = ({ open, children }) => {
   const items = React.Children.toArray(children);
   const trail = useTrail(items.length, {
      config: { mass: 5, tension: 1000, friction: 500 },
      opacity: open ? 1 : 0,
      x: open ? 0 : 20,
      height: open ? 75 : 0,
      from: { opacity: 0, x: 20, height: 0 },
      delay: 1000,
   });
   return (
      <div>
         {trail.map(({ height }, index) => (
            <a.div key={index} className="overflow-hidden relative w-full transition-all">
               <a.div style={{ height }}>{items[index]}</a.div>
            </a.div>
         ))}
      </div>
   );
};

export const HeadlineShop = (props) => {
   // const textAnimated = props.headline?.subTitle?.split('.');
   const [open, set] = useState(true);

   const n = useRef(0);
   const styles = useSpring({
      from: { x: -100, opacity: 0 },
      to: { x: 0, opacity: 1 },
      config: { duration: 2000 },
   });
   return (
      <div className="px-2 md:px-10 mt-14 md:mt-28">
         <div className="flex flex-col mt-5">
            {props.headline?.image?.url ? (
               <div className={c('h-full flex flex-col md:flex-row md:bg-transparent xl:mb-5 xl:pt-0')}>
                  <div className="flex items-stretch justify-center h-full w-full md:w-1/2 xl:w-1/2">
                     <div className="object-cover w-80 h-full">
                        <animated.div style={{ ...styles }}>
                           <Image {...props.headline.image} className="mx-auto" />
                        </animated.div>
                     </div>
                  </div>
                  {props.headline?.subTitle ? (
                     <div
                        className={c(
                           'self-stretch p-5 mx-auto md:w-full xl:w-1/2 bg-primary-dark text-secondary-dark mt-2 md:mt-0 text-base xl:text-2xl'
                        )}
                     >
                        {/* <Trail open={open}> */}
                        {/* {textAnimated.map((x) => {
                                 if (x) { */}
                        <MdStringRenderer source={props.headline?.subTitle} />
                        {/* </Trail> */}
                     </div>
                  ) : null}
               </div>
            ) : null}
         </div>
      </div>
   );
};
