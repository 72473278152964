// The image overlay offers different control to show image in fullscreen, show social buttons, like, etc.
// the theme core
import { useStore } from '@matix/gatsby-theme-core';
import c from 'classnames';
import React from 'react';
import { InlineShareButtons } from 'sharethis-reactjs';
export const ShareThis = (props) => {
   const {
      store: { siteMeta, pageQuery },
   } = useStore();
   const { image } = props;
   const showSharingButtons = siteMeta.social?.showOnPageTypes?.indexOf(pageQuery?.frontmatter?.type) > -1;
   return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
         {showSharingButtons && (
            <div className={c('z-50 p-10', props.custom ? 'custom-share' : '')}>
               <InlineShareButtons
                  config={{
                     alignment: 'center',
                     color: 'white',
                     enabled: true,
                     font_size: 16,
                     labels: null,
                     language: pageQuery?.locale,
                     // min_count: 0,
                     networks: siteMeta?.social?.shareNetworks,
                     padding: 4,
                     radius: 4,
                     show_total: false,
                     // show_mobile: true,
                     // show_toggle: true,
                     size: 48,
                     // top: 400,

                     url: pageQuery.frontmatter?.pagePath ? siteMeta.siteUrl + pageQuery.frontmatter?.pagePath : siteMeta.siteUrl,
                     image: pageQuery.frontmatter?.image?.url ?? image?.url ?? siteMeta?.social?.socialImage?.url, // TODO: check to get hero image, then any other image first found in components?
                     title: image?.title ?? siteMeta.seo.titleSeo,
                     message: siteMeta.social?.defaultShareMessage ?? siteMeta.seo?.descriptionSeo, // (only for email sharing)
                     subject: siteMeta.social?.defaultShareSubject ?? siteMeta.companyName, // (only for email sharing)
                  }}
               />
            </div>
         )}
      </>
   );
};
