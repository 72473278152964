import React from 'react';
import { MdStringRenderer } from '@matix/gatsby-theme-core';
import { Image, ImageProps } from '@matix/gatsby-theme-core';

export const Carousel = ({ mainTitle, subTitle, btnMainMd, btnSecondaryMd, image }: CarouselProps) => {
   return (
      <div className="relative w-full md:h-max bg-white overflow-hidden select-none">
         <div className="relative z-10 w-full h-full">
            <div className="flex flex-col-reverse md:flex-row items-center shadow-xl h-full">
               <div className="w-full md:w-1/2">
                  <div className="flex flex-col items-center justify-center text-center font-serif">
                     <div className="text-3xl md:text-7xl font-bold tracking-tight py-2 xl:py-6 text-primary-light w-full">
                        <MdStringRenderer source={mainTitle} />
                     </div>
                     {subTitle ? (
                        <div className="py-2 xl:py-6 text-2xl text-primary-light italic md:text-2xl">
                           <MdStringRenderer source={subTitle} />
                        </div>
                     ) : null}
                     {btnMainMd ? (
                        <div className="rounded-md py-0 md:py-2">
                           <div className="flex items-center ">
                              <button
                                 type="button"
                                 className="p-1 px-4 md:px-5 md:py-3 font-semibold rounded-md bg-primary-500 text-secondary-500 hover:bg-secondary-500 hover:text-primary-500 hover:border-primary-500"
                              >
                                 <MdStringRenderer source={btnMainMd} />
                              </button>
                           </div>
                        </div>
                     ) : null}
                  </div>
                  {btnSecondaryMd ? (
                     <div className="rounded-md shadow">
                        <div className="flex items-center ">
                           <button
                              type="button"
                              className="border-transparent p-3 px-5 sm:px-8 py-3 rounded-md bg-primary-500 text-secondary-500 border-2 border-secondary-500 hover:bg-secondary-500 hover:text-primary-500 hover:border-primary-500"
                           >
                              <MdStringRenderer source={btnSecondaryMd} />
                           </button>
                        </div>
                     </div>
                  ) : null}
               </div>
               <div className="w-full md:w-1/2 h-full">
                  <Image className="object-cover h-full w-full" {...{ loading: 'lazy', fadeIn: false }} {...image} />
               </div>
            </div>
         </div>
      </div>
   );
};

export interface CarouselProps {
   mainTitle: string;
   subTitle: string;
   btnMainMd: string;
   btnSecondaryMd: string;
   image: ImageProps;
}
