import React, { useMemo, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const LocalImagesContext = React.createContext({
   images: [],
   setLocalImagesContext: (images) => {
      images: images;
   },
}); //need the shape for gatsby build

export const LocalImageProvider = (props) => {
   const [images, setLocalImagesContext] = useState([]);

   const value = useMemo(() => ({ images, setLocalImagesContext }), [images]);

   return <LocalImagesContext.Provider value={images}>{props.children}</LocalImagesContext.Provider>;
};
