import React, { useState, useEffect, useContext } from 'react';
import Client from 'shopify-buy';
import produce from 'immer';
// inspired by https://github.com/IliasHad/gatsby-shopify-starter/blob/master/src/provider/ContextProvider.js
export interface CartContextProps {
   store: { client; checkout: { id: number; lineItems; webUrl }; products; shop; adding };
   addVariantToCart: (variantId, quantity, customAttributes?: any[]) => Promise<void>;
   removeLineItem: (lineItemId) => void;
   updateLineItem: (lineItemID, quantity) => void;
}
const CartContext = React.createContext<CartContextProps>(undefined);
const isBrowser = typeof window !== 'undefined';
const getLocalStorage = () => {
   if (isBrowser) {
      return localStorage;
   }
   return null;
};
export const CartProvider = (props: { children; storeFrontAccessToken; shopName; language }) => {
   const client = Client.buildClient({
      storefrontAccessToken: props.storeFrontAccessToken,
      domain: `${props.shopName}.myshopify.com`,
      language: props.language || 'fr',
   });
   const fromStorage = getLocalStorage()?.getItem('shopify_checkout');
   let initialQuantity = 0;
   if (fromStorage) {
      const itemsInCart = JSON.parse(fromStorage)?.lineItems ?? [];
      initialQuantity = itemsInCart.reduce((acc, cur) => acc + cur.quantity, 0);
   }
   const initialStoreState = {
      client,
      adding: false,
      checkout: { id: 1, lineItems: [{ quantity: initialQuantity }], webUrl: '' },
      products: [],
      shop: {},
   };

   const [store, updateStore] = useState(initialStoreState);

   useEffect(() => {
      const initializeCheckout = async () => {
         // Check for an existing cart.
         const existingCheckoutID = getLocalStorage()?.getItem('shopify_checkout_id');
         const existingCheckout = existingCheckoutID ? JSON.parse(getLocalStorage()?.getItem('shopify_checkout')) : null;

         const setCheckoutInState = (checkout) => {
            getLocalStorage()?.setItem('shopify_checkout_id', checkout.id);

            updateStore(
               produce((draft) => {
                  draft.checkout = checkout;
               })
            );
         };

         const createNewCheckout = () => store.client.checkout.create();
         const fetchCheckout = (id) => store.client.checkout.fetch(id);

         if (existingCheckoutID) {
            // to avoid flickering, simply put the state from local cache while we fetch from the backend
            existingCheckout && setCheckoutInState(existingCheckout);
            try {
               const checkout = await fetchCheckout(existingCheckoutID);
               // Make sure this cart hasn’t already been purchased.
               if (!checkout.completedAt) {
                  setCheckoutInState(checkout);
                  return;
               }
            } catch (e) {
               getLocalStorage()?.setItem('shopify_checkout_id', null);
            }
         }

         const newCheckout = await createNewCheckout();
         await store.client.checkout.updateAttributes(newCheckout.id, { customAttributes: [{ key: 'language', value: props.language }] }); // make available this attribute to localize notifications.
         setCheckoutInState(newCheckout);
      };

      initializeCheckout();
   }, [store.client]);

   useEffect(() => {
      getLocalStorage()?.setItem('shopify_checkout', JSON.stringify(store.checkout));
   }, [store.checkout]);

   return (
      <CartContext.Provider
         value={{
            store,
            addVariantToCart: async (variantId, quantity, customAttributes) => {
               if (variantId === '' || !quantity) {
                  console.error('Both a size and quantity are required.');
                  return;
               }
               updateStore(
                  produce((draft) => {
                     draft.adding = true;
                  })
               );

               const { checkout, client } = store;

               const checkoutId = checkout.id;
               const lineItemsToUpdate = [{ variantId, quantity: parseInt(quantity, 10), customAttributes }];

               return client.checkout.addLineItems(checkoutId, lineItemsToUpdate).then((res) => {
                  updateStore(
                     produce((draft) => {
                        draft.checkout = res;
                        draft.adding = false;
                     })
                  );
               });
            },
            removeLineItem: (lineItemID) => {
               return client.checkout.removeLineItems(store.checkout.id, [lineItemID]).then((res) => {
                  updateStore(
                     produce((draft) => {
                        draft.checkout = res;
                     })
                  );
               });
            },
            updateLineItem: (lineItemID, quantity) => {
               const lineItemsToUpdate = [{ id: lineItemID, quantity: parseInt(quantity, 10) }];

               return client.checkout.updateLineItems(store.checkout.id, lineItemsToUpdate).then((res) => {
                  updateStore(
                     produce((draft) => {
                        draft.checkout = res;
                     })
                  );
               });
            },
         }}
      >
         {props.children}
      </CartContext.Provider>
   );
};

export const useCart = () => {
   const context = useContext(CartContext);
   if (context === undefined) throw new Error('You must use it within a CartContext provider');
   return context;
};
