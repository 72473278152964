import React from 'react';
import { MdStringRenderer } from '../../../md-string-renderer';

export const CheckboxRenderer = ({ field, formState, formValue, handleChange, requiredMsg }) => {
   return (
      <div className="flex flex-col">
         {field.required && formValue[field.key] === false && formState.valid === false ? (
            <div className="text-xs flex-initial italic text-center text-red-500 font-bold">
               <MdStringRenderer source={requiredMsg} />
            </div>
         ) : null}
         <div className="flex w-full items-center mb-4 text-xs font-bold">
            <input
               className={
                  'block w-4 h-4 border rounded py-3 px-4 mr-2 leading-tight focus:outline-none bg:bg-formfield-focus focus:text-formfield-focus'
               }
               name={field.label}
               type={field.inputType}
               placeholder={field.placeHolder}
               value={formValue[field.key]}
               onChange={(e) => handleChange(e, field.key)}
               id={field.key}
            />
            {field.label ? (
               <label for={field.key}>
                  <MdStringRenderer source={field.label} />
                  {field.required ? <label>&nbsp;{'*'}</label> : null}
               </label>
            ) : null}
         </div>
      </div>
   );
};
