import React, { useState } from 'react';
import { MdStringRenderer } from '@matix/gatsby-theme-core';
import c from 'classnames';
import { FormProps } from '../../form.section-renderer';
import 'react-phone-number-input/style.css';
import { FormHandler } from '../../form-handler';

export const Form: React.FC<FormProps> = (props: FormProps) => {
   const [open, toggle] = useState(false);

   return (
      <div className={'flex flex-row flex-wrap space-x-10 justify-center space-y-10 w-full'}>
         <div className="w-full overflow-hidden max-h-full">
            <div className="w-full flex justify-center">
               <button
                  className="mx-auto outline-none bg-secondary-500 border-black text-white py-2 px-4 text-xl rounded-full m-4 hover:text-black hover:bg-tertiary-500"
                  onClick={() => toggle(!open)}
               >
                  <MdStringRenderer source={props.title} />
               </button>
            </div>

            <div className={c('overflow-hidden transition-all duration-700', open ? 'h-full' : 'h-0')}>
               <div className="px-3 z-10 h-full">
                  <FormHandler className={'relative bg-form text-form rounded px-4 md:px-8 pt-6 pb-8 z-10 border border-form'} {...props} />
               </div>
            </div>
         </div>
      </div>
   );
};
export default Form;
