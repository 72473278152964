import React from 'react';
import { SlideCarouselSpringProps } from '../interface';
import { Image } from '../../../image';

export interface CarouselSlideDefaultProps extends React.HTMLAttributes<HTMLElement>, SlideCarouselSpringProps {}
export const CarouselSlide1 = (props: CarouselSlideDefaultProps) => {
   return (
      <div className="h-full w-full mx-auto flex flex-col cursor-grab active:cursor-grabbing" style={{ touchAction: 'pan-y', userSelect: 'none' }}>
         <div className="h-full w-full overflow-hidden">
            {/* <Image className="h-auto md:max-h-prd-md xl:max-h-prd-xl mx-auto object-cover" {...props.image} draggable={false} /> */}
            <Image className="w-full mx-auto object-cover" {...props.image} draggable={false} />
         </div>
         {props.text ? <div className="italic py-2 xl:p-5 text-center text-sm md:text-base">{props.text}</div> : null}
      </div>
   );
};
