import React from 'react';
import { ShopifyForm } from '@matix/gatsby-theme-shopify';
import { FormSectionRenderer as FormSectionRendererCore } from '@matix/gatsby-theme-core/src/components/sections/form/form.section-renderer';

export const FormSectionRenderer = (props) => {
   if (['shopifyForm'].indexOf(props.form?.frontmatter?.style) > -1) {
      return <>{props.form?.frontmatter?.style === 'shopifyForm' && <ShopifyForm {...props.form.frontmatter} />}</>;
   } else {
      return FormSectionRendererCore(props);
   }
};
export default FormSectionRenderer;
