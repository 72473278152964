import React from 'react';

export const ShopifySelectRenderer = ({ field, formState, handleChange, setFormValueI }) => {
   return (
      <select
         className={
            'block bg-formfield text-formfield w-full border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-formfield-focus focus:text-formfield-focus '
         }
         name={field.label}
         onChange={(e) => handleChange(e, field.key)}
      >
         {field.options.map((x) => (
            <option value={x.product.shopifyId}>{x.product.label}</option>
         ))}
      </select>
   );
};
