import React from 'react';
import { Link } from '@matix/gatsby-theme-core';
import { useCart } from '../cart-context';
import { CartSvg } from '@matix/gatsby-theme-core';
export const CartButton = (props) => {
   const { store } = useCart();
   const itemsInCart = store.checkout?.lineItems ?? [];
   const allQuantity = itemsInCart.reduce((acc, cur) => acc + cur.quantity, 0);

   return (
      <Link to={props.to}>
         <div className="flex items-center mr-5">
            <CartSvg {...props}></CartSvg>

            {allQuantity > 0 && <div className="pl-1 text-sm">({allQuantity})</div>}
         </div>
      </Link>
   );
};
