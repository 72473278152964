import { useStore } from '@matix/gatsby-theme-core';
import { CartProvider } from '@matix/gatsby-theme-shopify';
import React from 'react';
// wraps all existing templates and provide the Cart to all of them
export const TemplateWrapper = (props) => {
   const {
      store: { siteMeta },
   } = useStore();
   return (
      <CartProvider
         language={props.data.pageQuery.locale}
         shopName={siteMeta.shopifyShopName}
         storeFrontAccessToken={siteMeta.shopifyStoreAccessToken}
      >
         {props.children}
      </CartProvider>
   );
};
