import React, { useRef, useState } from 'react';
import clamp from 'lodash-es/clamp';
import { useSprings, animated, interpolate, useSpring } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { useFullscreenModule } from './use-fullscreen';
import c from 'classnames';
import { Image } from '../../../image';
import { CloseSvg } from '../../icons/awesome-icon';
import { isSSR } from '@matix/gatsby-theme-core';
export const useFullscreenCarousel = (props) => {
   const { images } = props;
   const [resize, setResize] = useState(0);
   const [isFullscreen, setFullscreen] = useState(false);
   // I am using fixed backdrop instead of fullscreen api, its more elegant... otherwise this can be used..const { enterFullscreen } = useFullscreenModule({ handleResize: () => setResize(new Date().getMilliseconds()) });
   const index = useRef(0);
   const mainCarouselWrapperRef = useRef<HTMLDivElement | null>(null);
   const [springs, set] = useSprings(images ? images.length : 0, (i) => ({
      x: !isSSR() ? i * window.innerWidth : 0,
      scale: 1,
      display: 'block',
      opacity: 1,
   }));
   const styles = useSpring({
      loop: { reverse: true },
      from: { rotateZ: 0, color: 'white' },
      to: { rotateZ: 90, color: 'black' },
   });
   const enterFS = (currentImage) => {
      const idx = images.indexOf(currentImage);
      index.current = clamp(idx, 0, images.length - 1);
      // recompute springs
      set((i) => {
         return computeSprings(i, false, 0, 0);
      });
      setFullscreen(true);
   };
   const computeSprings = (i, active, distance, mx) => {
      if (i < index.current - 1 || i > index.current + 1) return { display: 'none', opacity: 0 };
      const x = ((i - index.current) * window.innerWidth) / 1.3 + (active ? mx : 0);
      const scale = active ? 1 - distance / window.innerWidth / 2 : 1;
      return { x, scale, display: 'block', opacity: 1 };
   };
   const bind = useDrag(({ active, movement: [mx], direction: [xDir], distance, cancel }) => {
      if (active && distance > window.innerWidth / 2) {
         cancel();
         index.current = clamp(index.current + (xDir > 0 ? -1 : 1), 0, images.length - 1);
      }
      set((i) => {
         return computeSprings(i, active, distance, mx);
      });
   });

   const fragment = (
      <div ref={mainCarouselWrapperRef} className={c(isFullscreen ? 'fixed bg-primary-dark bg-opacity-90 inset-0' : '')} style={{ zIndex: 500 }}>
         {isFullscreen &&
            springs.map(({ x, display, scale, opacity }, i) => (
               <animated.div
                  className="flex items-center justify-center absolute h-screen w-screen"
                  style={{
                     willChange: 'transform',
                     display,
                     opacity,
                     transform: interpolate(
                        [scale.interpolate((s) => `scale(${s})`), x.interpolate((x) => `translate3d(${x}px,0,0)`)],
                        (scale, rotation) => `${scale} ${rotation}`
                     ),
                     cursor: "url('https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/Ad1_-cursor.png') 39 39,auto", //TODO: use own cursor
                     overscrollBehaviorY: 'contain',
                     userSelect: 'none',
                  }}
                  {...bind()}
                  key={i}
               >
                  <Image
                     draggable={false}
                     {...images[i]}
                     className="h-1/2 w-1/2 "
                     style={{
                        display,
                        opacity,
                        userSelect: 'none',
                        userDrag: 'none',
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        willChange: 'transform',
                        boxShadow: 'inset 0 62.5px 125px -25px rgba(50, 50, 73, 0.5), 0 37.5px 75px -37.5px rgba(0, 0, 0, 0.6)',
                     }}
                  ></Image>

                  {images[i]?.title && (
                     <div className="absolute bottom-0 p-20 text-primary text-7xl tracking-tight font-extrabold uppercase">{images[i]?.title}</div>
                  )}
               </animated.div>
            ))}
         {isFullscreen && (
            <div className="fixed top-0 right-0 p-20" onClick={() => setFullscreen(false)}>
               <animated.div style={{ ...styles }}>
                  <CloseSvg className="w-24 h-24 hover:text-primary-light" />
               </animated.div>
            </div>
         )}
      </div>
   );
   // eslint-disable-next-line react/jsx-no-useless-fragment
   return { fragment, enter: enterFS, exit: () => setFullscreen(false), isFullscreen };
};
