import React from 'react';
import { useSpringCarousel } from 'react-spring-carousel-js';
import { CarouselSlideDefault } from './carousel-slide-default';
import { ThumbnailDefault } from './thumbnail-default';
import { CarouselSpringProps } from '../interface';
import { MdStringRenderer } from '../../../md-string-renderer';
import { animated, useSpring, useSprings } from 'react-spring';
import { ChevronLeft, ChevronRight, MagnifierSvg } from '../../icons/awesome-icon';
const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;
const calc = (x, y) => [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 2) / 20, 1.3];
export const CarouselSpring = (props: CarouselSpringProps) => {
   const {
      className,
      title,
      withControls = false,
      withThumbnails = false,
      withLoop = false,
      slides,
      thumbsSlideAxis = 'x',
      springConfig,
      itemsPerSlide = 1,
      fullscreenApi,
   } = props;

   // Augment slide objects with new data for rendering the clides and thumnails
   const getSlidesForCarousel = () =>
      slides.map((slide, idx) => ({
         ...slide,
         id: idx.toString(),
         renderItem: slide.slideNode ? React.cloneElement(slide.slideNode, { ...slide }) : <CarouselSlideDefault {...{ ...slide, fullscreenApi }} />,
         renderThumb: slide.thumbnailNode ? (
            React.cloneElement(slide.thumbnailNode, { ...slide, onClick: () => slideToItem(idx.toString()) })
         ) : (
            <ThumbnailDefault {...slide} onClick={() => slideToItem(idx.toString())} />
         ),
      }));

   const { carouselFragment, thumbsFragment, slideToPrevItem, slideToNextItem, slideToItem, enterFullscreen } = useSpringCarousel({
      springConfig,
      itemsPerSlide,
      withThumbs: withThumbnails,
      thumbsSlideAxis: thumbsSlideAxis,
      withLoop,
      items: getSlidesForCarousel(),
   });

   // TODO: Reorganize some springs animation and put in matix/ui
   const AnimatedChevron = animated(ChevronLeft);
   const AnimatedChevronRight = animated(ChevronRight);
   const [springs, setSprings] = useSprings(3, () => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 } }));
   return (
      <div className={className}>
         {title ? (
            <div className="text-3xl p-5 text-center lg:p-10 lg:text-6xl">
               <MdStringRenderer source={title} />
            </div>
         ) : null}

         <div className="relative mx-auto h-full w-full flex flex-row flex-nowrap">
            {withControls && slides?.length > 1 ? (
               <div className="absolute left-5 top-1/3  z-10" onClick={slideToPrevItem}>
                  <AnimatedChevron className="w-7 h-7 lg:h-24 lg:w-24 text-primary bg-black bg-opacity-60 ease-in-out hover:bg-opacity-90 rounded-full shadow-2xl md:block" />
               </div>
            ) : null}
            {carouselFragment}
            {withControls && slides?.length > 1 ? (
               <div className="absolute right-5 top-1/3  z-10 " onClick={slideToNextItem}>
                  <AnimatedChevronRight className="w-7 h-7 lg:h-24 lg:w-24  text-primary bg-black bg-opacity-60 ease-in-out hover:bg-opacity-90 rounded-full shadow-2xl md:block" />
               </div>
            ) : null}
         </div>
         {slides?.length > 1 ? (
            <div className="hidden mx-auto h-full w-full overflow-hidden overflow-x-hidden md:block scrollbar">{thumbsFragment}</div>
         ) : null}
      </div>
   );
};
