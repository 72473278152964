import React from 'react';
import { Hero as Hero1 } from './1/hero';
import { Hero as Hero2 } from './2/hero';
import { Hero as Hero3 } from './3/hero';
import { Hero as Hero4 } from './4/hero';
import { Hero as Hero5 } from './5/hero';
import { Hero as Hero6 } from './6/hero';
import { Hero as Hero7 } from './7/hero';
import { ImageProps } from '@matix/gatsby-theme-core';
import { ImageHeroSectionWrapper } from './image-hero-section-renderer.wrapper';

export interface HeroProps {
   mainTitle: string;
   subTitle: string;
   btnMainMd: string;
   btnSecondaryMd: string;
   heroImage: ImageProps;
}

export const ImageHeroSectionRenderer = (props) => {
   return (
      <ImageHeroSectionWrapper key={props.key}>
         {props.style === '1' ? <Hero1 {...props} /> : null}
         {props.style === '2' ? <Hero2 {...props} /> : null}
         {props.style === '3' ? <Hero3 {...props} /> : null}
         {props.style === '4' ? <Hero4 {...props} /> : null}
         {props.style === '5' ? <Hero5 {...props} /> : null}
         {props.style === '6' ? <Hero6 {...props} /> : null}
         {props.style === '7' ? <Hero7 {...props} /> : null}
      </ImageHeroSectionWrapper>
   );
};
