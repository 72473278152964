import { MdStringRenderer } from '../../../../md-string-renderer';
import { ImageWithTextProps } from '../image-with-text.section-renderer';
import { Image } from '../../../../image';
import React, { useState, useRef } from 'react';
import c from 'classnames';
import { useTrail, a, useSpring, animated } from '@react-spring/web';

const Trail: React.FC<{ open: boolean }> = ({ open, children }) => {
   const items = React.Children.toArray(children);
   const trail = useTrail(items.length, {
      config: { mass: 5, tension: 1000, friction: 500 },
      opacity: open ? 1 : 0,
      x: open ? 0 : 20,
      height: open ? 75 : 0,
      from: { opacity: 0, x: 20, height: 0 },
      delay: 1000,
   });
   return (
      <div>
         {trail.map(({ height }, index) => (
            <a.div key={index} className="overflow-hidden relative w-full transition-all">
               <a.div style={{ height }}>{items[index]}</a.div>
            </a.div>
         ))}
      </div>
   );
};

export const ImageWithText = (props: ImageWithTextProps) => {
   const { imageWithTextImages, text, title, position = 'bottom', btnMainMd } = props;
   const image = imageWithTextImages.length > 0 ? imageWithTextImages[0] : null;
   const textAnimated = text?.split('.');
   const [open, set] = useState(true);

   const n = useRef(0);
   const styles = useSpring({
      from: { x: -100, opacity: 0 },
      to: { x: 0, opacity: 1 },
      config: { duration: 1000 },
   });

   return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
         <div className="flex flex-col my-5">
            {title ? (
               <>
                  <div className="text-2xl md:text-4xl text-center mb-2">
                     <MdStringRenderer source={title} />
                  </div>
                  <div className="border-primary-200 xl:border-opacity-75 border-t-2 w-1/2 mb-5 mx-auto shadow-lg h-0"></div>
               </>
            ) : null}
            <div className={c('flex flex-col md:flex-row md:bg-transparent xl:mb-5 xl:pt-0 ', position === 'right' ? 'md:flex-row-reverse' : '')}>
               <div className="flex items-center justify-center w-full md:w-1/2 xl:w-1/2 bg-primary-dark text-primary-dark">
                  <div className="object-cover w-80">
                     <animated.div style={{ ...styles }}>
                        <Image {...image} className="mx-auto" />
                     </animated.div>
                  </div>
               </div>
               <div
                  className={c(
                     position === 'right' ? 'md:mr-6' : 'md:ml-6',
                     'flex flex-col p-5 mx-auto md:w-full xl:w-1/2 bg-primary-dark text-primary-dark'
                  )}
               >
                  <div className="flex flex-col items-center h-full">
                     {text ? (
                        <div className="flex-grow h-28 text-base xl:text-2xl">
                           <Trail open={open}>
                              {textAnimated.map((x) => {
                                 if (x) {
                                    return <MdStringRenderer source={x} />;
                                 }
                              })}
                           </Trail>
                        </div>
                     ) : null}
                     {btnMainMd ? (
                        <div className="z-20 text-right font-semibold underline w-full">
                           <MdStringRenderer source={btnMainMd} />
                        </div>
                     ) : null}
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};
