import React from 'react';
import { ImageProps, CarouselSlider } from '@matix/gatsby-theme-core';
import { CarouselHero as CarouselHero1 } from './1/carousel-hero';
import { CarouselHeroThumbnail as CarouselHeroThumbnail1 } from './1/carousel-hero-thumbnail';
import { CarouselHero as CarouselHero2 } from './2/carousel-hero';
import { CarouselHeroThumbnail as CarouselHeroThumbnail2 } from './2/carousel-hero-thumbnail';
import { CarouselHero as CarouselHero3 } from './3/carousel-hero';
import { CarouselHeroThumbnail as CarouselHeroThumbnail3 } from './3/carousel-hero-thumbnail';
import { CarouselHeroSectionWrapper } from './carousel-hero.section-renderer.wrapper';
export interface CarouselHeroSectionProps {
   type: string;
   visible: boolean;
   style: string;
   slides: [CarouselHeroProps];
}

export interface CarouselHeroProps {
   mainTitle: string;
   subTitle: string;
   btnMainMd: string;
   btnSecondaryMd: string;
   image: ImageProps;
}

export interface CarouselHeroThumbnailProps {
   thumbnailText: string;
   // iconName: string;
   id?: string;
   onClick?: () => void;
   isActive?: (idx) => boolean;
}

export interface HeroCarouselSliderProps extends CarouselHeroProps, CarouselHeroThumbnailProps {}

const InternalCarouselHeroSectionRenderer = (props: CarouselHeroSectionProps) => {
   const slides =
      props?.slides?.reduce((previous, item: HeroCarouselSliderProps) => {
         previous.push(prepareHeroSlide(item, props.style));
         return previous;
      }, []) || [];

   if (props.style === '3') {
      const sprConfig = { duration: 750 };
      return (
         // eslint-disable-next-line react/jsx-no-useless-fragment
         <>
            {slides.length > 0 ? (
               <CarouselSlider
                  className={'z-10 flex w-full justify-center mt-6 xl:-mt-10'}
                  springConfig={sprConfig}
                  autoplay={false}
                  // withLoop={true}
                  withThumbnails={true}
                  slides={slides}
                  customThumbnails={true}
               ></CarouselSlider>
            ) : null}
         </>
      );
   } else {
      return (
         // eslint-disable-next-line react/jsx-no-useless-fragment
         <>{slides.length > 0 ? <CarouselSlider withThumbnails={true} withLoop={false} slides={slides}></CarouselSlider> : null}</>
      );
   }
};
export const CarouselHeroSectionRenderer = (props) => {
   return (
      <CarouselHeroSectionWrapper>
         <InternalCarouselHeroSectionRenderer {...props} />
      </CarouselHeroSectionWrapper>
   );
};
function prepareHeroSlide(props: HeroCarouselSliderProps, style: string) {
   if (style === '1') {
      return {
         slideNode: (
            <CarouselHero1
               mainTitle={props.mainTitle}
               subTitle={props.subTitle}
               btnSecondaryMd={props.btnSecondaryMd}
               btnMainMd={props.btnMainMd}
               image={props.image}
            />
         ),
         thumbnailNode: <CarouselHeroThumbnail1 {...props} />,
      };
   } else if (style === '2') {
      return {
         slideNode: (
            <CarouselHero2
               mainTitle={props.mainTitle}
               subTitle={props.subTitle}
               btnSecondaryMd={props.btnSecondaryMd}
               btnMainMd={props.btnMainMd}
               image={props.image}
            />
         ),
         thumbnailNode: <CarouselHeroThumbnail2 {...props} />,
      };
   } else if (style === '3') {
      return {
         slideNode: (
            <CarouselHero3
               mainTitle={props.mainTitle}
               subTitle={props.subTitle}
               btnSecondaryMd={props.btnSecondaryMd}
               btnMainMd={props.btnMainMd}
               image={props.image}
            />
         ),
         thumbnailNode: <CarouselHeroThumbnail3 {...props} />,
      };
   }
}
