import React, { useState } from 'react';
import { MdStringRenderer, ChevronRight, AngleDown, useStore, Link } from '@matix/gatsby-theme-core';
import { navigate } from 'gatsby';
import c from 'classnames';

export const SubCategory = (props) => {
   const { store } = useStore();
   const { category, level } = props;

   const catNames = category.map((x) => x.frontmatter.identifier);

   const defaultState = {};
   for (const key of catNames) {
      let isOpen = false;
      if (key === store.pageQuery.frontmatter.identifier) {
         isOpen = true;
      } else {
         isOpen = isParentOf(key, store.pageQuery.frontmatter.parentCollection);
      }
      defaultState[key] = isOpen;
   }

   const [catValue, setCatValue] = useState(defaultState);

   const toggle = (e, key, store) => {
      if (catValue[key]) {
         e.preventDefault();
         navigate(store.pageQuery.frontmatter.parentCollection.frontmatter.pagePath);
      } else {
         setCatValueI({ [key]: !catValue[key] });
      }
   };

   function setCatValueI(state) {
      setCatValue((currentState) => ({ ...currentState, ...state }));
   }
   return (
      <div className="mt-4 space-y-4 ">
         {category.map((x, key) => (
            <div key={key}>
               <Link to={x.frontmatter.pagePath}>
                  <div
                     className="ml-2 flex w-full items-center cursor-pointer hover:opacity-75 "
                     onClick={(e) => toggle(e, x.frontmatter.identifier, store)}
                  >
                     <div className={c('text-xs xl:text-base font-light tracking-wide', !level ? 'font-bold' : '')}>
                        <MdStringRenderer source={x.frontmatter.headline.title} />
                     </div>
                     {x.frontmatter.childCollection && x.frontmatter.childCollection.length > 0 ? (
                        <div>{catValue[x.frontmatter.identifier] ? <AngleDown className="mx-2 w-2" /> : <ChevronRight className="mx-2 w-2" />}</div>
                     ) : null}
                  </div>
               </Link>
               {catValue[x.frontmatter.identifier] && x.frontmatter.childCollection && x.frontmatter.childCollection.length > 0 ? (
                  <div className="flex flex-col ml-2">
                     <SubCategory {...props} {...{ category: props.collections[0].frontmatter.childCollection, level: 0 }} />
                  </div>
               ) : null}
            </div>
         ))}
      </div>
   );
};

const isParentOf = (id, parentCollection) => {
   if (!parentCollection || !parentCollection.frontmatter) {
      return false;
   }
   if (parentCollection.frontmatter.identifier === id) {
      return true;
   }
   if (parentCollection.frontmatter.parentCollection) {
      return isParentOf(id, parentCollection.frontmatter.parentCollection);
   }
   return false;
};
