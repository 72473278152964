import React from 'react';
import { MdStringRenderer, ImageProps, Image, Link } from '@matix/gatsby-theme-core';
import c from 'classnames';
interface CardProps {
   title: string;
   text: string;
   btnMainMd: string;
   image: ImageProps;
}

export const Card = (props: CardProps) => {
   const urlFromBtn = props.btnMainMd?.match(/\(([^)]+)\)+$/);
   const transitionClass = 'transition-all transform duration-500 ease-in-out';

   const googlePlayUrl = 'https://res.cloudinary.com/groupebt/image/upload/v1624883163/Googleplay_egqmka.png';
   const googlePlayLink = 'https://play.google.com/store/apps/details?id=com.mobile_btu';
   const appleStoreUrl = 'https://res.cloudinary.com/groupebt/image/upload/v1624883163/Appstore_tbflaj.png';
   const appleStoreLink = 'https://apps.apple.com/in/app/b-t-u/id1572467328';
   return (
      <div
         className={c(
            'group h-full relative flex flex-col text-justify md:mx-2 xl:mx-32 px-5 hover:bg-secondary-light hover:text-secondary-light hover:bg-opacity-80',
            transitionClass
         )}
      >
         {urlFromBtn && urlFromBtn.length ? (
            <Link to={urlFromBtn[1]}>
               <div className="rounded-full mx-auto h-40 w-40 overflow-hidden">
                  <Image {...props.image} fadeIn={false} className={c('object-cover  group-hover:scale-110', transitionClass)} />
               </div>
            </Link>
         ) : (
            <div className="rounded-full mx-auto h-40 w-40 overflow-hidden">
               <Image {...props.image} fadeIn={false} className={c('object-cover rounded-full h-40 w-40  group-hover:scale-110', transitionClass)} />
            </div>
         )}
         {props.title ? (
            <div className="py-4 text-2xl text-center font-serif">
               <MdStringRenderer source={props.title} />
            </div>
         ) : null}

         {props.text ? (
            <div className="flex-grow py-4 text-base text-center">
               <MdStringRenderer source={props.text} />
            </div>
         ) : null}
         {props.btnMainMd ? (
            <div className="flex-grow py-4 text-center">
               <MdStringRenderer source={props.btnMainMd} />
            </div>
         ) : null}
         <div className="flex space-x-3 items-center justify-center">
            <Link to={googlePlayLink}>
               <div className="w-40">
                  <Image url={googlePlayUrl} alt="Google play store" className="object-cover" fadeIn={false} />
               </div>
            </Link>
            <Link to={appleStoreLink}>
               <div className="w-40">
                  <Image url={appleStoreUrl} alt="Apple store" className="object-cover" fadeIn={false} />
               </div>
            </Link>
         </div>
      </div>
   );
};

export default Card;
