import * as React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import { isSSR } from '@matix/gatsby-theme-core';
/**
 * Animates elements that enter and leave the dom as standard animations cannot be applied for this.
 * This basically adds the children into a queue, applies the animation and then remove/add items in/from the dom
 */
export const DomAnimate = (props) => {
   return (
      <>
         {isSSR() && props.children}
         {!isSSR() && (
            <CSSTransitionGroup className={props.className} transitionName="domAnimate" transitionLeaveTimeout={500} transitionEnterTimeout={500}>
               {props.children}
            </CSSTransitionGroup>
         )}
      </>
   );
};
