/* eslint-disable jsx-a11y/heading-has-content */
import React, { useState } from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { config as config1 } from './1/config';
import { config as config2 } from './2/config';
import { config as config3 } from './3/config';
import { config as config4 } from './4/config';
import { MDXProvider } from '@mdx-js/react';
import Loadable from '@loadable/component';
import { ImageGalleryMtx } from '../../../support/carousel/image-gallery';
import { MdStringRenderer } from '@matix/gatsby-theme-core';
import c from 'classnames';
export interface MarkdownProps {
   style: string;
   text: string;
}

export const MarkdownSectionRenderer = (props) => {
   const gallery = (props) => (
      <div className="w-full md:2/3 mx-auto py-5">
         <ImageGalleryMtx {...props} />
      </div>
   );
   const img = (props) => {
      const slides = { images: [{ ...props, url: props.src }] };
      return gallery(slides);
   };

   let config = config1({ img: img, Gallerie: gallery });
   if (props.style === '2') {
      config = config2({ img: img, Gallerie: gallery });
   } else if (props.style === '3') {
      config = config3({ img: img, Gallerie: gallery });
   } else if (props.style === '4') {
      config = config4({ img: img, Gallerie: gallery });
   }
   if (!config) return null;
   return (
      <div>
         {props.style === '1' ? (
            <div className={'xl:mx-10'}>
               <Render config={config} {...props} />
            </div>
         ) : null}
         {props.style === '2' ? (
            <div className={'xl:mx-10'}>
               <Render config={config} {...props} />
            </div>
         ) : null}
         {props.style === '3' ? (
            <div className={'xl:mx-10 mb-4'}>
               <Render config={config} {...props} />
            </div>
         ) : null}
         {props.style === '4' ? (
            <div className={'mb-4'}>
               <Render config={config} {...props} />
            </div>
         ) : null}
      </div>
   );
};

export default MarkdownSectionRenderer;

export const Render = (props) => {
   const useLazy = !!props.isInCms;
   const { showMoreBtnText, summary, bodyAst } = props;
   // Technique to only load this lib during development
   const MdxScopedLazy = Loadable(() => import('mdx-scoped-runtime'));
   const [open, toggle] = useState(false);

   if (useLazy) {
      return (
         <MdxScopedLazy components={{ ...props.config.components }} scope={props.config.shortCodes}>
            {props.body}
         </MdxScopedLazy>
      );
   }

   if (summary && showMoreBtnText) {
      return (
         <>
            <div className={'flex flex-row flex-wrap space-x-10 justify-center space-y-10 py-6 w-full'}>
               <div className="w-full overflow-hidden max-h-full">
                  <div className="px-3 text-base 2xl:text-3xl mb-4">
                     <div className="px-4">
                        <MdStringRenderer source={summary} />
                     </div>
                  </div>
                  <div className="w-full flex justify-center">
                     <button
                        className="mx-auto outline-none bg-secondary-500 border-black text-white py-2 px-4 text-xl rounded-full m-4 hover:text-black hover:bg-tertiary-500"
                        onClick={() => toggle(!open)}
                     >
                        <MdStringRenderer source={showMoreBtnText} />
                     </button>
                  </div>

                  <div className={c('overflow-hidden transition-all duration-700', open ? 'h-full' : 'h-0')}>
                     <div className="px-3 z-10 h-full">
                        <MDXProvider components={props.config.components}>
                           <MDXRenderer components={props.config.components}>{bodyAst}</MDXRenderer>
                        </MDXProvider>
                     </div>
                  </div>
               </div>
            </div>
         </>
      );
   }

   if (summary && !showMoreBtnText) {
      return (
         <>
            <div className={'flex flex-row flex-wrap space-x-10 justify-center space-y-10 py-6 w-full'}>
               <div className="w-full overflow-hidden max-h-full">
                  <div className="px-3 text-base 2xl:text-3xl mb-4">
                     <div className="px-4">
                        <MdStringRenderer source={summary} />
                     </div>
                  </div>
                  <div className={c('overflow-hidden transition-all duration-700 h-full')}>
                     <div className="px-3 z-10 h-full">
                        <MDXProvider components={props.config.components}>
                           <MDXRenderer components={props.config.components}>{bodyAst}</MDXRenderer>
                        </MDXProvider>
                     </div>
                  </div>
               </div>
            </div>
         </>
      );
   }

   return (
      <MDXProvider components={props.config.components}>
         <MDXRenderer components={props.config.components}>{bodyAst}</MDXRenderer>
      </MDXProvider>
   );
};
