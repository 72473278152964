import React from 'react';

export const MagnifierSvg = (props: React.SVGProps<SVGSVGElement>) => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
         <path
            fill="currentColor"
            d="M13 10h-3v3H8v-3H5V8h3V5h2v3h3v2zm8.172 14l-7.387-7.387A8.945 8.945 0 019 18c-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9a8.951 8.951 0 01-1.387 4.785L24 21.172 21.172 24zM9 16c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7z"
         ></path>
      </svg>
   );
};
