import React, { useState, useContext } from 'react';
import { MdStringRenderer, Link, useStore, ScrollContext, DomAnimate } from '@matix/gatsby-theme-core';
import { useCart } from '../cart-context';
import { CartSummaryItem } from './cart-summary-item';
import c from 'classnames';

export const CartPage = (props) => {
   const { isTop } = useContext(ScrollContext);
   const texts = props.data.pageQuery.frontmatter.texts
      ?.reduce((acc, t) => {
         acc.push(...t.frontmatter.translations);
         return acc;
      }, [])
      .reduce((acc, cur) => {
         acc[cur.identifier] = cur.value;
         return acc;
      }, {});
   const [open, set] = useState(true);
   const locale = useStore().store.pageQuery?.locale;
   const { store } = useCart();
   const classNames = {
      btn: 'font-extrabold bg-primary-dark text-primary-dark p-3 tracking-widest hover:opacity-95 uppercase',
      btnCheckout: 'font-extrabold bg-primary-dark text-primary-dark p-3 w-full tracking-widest hover:opacity-95 uppercase',
   };
   const checkoutBtn = (classes?) => {
      return (
         <Link to={store.checkout.webUrl + '&locale=' + locale}>
            <button className={c('px-5 disabled:opacity-50', classNames.btnCheckout, classes)}>{texts.checkoutBtn}</button>
         </Link>
      );
   };
   const allQuantity = store.checkout.lineItems?.reduce((acc, cur) => acc + cur.quantity, 0);
   return (
      // TODO: check initialization state of the cart store.. what to display while loading?? Checking your bag
      // TODO: be able to click from the cart on a product and navigate to it. Where to store the original product url?

      <div className="max-w-7xl m-auto w-full flex-auto lg:static lg:max-h-full lg:overflow-visible">
         <div
            className={c(
               'px-5 flex flex-col sticky mt-20 top-10 font-extrabold bg-white z-10 transition-all ease-in-out duration-300 md:top-10',
               isTop ? 'py-10 text-2xl md:text-5xl ' : 'py-4 text-xl  md:text-3xl'
            )}
         >
            <div className="uppercase">{texts.yourBag}</div>
            {store.checkout.lineItems?.length > 0 && (
               <div className="pl-5 text-lg font-light flex justify-start space-x-3">
                  <div className="">{texts.priceSubTotal}</div>
                  <div className="">
                     {store.checkout.subtotalPriceV2?.currencyCode}$&nbsp;{store.checkout.subtotalPriceV2?.amount}
                  </div>
               </div>
            )}
         </div>

         {/* When bag is empty */}
         {!store.checkout.lineItems?.length && (
            <div className="h-96 pt-5 px-5">
               <div className="text-lg font-semibold p-5">{texts.nothingInCartYet}</div>
               {texts.btnShopNow && texts.btnShopNow.length && (
                  <button className={classNames.btn}>
                     <MdStringRenderer source={texts.btnShopNow}></MdStringRenderer>
                  </button>
               )}
            </div>
         )}

         {/* When bag is not empty */}
         {store.checkout.lineItems?.length > 0 && (
            <div className="w-full flex flex-col pb-10 md:flex-row">
               {checkoutBtn('md:hidden')}

               <DomAnimate className="min-w-0 flex-auto px-4 sm:px-6 xl:px-8 pt-10 pb-24 lg:pb-16 space-y-8">
                  {store.checkout.lineItems?.map((li) => (
                     <CartSummaryItem key={li.id} lineItem={li} text={props.data.pageQuery.frontmatter.texts} />
                  ))}{' '}
               </DomAnimate>

               {checkoutBtn('md:hidden')}

               {/* sidebar */}
               <div className="hidden lg:text-sm lg:block flex-none w-64 pl-8 mr-8">
                  <div
                     className={c(
                        'flex flex-col justify-between overflow-y-auto  pt-10 pb-6 top-48 space-y-4',
                        store.checkout.lineItems?.length > 2 ? 'sticky mt-48' : ''
                     )}
                  >
                     {checkoutBtn()}
                     <div className="border-black border-2 p-5">
                        <div className="tracking-wide font-semibold text-center uppercase">{texts.acceptedPayments}</div>
                        <ul className="flex flex-wrap">
                           <li className="m-1 p-2 rounded-md bg-primary-dark text-primary-dark">Visa</li>
                           <li className="m-1 p-2 rounded-md bg-primary-dark text-primary-dark">MasterCard</li>
                           <li className="m-1 p-2 rounded-md bg-primary-dark text-primary-dark">American Express</li>
                           <li className="m-1 p-2 rounded-md bg-primary-dark text-primary-dark">Paypal</li>
                        </ul>
                     </div>

                     <div className="border-black border-2 p-5 text-base font-light space-y-2">
                        <div className="tracking-wide font-semibold text-center">{texts.orderSummary}</div>
                        <div className="">
                           {allQuantity}&nbsp;{texts.nbrItemsInCart}
                        </div>

                        <div className="">{texts.shippingCalculatedAtCheckout}</div>
                        <div className="text-center flex flex-col justify-between font-semibold">
                           <div className="">{texts.priceSubTotal}</div>
                           <div className="">
                              {' '}
                              {store.checkout.subtotalPriceV2?.currencyCode}$&nbsp;{store.checkout.subtotalPriceV2?.amount}
                           </div>
                        </div>
                     </div>

                     <div className="border-black border-2 p-5">
                        <div className="tracking-wide font-semibold text-center">{texts.giftCardApply}</div>
                        <button className="font-extrabold bg-primary-dark text-primary-dark p-3 w-full tracking-wider hover:opacity-95">
                           {texts.apply}
                        </button>
                     </div>
                     {checkoutBtn()}
                  </div>
               </div>
            </div>
         )}
      </div>
   );
};
