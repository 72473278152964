import React from 'react';
import { MdStringRenderer } from '../../../../md-string-renderer';
import { ImageProps, Image } from '../../../../image';
import { Link } from '../../../../link';

interface CardProps {
   title: string;
   text: string;
   btnMainMd: string;
   image: ImageProps;
}
export const Card = (props: CardProps) => {
   const urlFromTitle = props.btnMainMd?.match(/\(([^)]+)\)+$/);

   if (urlFromTitle && urlFromTitle.length) {
      return (
         <Link to={urlFromTitle[1]}>
            <Result {...props} />
         </Link>
      );
   } else {
      return <Result {...props} />;
   }
};

const Result = (props: CardProps) => {
   return (
      <div className="relative flex flex-col hover:shadow-2xl max-w-full h-full">
         <div className="absolute w-full h-full z-10 bg-black opacity-60"></div>
         <Image {...props.image} className="absolute object-cover w-full h-full" />
         <div className="flex flex-col text-white z-20 px-6 pt-4 h-full">
            {props.title ? (
               <div className="text-center py-2 text-2xl">
                  <MdStringRenderer source={props.title} />
               </div>
            ) : null}
            {props.text ? (
               <div className="flex-grow font-semibold max-h-3/5 overflow-hidden">
                  <MdStringRenderer source={props.text} />
               </div>
            ) : null}
            {props.btnMainMd ? (
               <div className="py-2 mx-auto flex items-center">
                  <button className="my-5 py-2 px-6 rounded-md bg-primary-500 text-secondary-500 hover:bg-secondary-500 hover:text-primary-500">
                     <MdStringRenderer source={props.btnMainMd} />
                  </button>
               </div>
            ) : null}
         </div>
      </div>
   );
};
export default Card;
