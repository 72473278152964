import Helmet from 'react-helmet';
import { FacebookPage } from './facebook-page';
import React from 'react';
import { useStore } from '@matix/gatsby-theme-core';
export const PageSchema = (props) => {
   const { store } = useStore();
   const { creationDate, modificationDate, locale } = store.pageQuery; // TODO: make sure they are exposed on nodes
   const { descriptionSeo, headline, pagePath, titleSeo, image } = store.pageQuery?.frontmatter;
   const siteMeta = store.siteMeta;

   // const seoShareImageSrc = `${siteMeta.siteUrl}/static/${siteMeta.seoImageShare?.url}`;
   const seoShareImageSrc = siteMeta.social?.socialImage?.url;
   const schema = {
      '@context': 'http://schema.org',
      '@type': 'WebPage',
      mainEntityOfPage: siteMeta.siteUrl + pagePath,
      author: {
         '@type': 'Person',
         name: siteMeta.companyName,
      },
      copyrightHolder: {
         '@type': 'Person',
         name: siteMeta.companyName,
      },
      copyrightYear: new Date().getUTCFullYear(),
      creator: {
         '@type': 'Person',
         name: siteMeta.companyName,
      },
      publisher: {
         '@type': 'Organization',
         name: siteMeta.companyName,
         logo: {
            '@type': 'ImageObject',
            url: siteMeta.logo?.url,
         },
      },
      datePublished: creationDate,
      dateModified: modificationDate,
      description: descriptionSeo,
      headline: headline,
      inLanguage: locale === 'fr' ? 'french' : locale === 'en' ? 'english' : locale,
      url: siteMeta.siteUrl + pagePath,
      name: titleSeo,
      image: {
         '@type': 'ImageObject',
         url: image?.url,
      },
   };

   return (
      <>
         <Helmet>
            <script type="application/ld+json">{JSON.stringify(schema)}</script>
         </Helmet>
         <FacebookPage
            desc={descriptionSeo ?? siteMeta.seo?.descriptionSeo ?? ''}
            image={seoShareImageSrc}
            imageWidth={1200} // based on FB recommandations
            imageHeight={630}
            title={titleSeo ?? siteMeta.seo?.titleSeo ?? siteMeta.companyName ?? ''}
            type={'website'}
            url={siteMeta.siteUrl}
            locale={locale}
            name={siteMeta.companyName}
         />
      </>
   );
};
