import React from 'react';
import { MdStringRenderer } from '../../../../md-string-renderer';
import { ImageProps, Image } from '../../../../image';
import { CardsInternalProps } from '../card.section-renderer';
import { Link } from '../../../../link';

export const Card = (props: CardsInternalProps) => {
   return (
      <div className="h-full relative flex flex-col space-y-4 md:mx-2 xl:mx-10 text-black">
         {/* header */}

         {/* img */}
         {props.image && props.image.url ? <Img {...props} /> : null}
         {props.title ? (
            <div className="py-2 text-xl md:text-2xl">
               <MdStringRenderer source={props.title} />
            </div>
         ) : null}
         {/* body */}
         {props.text ? (
            <div className="flex-grow f-full font-semibold">
               <MdStringRenderer source={props.text} />
            </div>
         ) : null}

         {/* footer */}
         {props.btnMainMd ? (
            <div className="flex-none py-2 mx-auto flex items-center">
               <button className="my-5 py-2 px-6 rounded-md bg-primary-500 text-secondary-500 hover:bg-secondary-500 hover:text-primary-500">
                  <MdStringRenderer source={props.btnMainMd} />
               </button>
            </div>
         ) : null}
      </div>
   );
};

const Img = (props: any) => {
   const urlFromBtn = props.btnMainMd?.match(/\(([^)]+)\)+$/);
   if (urlFromBtn && urlFromBtn.length) {
      return (
         <Link to={urlFromBtn[1]}>
            <div className="h-56">
               <Image {...props.image} className="object-cover object-center h-full" />
            </div>
         </Link>
      );
   } else {
      return (
         <div className="h-56">
            <Image {...props.image} className="object-cover object-center h-full" />
         </div>
      );
   }
};
export default Card;
