import React from 'react';
import { LocalImagesContext } from '../../utils/local-images-context';
import { extractChildMatixAzureImage } from '../../utils/extract-img';
import { LayoutContainer } from '../layout';
import { SetLocalImagesContext } from '../../utils/set-local-images-context';
import { StoreProvider, useGlobalTranslation, useSiteMeta, useStore } from '../../utils';
import { TemplateWrapper } from './template-wrapper';
import { useNavigations } from '../../utils/use-navigations';

export const BaseTemplate = (props) => {
   // Search in all levels for childMatixAzureImage property. It contains gatsby images.
   const siteMeta = useSiteMeta().allMatixSiteMeta?.nodes.find((meta) => meta.locale === props.data.pageQuery.locale)?.frontmatter || {};
   const translations =
      useGlobalTranslation().allMatixTranslation?.edges.find((x) => x.node.locale === props.data.pageQuery.locale)?.node?.frontmatter?.translations ||
      [];
   const childMatixAzureImages = extractChildMatixAzureImage(props.data?.pageQuery, []);

   const navigations = useNavigations().allMatixMdx?.nodes.filter((x) => x.locale === props.data.pageQuery.locale);
   const frontWithshopLink = navigations?.find((x) => x?.frontmatter?.navItems?.find((y) => y.type === 'shopifyCartButton'));
   const shopWithLink = frontWithshopLink?.frontmatter?.navItems?.find((x) => x.type === 'shopifyCartButton');

   const images = [];
   if (childMatixAzureImages?.length > 0) {
      childMatixAzureImages.forEach((azureImages) => {
         Object.keys(azureImages).forEach((key) => azureImages[key].forEach((i) => images.push(i)));
      });
   }
   SetLocalImagesContext({
      images: images,
   });

   return (
      <StoreProvider
         key={JSON.stringify(props.data.pageQuery)}
         siteMeta={siteMeta}
         pageQuery={props.data.pageQuery}
         pageQueryLocale={props.data.pageQueryLocale}
         translations={translations}
         navigations={navigations}
         shopCartUrl={shopWithLink?.link}
      >
         <TemplateWrapper key={'1'} {...props}>
            <LayoutContainer {...props}>
               <LocalImagesContext.Provider
                  value={{
                     images: images,
                     setLocalImagesContext: () => {},
                  }}
               >
                  {props.children}
               </LocalImagesContext.Provider>
            </LayoutContainer>
         </TemplateWrapper>
      </StoreProvider>
   );
};
