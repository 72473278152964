import React from 'react';
import { MdStringRenderer } from '../../../../md-string-renderer';
import { ImageProps, Image } from '../../../../image';
import { CardsInternalProps } from '../card.section-renderer';
import { Link } from '../../../../link';

export const Card = (props: CardsInternalProps) => {
   const urlFromBtn = props.btnMainMd?.match(/\(([^)]+)\)+$/);

   if (urlFromBtn && urlFromBtn.length) {
      return (
         <Link to={urlFromBtn[1]}>
            <Result {...props} />
         </Link>
      );
   } else {
      return <Result {...props} />;
   }
};

const Result = (props: CardsInternalProps) => {
   return (
      <div className="h-full relative flex flex-col space-y-4 md:mx-2 xl:mx-10 xl:mb-6 text-black">
         {/* header */}
         {props.title ? (
            <div className="flex py-2 text-xl md:text-2xl mx-auto">
               <MdStringRenderer source={props.title} />
            </div>
         ) : null}

         {props.text ? (
            <div className="flex-grow bg-primary-500 text-center mt-6 p-6 font-semibold h-full">
               <MdStringRenderer source={props.text} />
            </div>
         ) : null}

         {props.btnMainMd ? (
            <div className="flex-none bg-blue-500 rounded-md text-xl md:text-2xl py-2 px-10 my-5 text-white mx-auto">
               <MdStringRenderer source={props.btnMainMd} />
            </div>
         ) : null}
      </div>
   );
};
export default Card;
