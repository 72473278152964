import React from 'react';

export const Time = (props: React.SVGProps<SVGSVGElement>) => {
   return (
      <div>
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
            <g id="Layer_2" data-name="Layer 2">
               <g id="Layer_1-2" data-name="Layer 1">
                  <path
                     className="cls-1"
                     d="M32,0A32,32,0,1,0,64,32,32,32,0,0,0,32,0Zm0,58.67A26.67,26.67,0,1,1,58.67,32,26.72,26.72,0,0,1,32,58.67Z"
                  />
                  <path
                     className="cls-1"
                     d="M43.55,34.67l-7.1-5.34L34.67,28V13.33a2.67,2.67,0,0,0-5.34,0v16a2.64,2.64,0,0,0,.08.62,2.25,2.25,0,0,0,.22.56,1.66,1.66,0,0,0,.29.48s0,.08.08.1a2.44,2.44,0,0,0,.37.35l0,0,.11.08,4.16,3.12,6.4,4.8a2.59,2.59,0,0,0,1.6.53,2.67,2.67,0,0,0,1.6-4.8Z"
                  />
               </g>
            </g>
         </svg>
      </div>
   );
};
