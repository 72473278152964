import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import { useStore } from '../../../utils';

import { seoSchemaRegistration } from '../../seo-schema-registration';
// reference: https://developers.google.com/search/docs/data-types/article?hl=fr

export const SEO = () => {
   //const breadcrumbs = computeBreadcrumbs(prop.data.pageQuery);
   const {
      store: { siteMeta, pageQuery },
   } = useStore();
   if (!siteMeta || !pageQuery) return null;
   const isSSR = typeof window === 'undefined';
   const currentPagePath = pageQuery.frontmatter.pagePath; // If not enough for SSR, consider exposing pagePath on the pageQuery
   const currentSiteUrl = siteMeta.siteUrl + currentPagePath;
   const seoShareImageSrc = `${siteMeta.social?.socialImage?.url}`;
   const webSiteLogoUrl = `${siteMeta.social?.socialImage?.url}`;

   const currentLang = pageQuery.locale;

   // const breadcrumb = {
   //    '@type': 'BreadcrumbList',
   //    name: siteMeta.companyName,
   //    numberOfItems: breadcrumbs.length,
   //    itemListElement: breadcrumbs,
   // };

   const corporateContact = {
      '@type': 'Organization',
      url: siteMeta.siteUrl,
      address: siteMeta.postalAdress,
      email: siteMeta.email,
      logo: webSiteLogoUrl,
      legalName: siteMeta.companyName,
      contactPoint: [
         {
            '@type': 'ContactPoint',
            telephone: siteMeta.telephone,
            email: siteMeta.email,
            contactType: 'customer service',
            areaServed: 'CA',
            availableLanguage: ['French', 'English'], // todo: populate from list of locales from siteMeta
         },
      ],
   };

   // const openingHoursSpecification = siteMeta.openingHoursSpecification.map((item: any) => {
   //    const hoursSpec = {
   //       '@type': 'OpeningHoursSpecification',
   //       dayOfWeek: item.info.dayOfWeek,
   //       opens: item.info.opens,
   //       closes: item.info.closes,
   //    };
   //    return hoursSpec;
   // });

   const localBusinessInfo = {
      '@type': 'Store',
      '@id': siteMeta.siteUrl,
      url: currentSiteUrl,
      name: siteMeta.companyName,
      telephone: siteMeta.telephone,
      address: siteMeta.postalAdress,
      image: webSiteLogoUrl,
      // openingHoursSpecification,
   };

   const groupRichContentsGraph = {
      '@context': 'https://schema.org',
      '@graph': [/*breadcrumb*/ corporateContact, localBusinessInfo],
   };

   const image32 = `${siteMeta.siteUrl}/favicon-32x32.png`;
   const image16 = `${siteMeta.siteUrl}/favicon-16x16.png`;
   const image = `${siteMeta.siteUrl}/favicon.png`;
   // const customSchemaFromNodeType = seoSchemaRegistration.build(siteMeta, pageQuery);
   const nodeType = pageQuery?.frontmatter?.type;
   const schemaFragment = nodeType && seoSchemaRegistration.schemas[nodeType];

   //Todo replace seo from pagequery
   return (
      <>
         <Helmet title={pageQuery.frontmatter.titleSeo ?? siteMeta.seo.titleSeo}>
            <html lang={currentLang} />
            <meta name="robots" content="Index, Follow" />
            <meta name="description" content={pageQuery.frontmatter.descriptionSeo} />
            {seoShareImageSrc && <meta name="image" content={seoShareImageSrc} />}
            <meta name="title" content={pageQuery.frontmatter.titleSeo ?? siteMeta.seo.titleSeo} />
            <meta name="keywords" content={siteMeta.seo.keywords} />
            <link rel="icon" type="image/png" sizes="144x144" href={image} />
            <link rel="icon" type="image/png" sizes="32x32" href={image32} />
            <link rel="icon" type="image/png" sizes="16x16" href={image16} />
            <link rel="canonical" href={`${siteMeta.siteUrl}${currentPagePath}`} />
            <script type="application/ld+json">{JSON.stringify(groupRichContentsGraph)}</script>
         </Helmet>
         {schemaFragment && React.cloneElement(schemaFragment({ siteMeta, pageQuery }), { siteMeta, pageQuery })}
      </>
   );
};
