import React from 'react';
import { Form } from '../../forms/1/form';
import { Image, ImageProps, MdStringRenderer } from '../../../../';
import { FontSize, Position, TextAlignment } from '../../form.section-renderer';
import c from 'classnames';

interface FormWithImageAndTextProps {
   title: string;
   image: ImageProps;
   text: string;
   fontSizeTitle?: FontSize;
   fontSizeText?: FontSize;
   textAlignment?: TextAlignment;
   position?: Position;
   withTopBorder?: boolean;
   withBottomBorder?: boolean;
   justifyTextCenter?: boolean;
   form: any;
}
export const FormWithImageAndText: React.FC<FormWithImageAndTextProps> = (props) => {
   const {
      fontSizeTitle = '3xl',
      fontSizeText = 'xl',
      image,
      position = 'bottom',
      textAlignment = 'left',
      withTopBorder = true,
      withBottomBorder = true,
      justifyTextCenter = true,
   } = props;
   return (
      <div className="relative">
         <div className={c('lg:row-start-1', withTopBorder ? 'border-black border-t-2 w-1/2 mx-auto shadow-lg h-0 lg:col-span-2' : '')}></div>
         <div className="flex flex-col md:flex-row w-full items-center justify-between">
            <div className="text-center md:text-left w-full md:w-1/2">
               <MdStringRenderer source={props.text} />
            </div>
            <div className="w-full md:w-1/2">
               <Form {...props.form?.frontmatter} />
            </div>
         </div>
         <div className={c(withBottomBorder ? 'border-black border-t-2 w-1/2 mx-auto shadow-lg order-last h-0' : '', 'lg:row-start-4')}></div>
         <div className="absolute inset-0 z-0">{image ? <Image className="object-cover h-full lg:w-full " {...image} /> : null}</div>
      </div>
   );
};
