import React from 'react';
import { MdStringRenderer, useNavigations, extractChildMatixAzureImage, Image, renderNavItemLeaf, useStore } from '@matix/gatsby-theme-core';

export const Footer = (props) => {
   const {
      store: { siteMeta, pageQuery, translations },
   } = useStore();

   const getTranslation = (id) => {
      const result = translations.find((x) => x.identifier === id);
      if (!result) {
         return 'N/A';
      }
      return result.value;
   };

   const dataContext = useNavigations().allMatixMdx.nodes.find((n) => n.frontmatter.identifier === 'footer' && n.locale === pageQuery.locale);
   const breadCrumbsCtx = useNavigations().allMatixMdx.nodes.find((n) => n.frontmatter.identifier === 'breadcrumbs' && n.locale === pageQuery.locale);
   const findNode = (pagePath, ctx) => {
      let result = null;
      result = ctx.find((x) => {
         if (x.page?.frontmatter?.pagePath === pagePath) {
            return true;
         }
         if (x.collection?.frontmatter?.pagePath === pagePath) {
            return true;
         }
         if (x.article?.frontmatter?.pagePath === pagePath) {
            return true;
         }
         if (x.event?.frontmatter?.pagePath === pagePath) {
            return true;
         }
         if (x.shopifyCollections?.frontmatter?.pagePath === pagePath) {
            return true;
         }
         if (x.link === pagePath) {
            return true;
         }
      });
      if (result) {
         return [result];
      }

      ctx.forEach((x, index) => {
         if (x.navItems && !result) {
            let isChild = null;
            isChild = findNode(pagePath, x.navItems);
            if (isChild) {
               let linkType = isChild;
               if (isChild[0].type === 'subMenu') {
                  linkType[0] = x.navItems.find((y) => {
                     if (y.type !== 'subMenu' && y.title === isChild[0].title) {
                        return true;
                     }
                  });
               }
               let xLinkType = x;
               if (xLinkType.type === 'subMenu') {
                  xLinkType = ctx.find((y) => {
                     if (y.type !== 'subMenu' && y.title === x.title) {
                        return true;
                     }
                  });
               }
               result = [xLinkType].concat(linkType);
            }
         }
      });

      return result;
   };

   let breadCrumbsPath;
   // deactivate breacurmb for now, structure of footer has changed
   //const breadCrumbsPath = findNode(pageQuery.frontmatter.pagePath, breadCrumbsCtx.frontmatter.navItems);

   const data = dataContext?.frontmatter;
   const dataSecondFooter = useNavigations().allMatixMdx.nodes.find(
      (n) => n.frontmatter.identifier === 'secondfooter' && n.locale === pageQuery.locale
   )?.frontmatter;

   const imgFooterMatixImages = extractChildMatixAzureImage(dataContext, []);
   const imgFooter = imgFooterMatixImages[0].navigationImages.length > 0 ? imgFooterMatixImages : null;
   const footerData = data?.navItems?.filter((x) => x.type !== 'logo');
   return (
      <>
         {breadCrumbsPath ? (
            <div className="flex flex-wrap w-full bg-gray-800 text-footer text-xs md:text-base px-4 md:px-20 py-6">
               {breadCrumbsPath.map((x, index) => {
                  const link =
                     x.page?.frontmatter?.pagePath ||
                     x.collection?.frontmatter?.pagePath ||
                     x.article?.frontmatter?.pagePath ||
                     x.event?.frontmatter?.pagePath ||
                     x.shopifyCollections?.frontmatter?.pagePath ||
                     x.link;

                  return (
                     <>
                        {index !== breadCrumbsPath.length - 1 ? (
                           <div className="uppercase hover:opacity-75 pr-1">
                              <MdStringRenderer source={`[${x.title}](${link}) > `} />
                           </div>
                        ) : (
                           <div className="uppercase hover:opacity-75 ">
                              <MdStringRenderer source={`[${x.title}](${link})`} />
                           </div>
                        )}
                     </>
                  );
               })}
            </div>
         ) : null}
         <div className="w-full flex flex-col bg-footer text-footer px-4 md:px-10 py-10">
            <div className="flex w-full justify-center text-center md:mb-4">
               <div className="flex flex-col">
                  {imgFooter && (
                     <div className="w-16 mx-auto">
                        <Image className="object-cover" fluid={imgFooter[0].navigationImages[0].childImageSharp.fluid} />
                     </div>
                  )}

                  <div className="text-xl font-semibold">
                     <MdStringRenderer source={siteMeta.companyName} />
                  </div>
                  <div className="text-xs mb-6">
                     <MdStringRenderer source={siteMeta.companySloguan} />
                  </div>
               </div>
            </div>
            <div className="w-full flex flex-wrap justify-center xl:container xl:mx-auto">
               {footerData?.map(
                  (col) =>
                     col.title !== 'NEWSLETTERS' && (
                        <div className="flex flex-col mb-6 mx-auto text-left w-full md:w-1/2 xl:w-auto r">
                           <div className="text-xl w-full pb-4 md:mx-auto">
                              <MdStringRenderer source={col.title} />
                           </div>
                           <div className="flex flex-col space-y-3">
                              {col.navItems?.map((sec) => (
                                 <div className="flex flex-col space-y-3 text-gray-400">
                                    {sec.navItems && sec.title !== 'NEWSLETTERS' ? (
                                       <>
                                          <div className="text-lg">
                                             <MdStringRenderer source={sec.title} />
                                          </div>
                                          {sec.navItems[0]?.type === 'linkSocial' ? (
                                             <div className="flex flex-row space-x-3">{sec.navItems?.map((nav) => renderNavItemLeaf(nav))}</div>
                                          ) : null}
                                       </>
                                    ) : null}
                                    {renderNavItemLeaf(sec)}
                                 </div>
                              ))}
                           </div>
                        </div>
                     )
               )}
            </div>
            <div className="w-full flex flex-wrap justify-center">
               {footerData?.map(
                  (col) =>
                     col.title === 'NEWSLETTERS' && (
                        <div className="flex flex-col mb-6 mx-auto md:ml-0 md:mr-6 md:my-4 text-center md:text-left">
                           <div className="text-xl pb-4 mx-auto md:mx-0">
                              <MdStringRenderer source={col.title} />
                           </div>

                           {col.navItems?.map((sec) => (
                              <div className=" mx-auto md:mx-0 text-center md:text-left">
                                 {sec.navItems && sec.title !== 'NEWSLETTERS' ? (
                                    <div className="text-lg text-center my-4 md:text-left">
                                       <MdStringRenderer source={sec.title} />
                                    </div>
                                 ) : null}
                                 {renderNavItemLeaf(sec)}
                              </div>
                           ))}
                        </div>
                     )
               )}
            </div>
            {dataSecondFooter && dataSecondFooter.navItems?.length > 0 ? (
               <>
                  <div className="mt-4">
                     <MdStringRenderer source={'---'} />
                     <div className="w-full flex flex-wrap mt-4 justify-center">
                        {dataSecondFooter.navItems.map((item) => {
                           return <div className="mx-6 md:mx-2 xl:mx-6 hover:opacity-90">{renderNavItemLeaf(item)}</div>;
                        })}
                     </div>
                  </div>
                  <div className="w-full flex flex-wrap mt-4 justify-center">
                     <MdStringRenderer source={`${getTranslation('WebSiteCreatedBy')} -  ${new Date().getFullYear()}`} />
                  </div>
               </>
            ) : null}
         </div>
      </>
   );
};
