import React from 'react';
import { CarouselHeroSectionRenderer } from './sections/hero/carousel-hero/carousel-hero.section-renderer';
import { ImageHeroSectionRenderer } from './sections/hero/image/image-hero.section-renderer';
import { HeadlineSectionRenderer } from './sections/text/headline/headline.section-renderer';
import { CarouselSectionRenderer } from './sections/carousel/carousel.section-renderer';

import Loadable from '@loadable/component';
import { ImageWithTextSectionRenderer } from './sections/text/image-with-text/image-with-text.section-renderer';
import CardSectionRenderer from './sections/text/card/card.section-renderer';
import MarkdownSectionRenderer from './sections/text/markdown/markdown.section-renderer';

const BlogRenderer = Loadable(() => import(/* webpackPrefetch: true */ './sections/blog-section/blog/blog-renderer'));
const FormSectionRenderer = Loadable(() => import(/* webpackPrefetch: true */ './sections/form/form.section-renderer'));
export class WidgetRegistration {
   widgets = {};

   register(widgetName, widget) {
      this.widgets[widgetName] = widget;
      return this;
   }
}

export const widgetRegistration = new WidgetRegistration();

widgetRegistration
   .register('heroCarousel', (props) => <CarouselHeroSectionRenderer {...props} />)
   .register('heroImage', (props) => <ImageHeroSectionRenderer {...props} />)
   .register('imageWithText', (props) => <ImageWithTextSectionRenderer {...props} />)
   .register('carousel', (props) => <CarouselSectionRenderer {...props} />)
   .register('textImageCard', (props) => <CardSectionRenderer {...props} />)
   .register('markdown', (props) => <MarkdownSectionRenderer {...props} />)
   .register('headline', (props) => <HeadlineSectionRenderer {...props} />)
   .register('formWithText', (props) => <FormSectionRenderer {...props} />) // TODO: this form is missing, bring it from regimbald
   .register('simpleForm', (props) => <FormSectionRenderer {...props} />)
   .register('blog', (props) => <BlogRenderer {...props} />);
