import React, { useContext } from 'react';
import { SectionRenderer } from '../section-renderer';
import { BaseTemplate } from './base-template';

export default (props) => {
   return (
      <BaseTemplate {...props}>
         <SectionRenderer {...props} />
      </BaseTemplate>
   );
};
