/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext } from 'react';
import GatsbyImage, { FluidObject, GatsbyImageOptionalProps, FixedObject } from 'gatsby-image';
import { LocalImagesContext } from '../utils/local-images-context';
export interface ImageProps extends ImageFluidProps, ImageFixedProps {
   url?: string;
   className?: string;
   title?: string;
   canShare?: boolean;
}

interface ImageFluidProps extends GatsbyImageOptionalProps {
   fluid?: FluidObject | FluidObject[];
}

interface ImageFixedProps extends GatsbyImageOptionalProps {
   fixed?: FixedObject | FixedObject[];
}

const urlToUniqueName = (url) => {
   // IMPORTANT: This function must match exactly the one from plugin-azure-external-image
   return url
      .split('')
      .reduce((prevHash, currVal) => ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0, 0)
      .toString();
};

export const Image = (props: ImageProps) => {
   const localImagesCtx = useContext(LocalImagesContext);

   const { url } = props;
   const getImageFromContext = (key) => {
      let img;

      if (key.startsWith('http')) {
         const imgKey = urlToUniqueName(key);
         img = localImagesCtx.images.find((localImage) => {
            if (localImage) {
               return localImage.name === imgKey;
            }
            return false;
         });
      } else {
         img = localImagesCtx.images.find((localImage) => localImage.name === key);
      }
      if (!img && typeof window !== 'undefined') {
         //console.debug(`The image with url ${key} is not processed and optimized by Gatsby. Consider adding url to local build.`);
         return null;
      }
      return img;
   };

   const imgFromContext = url && getImageFromContext(url);
   const isFromCtx = imgFromContext === null ? false : true;
   if (isFromCtx && imgFromContext && imgFromContext.childImageSharp) {
      return <GatsbyImage {...props} fluid={imgFromContext.childImageSharp.fluid} fixed={imgFromContext.childImageSharp.fixed}></GatsbyImage>;
   } else {
      return (
         <>
            {url ? (
               <img alt={props.alt} src={url} className={props.className} draggable="false"></img>
            ) : (
               <GatsbyImage {...props} fluid={props.fluid}></GatsbyImage>
            )}
         </>
      );
   }
};
