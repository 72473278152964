import React from 'react';
import { CardsInternalProps } from '../card.section-renderer';
import { MdStringRenderer, ImageProps, Image, Link } from '@matix/gatsby-theme-core';

export const Card = (props: CardsInternalProps) => {
   return (
      <div className="h-full flex flex-col space-y-4 md:mx-2 xl:mx-10 text-primary-light">
         {/* header */}
         {props.title ? (
            <div className="flex py-2 text-xl md:text-2xl mx-auto">
               <MdStringRenderer source={props.title} />
            </div>
         ) : null}

         {/* img */}
         {props.image && props.image.url ? <Img {...props} /> : null}

         {/* body */}
         {props.text ? (
            <div className="flex-grow text-base 2xl:text-xl bg-primary-light p-4">
               <MdStringRenderer source={props.text} />
            </div>
         ) : null}

         {/* footer */}
         {props.btnMainMd ? (
            <div className="flex-none mx-auto flex items-center ">
               <button className="bg-primary-light my-3 py-2 px-6 rounded-md hover:bg-primary-dark hover:text-primary-dark">
                  <MdStringRenderer source={props.btnMainMd} />
               </button>
            </div>
         ) : null}
      </div>
   );
};

const Img = (props: any) => {
   const urlFromBtn = props.btnMainMd?.match(/\(([^)]+)\)+$/);
   if (urlFromBtn && urlFromBtn.length) {
      return (
         <Link to={urlFromBtn[1]}>
            <div className="md:h-72 xl:h-96">
               <Image {...props.image} {...{ loading: 'eager', fadeIn: false }} className="object-cover object-center h-full" />
            </div>
         </Link>
      );
   } else {
      return (
         <div className="md:h-72 xl:h-96">
            <Image {...props.image} {...{ loading: 'eager', fadeIn: false }} className="object-cover object-center h-full" />
         </div>
      );
   }
};
export default Card;
