import React from 'react';
import { MdStringRenderer } from '@matix/gatsby-theme-core';
export const Breadcrumbs = (props) => {
   if (!props.breadcrumbs) {
      return null;
   }
   return (
      <div className="flex flex-wrap text-xs xl:text-base xl:mb-4 font-light pt-5 pb-3">
         {props.breadcrumbs.map((x, index) => {
            return (
               <>
                  {index !== 0 && (
                     <div className="px-1 md:px-2">
                        <MdStringRenderer source={`/`} />
                     </div>
                  )}
                  <div className="uppercase hover:opacity-75">
                     <MdStringRenderer source={x} />
                  </div>
               </>
            );
         })}
      </div>
   );
};
