/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import { Link } from '../../../../link';

export const config = (extraScopes) => {
   const components = {
      h1: (props) => <h1 className="text-base md:text-base font-semibold pb-5 pt-5" {...props} />,
      h2: (props) => <h2 className="text-base md:text-base font-semibold pb-3 pt-5" {...props} />,
      h3: (props) => <h3 className="text-base md:text-base font-semibold pb-3 pt-5" {...props} />,
      h4: (props) => <h4 className="text-base md:text-base font-semibold pb-2 pt-5" {...props} />,
      h5: (props) => <h5 className="text-base md:text-base font-semibold pb-2 pt-5" {...props} />,
      h6: (props) => <h6 className="text-base md:text-base font-semibold pb-2 pt-5" {...props} />,
      p: (props) => <p className="pb-4 text-justify text-sm md:text-base" {...props}></p>,
      a: (props) => <Link to={props.href} className="italic text-blue-500 underline hover:text-blue-700 hover:no-underline" {...props} />,
      blockquote: (props) => (
         <blockquote className="bg-primary-light text-2xl font-extralight border-l-4 border-black flex my-5 py-5 pl-8 pr-5 italic" {...props}>
            {props.children}
         </blockquote>
      ),
      ...extraScopes,
   };
   const shortCodes = {
      ...extraScopes,
   };
   return { components, shortCodes };
};
