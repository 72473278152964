import { ArticleSchema } from '../components/support/seo/article-schema';
import { PageSchema } from '../components/support/seo/page-schema';
import React from 'react';
export class SEOSchemaRegistration {
   schemas = {};

   register(schemaName, widget) {
      this.schemas[schemaName] = widget;
      return this;
   }
}

export const seoSchemaRegistration = new SEOSchemaRegistration();

seoSchemaRegistration.register('articles', (props) => <ArticleSchema {...props} />).register('pages', (props) => <PageSchema {...props} />);
