import React, { useContext } from 'react';
import { MdStringRenderer, Time, Duration, Marker, useStore } from '@matix/gatsby-theme-core';
import { Image } from '@matix/gatsby-theme-core';
import { CardEventProps } from '../card-event.renderer';
import dateFormat from 'dateformat';

export const Card = (props: CardEventProps) => {
   const { store } = useStore();
   const frontMatterProps = store.pageQuery.frontmatter;
   const eventDate = frontMatterProps.date ? frontMatterProps.date : frontMatterProps.publishDate;
   const displayDate = dateFormat(new Date(eventDate), 'yyyy-mm-dd  h:MM TT ( Z )');
   const duration = props.duration ? props.duration : 'Durée : 2h';
   const location = props.location ? props.location : 'Webinar';
   //TODO: useCart but theme events should be aware of shopify. instead, use a render prop where the action is rendered as a prop that will add to shopify cart
   // Need to  shadow the file.. i.e. <CardEventShadowed><CardEvent render={{onClick=()=>addToShopifycart()}}>... Events need to be decoupled from shopify
   return (
      <div className="flex w-full flex-wrap bg-primary-500 shadow-2xl h-96">
         <Image {...props.image} className="object-cover w-full xl:w-2/3 h-full" />
         <div className="flex flex-col w-full xl:w-1/3 p-6 text-black items-center justify-center space-y-5">
            <div className="flex items-center">
               <Time className="w-4 mr-2"></Time>
               <MdStringRenderer source={`Date : ${displayDate.toString()}`} />
            </div>
            <div className="flex items-center">
               <Duration className="w-4 mr-2" />
               <MdStringRenderer source={duration} />
            </div>
            <div className="flex items-center">
               <Marker className="w-4 mr-2" />
               <MdStringRenderer source={location} />
            </div>
            <MdStringRenderer source={props.price} />
            <button
               className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-1/2"
               disabled={true}
               // onClick={() => addItem({ shopifyId: props.shopifyId ?? 'events' })}
            >
               <MdStringRenderer source={props.btnMainMd} />
            </button>
            {props.shareOpt ? <MdStringRenderer source={'PARTAGER: WIP'} /> : null}
         </div>
      </div>
   );
};
export default Card;
