import React from 'react';
import { ImageProps } from '../../image';
import { CarouselSpring } from '../carousel/carousel-spring';
import { useFullscreenCarousel } from './fullscreen-carousel/fullscreen-carousel';

/**
 * Simple image gallery with no texts.
 * @param props
 * @returns
 */
export const ImageGalleryMtx = (props) => {
   const { images } = props;
   const slides = images?.map((p) => ({ image: p }));
   const fullscreenApi = useFullscreenCarousel({ images });
   return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
         {fullscreenApi.fragment}
         <div className="">
            {slides ? (
               <CarouselSpring
                  className="w-full h-full"
                  fullscreenApi={fullscreenApi}
                  slides={slides}
                  withThumbnails={true}
                  withControls={props.withControl}
                  withLoop={false} /**There is a bug when setting this to true and using thumbnails */
               ></CarouselSpring>
            ) : null}
         </div>
      </>
   );
};
