import React from 'react';

export const MielBotRenderer = ({ formState, setFormValueI }) => {
   return (
      <div className="hidden">
         <label htmlFor="miel-bot" className="hidden" />
         <input
            className="form-control"
            type="text"
            name="miel-bot"
            value={formState.mielBot}
            onChange={(e) => setFormValueI({ mielBot: e.target.value })}
         />
      </div>
   );
};
