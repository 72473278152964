import React from 'react';
import { Link, MdStringRenderer } from '@matix/gatsby-theme-core';
import { Image } from '@matix/gatsby-theme-core';

export const Card = (props) => {
   let mainImage = null;
   let mainPrice = 'N/A';
   if (props.variants && props.variants.length) {
      mainPrice = props.variants[0]?.shopifyProductVariant?.price ?? 'N/A';
      if (props.variants[0].images) {
         mainImage = props.variants[0]?.images[0];
      } else {
         mainImage = {
            url: 'https://res.cloudinary.com/groupebt/image/upload/v1621511354/Boutique%20Black%20%20and%20Tough/dummy_ahxye1.png',
         };
      }
   }
   return (
      <div key={props.key} className="group flex flex-col justify-center  pb-5">
         <div className="flex-grow w-full h-60 md:h-96 xl:h-96 overflow-hidden">
            <Link to={props.pagePath} state={{ breadcrumbs: props.currentParentCollectionNames }}>
               <Image
                  {...mainImage}
                  // {...{ loading: 'eager', fadeIn: false }} TODO: fix make image resizes after load
                  className="object-cover w-full h-full transition-all transform duration-500 ease-in-out group-hover:scale-105"
               />
            </Link>
         </div>
         <div className=" p-2 group-hover:bg-primary-dark group-hover:text-primary-dark ">
            <div className="text-xs md:text-base tracking-widest font-light">
               <MdStringRenderer className="" source={props.productName} />
            </div>
            <div className="text-xs md:text-sm font-bold">
               <MdStringRenderer source={`${mainPrice} $`} />
            </div>
         </div>
      </div>
   );
};
