import React from 'react';
import { InlineShareButtons } from 'sharethis-reactjs';
import { MdStringRenderer } from './md-string-renderer';
import c from 'classnames';
import { useStore } from '../utils/store-context';
export const ShareComponent = (props) => {
   const {
      store: { siteMeta, pageQuery },
   } = useStore();
   return (
      <div className="flex flex-col w-full my-6">
         <div className="flex w-full items-center">
            <div className="w-1/3 border-t-2" />
            <div className="w-1/3 text-center">
               <MdStringRenderer source={siteMeta.social?.shareText} />
            </div>
            <div className="w-1/3 border-t-2" />
         </div>
         <div className={c(props.className ? props.className : 'custom-share', 'w-full')}>
            <InlineShareButtons
               config={{
                  alignment: 'center', // alignment of buttons (left, center, right)
                  color: 'white', // set the color of buttons (social, white)
                  enabled: true, // show/hide buttons (true, false)
                  font_size: 14, // font size for the buttons
                  labels: null, // button labels (cta, counts, null)
                  language: props.locale, // which language to use (see LANGUAGES)
                  networks: siteMeta?.social?.shareNetworks,
                  padding: 4, // padding within buttons (INTEGER)
                  radius: 4, // the corner radius on each button (INTEGER)
                  show_total: false,
                  size: 30, // the size of each button (INTEGER)

                  // OPTIONAL PARAMETERS
                  url: pageQuery.frontmatter?.pagePath ? siteMeta.siteUrl + pageQuery.frontmatter?.pagePath : siteMeta.siteUrl,
                  // image: image.url,
                  // title: image.title,
                  message: siteMeta.social?.defaultShareMessage ?? siteMeta.descriptionSeo, // (only for email sharing)
                  subject: siteMeta.social?.defaultShareSubject ?? siteMeta.companyName, // (only for email sharing)
                  username: siteMeta.social?.twitterUsername, // (only for twitter sharing)
               }}
            />
         </div>
      </div>
   );
};
