//TODO: control classes from theme options. Maybe access siteMeta to get for instance the width of the site, the main layout, etc.

import React, { useState } from 'react';
import { Footer } from './footer';
import { Header } from './header';
import { SectionContainer } from './section-container';
import { SiteContainer } from './site-container';
import { SEO } from './seo';
import { ShareThis } from './support/seo/share-this';
import c from 'classnames';
import { useStore } from '../utils/store-context';
import Helmet from 'react-helmet';
import { Banner } from './banner';
export const LayoutContainer = (props) => {
   const [menuOpen, setMenuOpen] = useState(false);
   const { store } = useStore();
   props.siteMeta = store.siteMeta; // temporary until everyone uses useStore
   return (
      <SiteContainer>
         {!menuOpen ? <Banner banner={props.siteMeta.banner} /> : null}
         <Header {...props} render={(menuState) => setMenuOpen(menuState[0])}></Header>
         <SEO {...props} />
         <Helmet>
            <body className={menuOpen && 'overflow-hidden'}></body>
         </Helmet>
         <SectionContainer {...props}>
            <div
               className={c(
                  'transform transition ease-in-out duration-1000 fixed inset-0 top-14 w-full',
                  menuOpen ? 'z-10 opacity-100' : '-z-10 opacity-0'
               )}
               style={{ backdropFilter: 'blur(2px)' }}
            ></div>

            {props.children}
         </SectionContainer>
         <ShareThis {...props} custom={true} />
         <Footer {...props}></Footer>
      </SiteContainer>
   );
};
