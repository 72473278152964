import * as React from 'react';
import { useState } from 'react';
import c from 'classnames';
export const AnimatedBurger = (props) => {
   const [isOpen, setIsOpen] = useState(false);
   return (
      <button
         type="button"
         onClick={() => setIsOpen(!isOpen)}
         className="bg-white w-10 h-10 rounded-md inline-flex items-center justify-center focus:outline-none "
      >
         {!isOpen && <span className="sr-only">Open menu</span>}
         {isOpen && <span className="sr-only">Close menu</span>}

         <span
            aria-hidden="true"
            className={c(
               'block absolute h-0.5 w-5 bg-primary transform transition duration-500 ease-in-out',
               isOpen ? 'rotate-45' : '-translate-y-1.5'
            )}
         ></span>
         <span
            aria-hidden="true"
            className={c('block absolute  h-0.5 w-5 bg-primary transform transition duration-500 ease-in-out', isOpen ? 'opacity-0' : '')}
         ></span>
         <span
            aria-hidden="true"
            className={c(
               'block absolute  h-0.5 w-5 bg-primary transform transition duration-500 ease-in-out',
               isOpen ? '-rotate-45' : 'translate-y-1.5'
            )}
         ></span>
         {props.render && props.render([isOpen, setIsOpen])}
      </button>
   );
};
