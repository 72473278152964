/**
 * When switching language, stay on the same page but the localized version
 */

import React from 'react';
import { useStore } from '../utils';
import { Link } from './link';
import { MdStringRenderer } from './md-string-renderer';

export const PageLocaleSwitcher = ({ targetLocale, className, title }) => {
   const { pageQueryLocale } = useStore().store;
   const targetLink = pageQueryLocale.nodes.find((pq) => pq.locale === targetLocale);

   return (
      <Link className={className} to={targetLink?.frontmatter.pagePath}>
         <MdStringRenderer source={title} />
      </Link>
   );
};
