import React from 'react';
import c from 'classnames';

export const ImageHeroSectionWrapper = (props) => {
   return (
      <section {...props} className={c(props.className ?? '', 'mt-8')}>
         {props.children}
      </section>
   );
};
