import React from 'react';
import { ImageProps, CarouselSlider } from '@matix/gatsby-theme-core';
import { Carousel } from './1/carousel';
import { CarouselThumbnail } from './1/carousel-thumbnail';
import { CarouselSectionWrapper } from './carousel.section-renderer.wrapper';
export interface CarouselSectionProps {
   type: string;
   visible: boolean;
   style: string;
   slides: [CarouselProps];
}

export interface CarouselProps {
   mainTitle: string;
   subTitle: string;
   btnMainMd: string;
   btnSecondaryMd: string;
   image: ImageProps;
}

export interface CarouselThumbnailProps {
   thumbnailText: string;
   // iconName: string;
   id?: string;
   onClick?: () => void;
   isActive?: (idx) => boolean;
}

export interface CarouselSliderProps extends CarouselProps, CarouselThumbnailProps {}

export const CarouselSectionRenderer = (props) => {
   return (
      <CarouselSectionWrapper>
         <InternalCarouselSectionRenderer {...props} />
      </CarouselSectionWrapper>
   );
};

export const InternalCarouselSectionRenderer = (props: CarouselSectionProps) => {
   const slides =
      props?.slides?.reduce((previous, item: CarouselSliderProps) => {
         previous.push(prepareSlide(item, props.style));
         return previous;
      }, []) || [];

   const sprConfig = { duration: 750 };
   return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
         {slides.length > 0 ? (
            <CarouselSlider
               className={'z-10 flex w-full md:w-1/2 justify-center mt-10 md:-mt-10'}
               springConfig={sprConfig}
               autoplay={true}
               // withLoop={true}
               withThumbnails={true}
               slides={slides}
               customThumbnails={true}
            ></CarouselSlider>
         ) : null}
      </>
   );
};

function prepareSlide(props: CarouselSliderProps, style: string) {
   return {
      slideNode: (
         <Carousel
            mainTitle={props.mainTitle}
            subTitle={props.subTitle}
            btnSecondaryMd={props.btnSecondaryMd}
            btnMainMd={props.btnMainMd}
            image={props.image}
         />
      ),
      thumbnailNode: <CarouselThumbnail {...props} />,
   };
}
