import React from 'react';
import { BaseTemplate } from '@matix/gatsby-theme-core';
import { CartPage } from '../cart';

export const CartTemplate = (props) => {
   return (
      <BaseTemplate {...props}>
         <CartPage {...props} />
      </BaseTemplate>
   );
};
