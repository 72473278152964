import React from 'react';
import { MdStringRenderer } from '../../../../md-string-renderer';
import { ImageProps, Image } from '../../../../image';
import { Link } from '../../../../link';

interface CardProps {
   title: string;
   text: string;
   btnMainMd: string;
   image: ImageProps;
}

export const Card = (props: CardProps) => {
   const urlFromTitle = props.title?.match && props.title?.match(/\(([^)]+)\)+$/);

   if (urlFromTitle && urlFromTitle.length) {
      return (
         <Link to={urlFromTitle[1]}>
            <Result {...props} />
         </Link>
      );
   } else {
      return <Result {...props} />;
   }
};

const Result = (props: CardProps) => {
   return (
      <div className="group relative h-60 md:h-96 shadow-2xl transition-all transform duration-500 ease-in-out hover:scale-95">
         <div className="absolute inset-0 z-10 w-full h-full group-hover:bg-white group-hover:bg-opacity-50" />
         {props.title ? (
            <div className="absolute bg-gradient-to-b from-white flex z-10 w-full justify-around py-4 px-10 font-bold text-black text-2xl md:text-4xl group-hover:z-20">
               <MdStringRenderer source={props.title} />
               {props.title?.length > 0 && (
                  <div className="self-center">
                     <p>{'>'}</p>
                  </div>
               )}
            </div>
         ) : null}

         <div className="absolute inset-0 w-full">
            <Image {...props.image} className="object-cover w-full h-full" />
         </div>
      </div>
   );
};

export default Card;
