import React from 'react';
import c from 'classnames';

export const CarouselThumbnail = (props) => {
   return (
      <div
         onClick={() => props.onClick()}
         className={c(
            'w-4 h-4 mx-2 rounded-full border',
            props.isActive(props.id) ? 'bg-primary-light border-black' : 'bg-primary-dark border-white'
         )}
      ></div>
   );
};
