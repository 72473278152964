import React, { useContext, useState } from 'react';
import { MdStringRenderer, MarkdownSectionRenderer, useStore, isSSR } from '@matix/gatsby-theme-core';
import { CarouselSpring1, CarouselSlide1, ThumbnailSpring1, ShareComponent } from '@matix/gatsby-theme-core';
import { Carousel } from './carousel/carousel';
import { CarouselThumbnail } from './carousel/carousel-thumbnail';
import { Breadcrumbs } from '../breadcrumbs';
import c from 'classnames';
import { useCart } from '../cart-context';

export const ProductDetails = (props) => {
   const { store } = useStore();
   let state;
   if (!isSSR()) {
      state = props.location?.state?.breadcrumbs;
   }
   if (!state) {
      state = [`[${props.collections[0].frontmatter.headline.title}](${props.collections[0].frontmatter.pagePath})`];
   }

   const [breadcrumb, setBreadcrumb] = useState(state);
   const [itemCount, setItemCountState] = useState('1');

   const prd = store.pageQuery.frontmatter;
   const cart = useCart();
   const cartStore = cart.store;

   const prdGlobalTranslate = prd.productTexts?.find((x) => x.frontmatter?.identifier === 'global-translation')?.frontmatter?.translations;
   const getTranslation = (id) => {
      const result = prdGlobalTranslate?.find((x) => x.identifier === id);
      if (!result) {
         return 'N/A';
      }
      return result.value;
   };
   let typeOptions = [];
   if (prd.variants && prd.variants.length) {
      prd.variants.forEach((x) => {
         x.variantTags?.forEach((y) => {
            const typeFound = prdGlobalTranslate?.find((k) => y.identifier.startsWith(k.identifier));
            const isInOptions = typeOptions?.find((x) => x.identifier === typeFound.identifier);
            if (!isInOptions && typeFound) {
               typeOptions.push(typeFound);
            }
            if (typeFound && typeFound.options) {
               const optFound = typeFound.options.find((k) => k === y.value);
               if (!optFound) {
                  typeFound.options.push(y.value);
               }
            } else if (typeFound) {
               typeFound.options = [];
               typeFound.options.push(y.value);
            }
         });
      });
   }

   const prdOptions = typeOptions?.filter((x) => x.options && x.options.length);
   const defaultOptions = prdOptions?.map((x) => {
      return {
         name: x.identifier,
         value: x.options[0],
      };
   });
   const [optionsSelected, setOptionSelected] = useState(defaultOptions);

   let imageIndexToShow = prd.variants.findIndex((x) => {
      const tags = x.variantTags;
      const isMatch = tags?.every((x) => {
         const opt = optionsSelected?.find((y) => x.identifier.startsWith(y.name));
         if (!opt) return false;
         return opt.value === x.value;
      });

      return isMatch;
   });

   if (imageIndexToShow < 0) {
      imageIndexToShow = 0;
   }
   const prdImgs = prd.variants[imageIndexToShow]?.images ? prd.variants[imageIndexToShow].images : prd.variants[0].images;
   const springs = prdImgs.map((item, idx) => {
      return {
         id: idx.toString(),
         slideNode: <CarouselSlide1 image={{ url: item.url, alt: item.alt }} text={item.text} />,
         thumbnailNode: <ThumbnailSpring1 image={{ url: item.url, alt: item.alt }} text={item.text}></ThumbnailSpring1>,
      };
   });

   const setOptions = (x, y) => {
      const newOptions = optionsSelected.map((opt) => {
         if (opt.name === prdOptions[x].identifier) {
            return { name: prdOptions[x].identifier, value: prdOptions[x].options[y] };
         } else {
            return opt;
         }
      });
      setOptionSelected(newOptions);
   };

   const setCount = (e) => {
      const num = parseInt(itemCount);
      const result = e === '+' ? num + 1 : num - 1;
      setItemCountState(result.toString());
   };

   const addToCart = () => {
      const variantId = btoa(prd.variants[imageIndexToShow].shopifyVariantId);
      cart.addVariantToCart(variantId, itemCount);
   };
   const hasVariantForSell = !!prd.variants[imageIndexToShow]?.shopifyProductVariant?.availableForSale;
   return (
      <>
         <div className="container mx-auto md:px-10 sticky top-10 pt-0 pl-2 md:pt-10 xl:pt-2 bg-white z-10">
            <Breadcrumbs breadcrumbs={breadcrumb} />
         </div>
         <div className="container mx-auto md:px-10 mt-14 px-2 md:mt-20 mb-6">
            <div className="flex flex-col xl:flex-row w-full">
               {/* images */}
               <div className="w-full md:w-2/3 xl:w-1/2 mx-auto">
                  <CarouselSpring1 className="" {...props} withThumbnails={true} withLoop={false} slides={springs}></CarouselSpring1>
               </div>
               {/* Information */}
               <div className="flex flex-col px-6 space-y-6 mt-6 md:mt-0 xl:w-1/2">
                  <div className="text-xl md:text-3xl sticky top-0">
                     <MdStringRenderer source={`# ${prd.productName}`} />
                  </div>
                  {prd.vendor?.productVendor ? (
                     <div className="text-base md:text-base">
                        <div className="font-semibold">
                           <MdStringRenderer source={getTranslation('seller')} />
                        </div>
                        <div className="text-sm md:text-base">
                           <MdStringRenderer source={prd.vendor.productVendor} />
                        </div>
                     </div>
                  ) : null}
                  {prd.variants[imageIndexToShow]?.shipping?.originCountry ? (
                     <div className="text-base md:text-base">
                        <div className="font-semibold">
                           <MdStringRenderer source={getTranslation('country')} />
                        </div>
                        <div className="text-sm md:text-base">
                           {/* TODO Add it back. Look for shopify query */}
                           <MdStringRenderer source={getTranslation(prd.variants[imageIndexToShow].shipping?.originCountry)} />
                        </div>
                     </div>
                  ) : null}
                  {prd.summary ? (
                     <div className="text-sm md:text-base">
                        <MdStringRenderer source={prd.summary} />
                     </div>
                  ) : null}
                  {prdOptions && prdOptions.length ? (
                     <div className="flex flex-col text-sm md:text-base">
                        <div className="font-semibold">
                           <MdStringRenderer source={getTranslation('choices')} />
                        </div>
                        {prdOptions?.map((x, index) => {
                           return (
                              <div key={index} className="ml-4">
                                 <MdStringRenderer source={x.value} />
                                 <div className="flex flex-wrap">
                                    {x.options.map((y, yidx) => {
                                       return (
                                          <div
                                             key={yidx}
                                             className={c(
                                                'border mr-2 mt-2 px-4 py-2 cursor-pointer',
                                                y === optionsSelected[index].value ? 'font-bold' : ''
                                             )}
                                             onClick={() => setOptions(index, yidx)}
                                          >
                                             <MdStringRenderer source={y} />
                                          </div>
                                       );
                                    })}
                                 </div>
                              </div>
                           );
                        })}
                     </div>
                  ) : null}
                  {prd.variants[imageIndexToShow] ? (
                     <div className="text-base mx-auto md:mx-0">
                        <MdStringRenderer source={`${prd.variants[imageIndexToShow]?.shopifyProductVariant?.price} $`} />
                     </div>
                  ) : null}
                  <div className="flex flex-col text-sm md:text-base mx-auto md:mx-0">
                     <div className="font-semibold mx-auto md:mx-0">
                        <MdStringRenderer source={getTranslation('quantity')} />
                     </div>
                     <div className="flex flex-row justify-center border h-8 md:h-10 w-28 md:w-52 relative">
                        <button
                           className="font-semibold border-r bg-primary-light text-primary-light h-full w-20 flex rounded-l focus:outline-none cursor-pointer"
                           onClick={() => setCount('-')}
                        >
                           <span className="m-auto">-</span>
                        </button>
                        <input type="hidden" className="md:p-2 p-1 text-xs md:text-base  focus:outline-none text-center" name="custom-input-number" />
                        <div className="w-24 text-xs md:text-base flex items-center justify-center cursor-default">
                           <input
                              type="number"
                              className="focus:outline-none text-center w-full font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700 outline-none"
                              name="custom-input-number"
                              value={itemCount}
                              min={'1'}
                           ></input>
                        </div>

                        <button
                           className="font-semibold border-l bg-primary-light text-primary-light h-full w-20 flex rounded-r focus:outline-none cursor-pointer"
                           onClick={() => setCount('+')}
                        >
                           <span className="m-auto">+</span>
                        </button>
                     </div>
                  </div>
                  <button
                     className="px-4 py-2 text-center bg-primary-dark text-primary-dark font-bold w-full md:w-60 md:px-10 cursor-pointer disabled:opacity-50"
                     disabled={!hasVariantForSell}
                     onClick={() => addToCart()}
                  >
                     <MdStringRenderer source={getTranslation('addToCart')} />
                  </button>
                  <div className="visible md:hidden">
                     <ShareComponent {...props} />
                  </div>
                  {prd.productDescription.bodyAst ? (
                     <MarkdownSectionRenderer {...{ ...props, ...{ bodyAst: prd.productDescription.bodyAst, style: '4' } }} />
                  ) : null}
               </div>
            </div>
         </div>
      </>
   );
};

export default ProductDetails;
