import React from 'react';
import { MdStringRenderer, Image } from '@matix/gatsby-theme-core';
import { HeroProps } from '../image-hero.section-renderer';

export const Hero = ({ mainTitle, subTitle, btnMainMd, btnSecondaryMd, heroImage }: HeroProps) => {
   return (
      <div className="relative w-full mt-14 h-56 md:h-hero-md xl:h-hero-xl bg-white overflow-hidden select-none mb-10">
         <div className="relative z-10 h-full w-full">
            <main className="pl-4 md:pl-10 pb-2 md:pb-10 bg-gradient-to-t from-black h-full flex content-end">
               <div className="flex flex-col content-end">
                  {mainTitle ? (
                     <div className="text2xl md:text-4xl py-4 pr-2 pb-1 tracking-tight font-bold text-white mt-auto">
                        <div className="block xl:inline">
                           <MdStringRenderer source={mainTitle} />
                        </div>
                     </div>
                  ) : null}
                  {subTitle ? (
                     <div className="ml-4 p-1 md:pb-2 xl:p-6 xl:pl-0 font-light text-white text-lg md:text-3xl">
                        <MdStringRenderer source={subTitle} />
                     </div>
                  ) : null}
                  {btnMainMd || btnSecondaryMd ? (
                     <div className="flex text-sm font-medium text-white">
                        {btnMainMd ? (
                           <div className="rounded-md shadow">
                              <div className="flex items-center ">
                                 <button
                                    type="button"
                                    className="ml-4 p-1 md:p-2 rounded-md bg-primary-500 text-secondary-500 border-2 border-secondary-500 hover:bg-secondary-500 hover:text-primary-500 hover:border-primary-500"
                                 >
                                    <MdStringRenderer source={btnMainMd} />
                                 </button>
                              </div>
                           </div>
                        ) : null}

                        {btnSecondaryMd ? (
                           <div className="rounded-md shadow">
                              <div className="flex items-center ">
                                 <button
                                    type="button"
                                    className="border-transparent p-1 px-2 md:p-2 ml-4 rounded-md bg-primary-500 text-secondary-500 border-2 border-secondary-500 hover:bg-secondary-500 hover:text-primary-500 hover:border-primary-500"
                                 >
                                    <MdStringRenderer source={btnSecondaryMd} />
                                 </button>
                              </div>
                           </div>
                        ) : null}
                     </div>
                  ) : null}
               </div>
            </main>
         </div>
         <div className="absolute inset-0 z-0">
            <Image className="w-full object-cover h-full " {...heroImage} />
         </div>
      </div>
   );
};
