import React from 'react';
import { FormProps } from '../../form.section-renderer';
import 'react-phone-number-input/style.css';
import { FormHandler } from '../../form-handler';
import c from 'classnames';
export const Form = (props: FormProps) => {
   return (
      <div className={'flex flex-row flex-wrap bg-transparent bg-primary-light text-primary-light space-x-10 w-full z-10'}>
         <FormHandler {...props} className={c(props.className !== '' ? props.className : 'relative bg-opacity-80 rounded z-10 w-full')} />
      </div>
   );
};
export default Form;
