import { MdStringRenderer } from '../../../../md-string-renderer';
import { ImageWithTextProps } from '../image-with-text.section-renderer';
import { CarouselSpring } from '../../../../support/carousel';
import React from 'react';
import c from 'classnames';

export const ImageWithText = (props: ImageWithTextProps) => {
   const { imageWithTextImages, text, title, position = 'bottom', fullscreenApi } = props;
   const slides = imageWithTextImages?.map((p) => ({ image: p, fullscreenApi }));
   return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
         <div className="flex flex-col space-y-10 mb-5 pt-5 pb-10 bg-gray-100 xl:border-opacity-75 xl:grid xl:grid-cols-2">
            <div className={c('lg:row-start-1', 'border-primary-200 border-t-2 w-1/2 mx-auto shadow-lg h-0 lg:col-span-2')}></div>
            {props.title ? (
               <div className={c(`text-2xl md:text-4xl mb-2 font-extrabold text-center`, 'lg:row-start-2 lg:col-span-2')}>
                  <MdStringRenderer source={title} />
               </div>
            ) : null}
            {props.text ? (
               <div
                  className={c(
                     'px-5 font-light mx-auto md:w-2/3',
                     `text-xl text-justify`,
                     position === 'bottom' ? 'order-1' : 'order-2',
                     position === 'right' ? 'lg:row-start-1' : '',
                     position === 'top' || position === 'bottom' ? 'lg:col-span-2 lg:max-w-4xl' : '',
                     position === 'left' || position === 'right'
                        ? 'lg:row-start-3'
                        : position === 'top'
                        ? 'lg:row-start-4'
                        : position === 'bottom'
                        ? 'lg:row-start-3'
                        : ''
                  )}
               >
                  <MdStringRenderer source={text} />
               </div>
            ) : null}

            <div
               className={c(
                  'w-full',
                  position === 'bottom' ? 'order-2' : 'order-1',
                  'row-span-1',
                  position === 'left'
                     ? 'row-start-3 col-start-1'
                     : position === 'right'
                     ? 'col-start-2'
                     : position === 'top'
                     ? 'col-start-1 col-span-2'
                     : position === 'bottom'
                     ? 'col-start-1 col-span-2 row-start-4'
                     : ''
               )}
            >
               {slides ? (
                  <CarouselSpring
                     className="w-full h-full lg:hidden"
                     slides={slides}
                     withThumbnails={true}
                     withControls={true}
                     withLoop={false} /**There is a bug when setting this to true and using thumbnails */
                     fullscreenApi={fullscreenApi}
                  ></CarouselSpring>
               ) : null}
               {slides ? (
                  <CarouselSpring
                     className={c('hidden lg:block', imageWithTextImages?.length === 1 ? 'w-1/2 mx-auto' : '')}
                     slides={slides}
                     withThumbnails={imageWithTextImages?.length > 1}
                     withControls={false}
                     itemsPerSlide={imageWithTextImages?.length > 1 && (position === 'top' || position === 'bottom') ? 2 : 1}
                     withLoop={true} /**There is a bug when setting this to true and using thumbnails */
                     fullscreenApi={fullscreenApi}
                  ></CarouselSpring>
               ) : null}
            </div>
            <div className={c('border-primary-200 border-t-2 w-1/2 mx-auto shadow-lg order-last h-0', 'lg:row-start-4')}></div>
         </div>
      </>
   );
};
