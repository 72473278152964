//TODO: control classes from theme options. Maybe access siteMeta to get for instance the width of the site, the main layout, etc.
import React, { ReactNode } from 'react';
type Props = {
   children: ReactNode;
   className?: string;
};
export const SectionContainer = (props: Props) => {
   return (
      <div className={props.className || 'mx-auto'} {...props}>
         {props.children}
      </div>
   );
};
