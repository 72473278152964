import React from 'react';
import { ShopifySelectRenderer } from '@matix/gatsby-theme-shopify';
import { CheckboxRenderer } from '@matix/gatsby-theme-core';
export const ShadowRenderer = (props) => {
   if (props.field.type === 'shopifySelect') {
      return <ShopifySelectRenderer field={props.field} {...props} />;
   }
   if (props.field.type === 'shopifyCheckbox') {
      return <CheckboxRenderer field={props.field} {...props} />;
   }
   return <></>;
};
