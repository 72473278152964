import React, { useContext, useEffect, useState } from 'react';
export interface StoreContextProps {
   store: { siteMeta; pageQuery; translations; shopCartUrl; pageQueryLocale };
}
// eslint-disable-next-line @typescript-eslint/no-empty-function
const StoreContext = React.createContext<StoreContextProps>(undefined);

export const StoreProvider = (props) => {
   const initialState = {
      siteMeta: props.siteMeta ?? {},
      pageQuery: props.pageQuery ?? {},
      pageQueryLocale: props.pageQueryLocale ?? {},
      translations: props.translations ?? [],
      navigations: props.navigations ?? [],
      shopCartUrl: props.shopCartUrl ?? '',
   };

   const [store, updateStore] = useState(initialState);

   return (
      <StoreContext.Provider
         value={{
            store,
            // DO not allow modification of siteMeta, we provide it in initialState only. Just keeping code as reference.
            // setSiteMeta: (siteMeta) => {
            //    updateStore(
            //       produce((draft) => {
            //          draft.siteMeta = siteMeta;
            //       })
            //    );
            // },
         }}
      >
         {props.children}
      </StoreContext.Provider>
   );
};

export const useStore = () => {
   const context = useContext(StoreContext);
   if (context === undefined) throw new Error('You must use it within a StoreContext provider');
   return context;
};
