import React from 'react';
import c from 'classnames';
import { MdStringRenderer, CarouselHeroThumbnailProps } from '@matix/gatsby-theme-core';

export const CarouselHeroThumbnail = (props: CarouselHeroThumbnailProps) => {
   return (
      <div
         onClick={() => props.onClick()}
         className={c(
            'flex items-center space-x-2 h-full text-white text-xl p-4 md:p-12 border-white bg-transparent hover:bg-yellow-200 hover:bg-opacity-10 cursor-pointer select-none',
            props.isActive(props.id) ? 'border-t-4  border-opacity-100' : 'border-t-4 border-opacity-20'
         )}
      >
         <MdStringRenderer source={props.thumbnailText}></MdStringRenderer>
      </div>
   );
};
