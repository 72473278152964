import React, { useState, useRef } from 'react';

import { Image, CloseSvg } from '@matix/gatsby-theme-core';
import { useCart } from '@matix/gatsby-theme-shopify';

export const CartSummaryItem = ({ lineItem, text }) => {
   const cart = useCart();
   const updateQuantity = (e) => {
      cart.updateLineItem(lineItem.id, e.target.value);
   };

   const getTranslation = (id) => {
      let translation = null;
      text.forEach((element) => {
         if (!translation) {
            translation = element.frontmatter.translations.find((x) => x.identifier.toLowerCase() === id.toLowerCase());
         }
      });
      if (!translation) {
         return 'N/A';
      }
      return translation.value;
   };
   return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      // TODO: gerer quand un produit est en checkout mais que le produit devient backorder ou en statut draft
      <div key={lineItem.id} className="group min-w-0 flex-auto flex flex-row flex-nowrap w-full md:h-80">
         <div className="self-stretch w-2/5 overflow-hidden">
            {lineItem.variant?.image?.src && (
               <Image
                  className="h-auto md:h-full w-full object-contain transition-all transform duration-500 ease-in-out group-hover:scale-105"
                  {...{ url: lineItem.variant?.image?.src }}
               />
            )}
         </div>
         <div className="relative self-stretch flex-1 md:bg-primary-light flex md:h-80 ">
            <div className="flex flex-col justify-between flex-1 md:bg-secondary mt-5 p-3 md:p-10">
               <div className="text-xl font-light tracking-wide ">{lineItem.title}</div>
               <div className="text-base font-light leading-9">
                  {lineItem.variant?.priceV2.currencyCode}$&nbsp;
                  {lineItem.variant?.priceV2.amount}
               </div>
               {lineItem.variant?.selectedOptions.map((so) => {
                  let variantValue = so.value;
                  if (variantValue.startsWith(`${so.name}_`)) {
                     variantValue = variantValue.replace(`${so.name}_`, '');
                  }
                  const nameTranslated = getTranslation(so.name);
                  return so.name?.toLowerCase() !== 'title' ? (
                     <div className="text-base italic font-light uppercase">
                        {nameTranslated}:&nbsp;{variantValue}
                     </div>
                  ) : null;
               })}
               <div className="flex-1"></div>
               <select
                  className={
                     'block bg-primary-light w-full border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-primary focus:text-primary '
                  }
                  name="quantity"
                  value={lineItem.quantity}
                  onChange={(e) => updateQuantity(e)}
               >
                  {[1, 2, 3, 4, 5, 6, 7, 8].map((x) => (
                     <option value={x}>{x}</option>
                  ))}
               </select>
            </div>
            <div className="absolute right-0 top-0 px-2 md:p-5">
               <CloseSvg
                  onClick={() => cart.removeLineItem(lineItem.id)}
                  className="w-10 h-10 text-primary-light transform transition-transform hover:scale-95 hover:-rotate-90"
               />
            </div>
         </div>
      </div>
   );
};
