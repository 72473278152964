import { useStore } from '@matix/gatsby-theme-core';
import React from 'react';
import Helmet from 'react-helmet';
import { FacebookPage } from './facebook-page';

export const ArticleSchema = (props) => {
   const { store } = useStore();
   const { locale, creationDate, modificationDate } = store.pageQuery;
   const { author, publishDate, descriptionSeo, headline, pagePath, titleSeo, image, tags = [] } = store.pageQuery.frontmatter;
   const siteMeta = store.siteMeta;
   const seoShareImageSrc = `${siteMeta.siteUrl}/static/${image?.url}`;
   const schema = {
      '@context': 'http://schema.org',
      '@type': 'Article', // or NewsArticles
      author: {
         '@type': 'Person',
         name: author,
      },
      copyrightHolder: {
         '@type': 'Person',
         name: author,
      },
      copyrightYear: new Date().getUTCFullYear(),
      creator: {
         '@type': 'Person',
         name: author,
      },
      publisher: {
         '@type': 'Organization',
         name: siteMeta.companyName,
         logo: {
            '@type': 'ImageObject',
            url: siteMeta.logo?.url,
         },
      },
      datePublished: publishDate,
      dateModified: publishDate,
      description: descriptionSeo,
      headline: headline,
      inLanguage: locale === 'fr' ? 'french' : locale === 'en' ? 'english' : locale,
      url: siteMeta.siteUrl + pagePath,
      name: titleSeo,
      image: {
         '@type': 'ImageObject',
         url: image?.url,
      },
      mainEntityOfPage: siteMeta.siteUrl + pagePath,
   };

   return (
      <>
         <Helmet>
            <script type="application/ld+json">{JSON.stringify(schema)}</script>
            <Helmet>
               <meta property="og:article:published_time " content={publishDate} />
               <meta property="og:article:modified_time" content={modificationDate} />
               <meta property="og:article:author " content={siteMeta.companyName} />
               {tags.map((tag) => (
                  <meta property="og:article:tag" content={tag} />
               ))}
            </Helmet>
         </Helmet>
         <FacebookPage
            desc={descriptionSeo ?? siteMeta.seo?.descriptionSeo ?? ''}
            image={seoShareImageSrc}
            imageWidth={siteMeta.imageShareWidth}
            imageHeight={siteMeta.imageShareHeight}
            title={titleSeo ?? siteMeta.seo?.titleSeo}
            type={'article'}
            url={siteMeta.siteUrl}
            locale={locale}
            name={siteMeta.companyName}
         />
      </>
   );
};
