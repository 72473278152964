import React, { HTMLProps, lazy, useState } from 'react';
import { MdStringRenderer, isSSR, Upload, useStore, FormStates, FormHandler, FormProps } from '@matix/gatsby-theme-core';
import c from 'classnames';
import { useCart } from '@matix/gatsby-theme-shopify';
import { navigate } from 'gatsby-link';

export const ShopifyForm: React.FC<FormProps> = (props: FormProps) => {
   const { store } = useStore();
   const pagePath = isSSR() ? '/' : window.location.pathname;
   const cart = useCart();
   const [open, toggle] = useState(false);

   const encode = (data) => {
      return Object.keys(data)
         .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
         .join('&');
   };

   const addToCart = async (formValue) => {
      // add a list of form data as customAttributes to the shopify line items
      // it has to have this shape [{key,value}] and key must begin with _, otherwise it will be visible in the checkout page.
      const customAttributes = [];
      const shopifyVariantIds = [];

      Object.keys(formValue).forEach((key) => {
         // expects a field with key shopifyId
         // can be enhanced to support multiple products
         let shopifyId = '';
         if (typeof formValue[key] == 'object') {
            if (formValue[key].hasOwnProperty('shopifyId')) {
               shopifyId = formValue[key]['shopifyId'];
            }
         } else if (key === 'shopifyId') {
            shopifyId = formValue[key];
         }

         if (shopifyId === '') {
            customAttributes.push({ key: `_${key}`, value: formValue[key] });
         } else {
            shopifyVariantIds.push(btoa(shopifyId));
         }
      });

      for (let i = 0; i < shopifyVariantIds.length; i++) {
         const shopifyId = shopifyVariantIds[i];
         await cart.addVariantToCart(shopifyId, 1, customAttributes);
      }
   };

   const handleSubmit = async (e, states: FormStates) => {
      await addToCart(states.formValue);
      fetch('/.netlify/functions/sendMail', {
         method: 'POST',
         headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
         body: encode({ 'form-name': pagePath, ...states.formValue }),
      }).then((e) => {
         if (e.status !== 200) {
            states.setFormStateI({ valid: false });
            // uncomment for testing purpose when sending mail fails
            return;
         } else {
            states.setFormStateI({ valid: true });
            navigate(store.shopCartUrl);
         }
      });
   };

   return (
      <div className={'flex flex-row flex-wrap space-x-10 justify-center space-y-10 w-full'}>
         <div className="w-full overflow-hidden max-h-full">
            <div className="w-full flex justify-center">
               <button
                  className="mx-auto outline-none bg-secondary-500 border-black text-white py-2 px-4 text-xl rounded-full m-4 hover:text-black hover:bg-tertiary-500"
                  onClick={() => toggle(!open)}
               >
                  <MdStringRenderer source={props.title} />
               </button>
            </div>

            <div className={c('overflow-hidden transition-all duration-700', open ? 'h-full' : 'h-0')}>
               <div className="px-3 pb-4 z-10 h-full">
                  <FormHandler
                     className={'relative bg-form text-form rounded px-4 md:px-8 pt-6 pb-8 z-10 border border-form'}
                     handleSubmitOverride={handleSubmit}
                     {...props}
                  />
               </div>
            </div>
         </div>
      </div>
   );
};
