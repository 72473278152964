import { propertyOf } from 'lodash';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from './link';
//import gfm from 'remark-gfm'
export const MdStringRenderer = (props: any) => {
   const linkChild = props.children;
   const renderers = {
      link: (propsLink: any) => {
         return (
            <Link className={props.className} to={propsLink.href}>
               {propsLink.children}
               {linkChild}
            </Link>
         );
      },
      text: (props) => {
         const pg = props.value.split('\\n');
         if (pg.length > 1) {
            return (
               <span>
                  {pg.map((item: string) => {
                     if (item) {
                        return <p>{item}</p>;
                     } else {
                        return <br />;
                     }
                  })}
               </span>
            );
         }
         if (props.value.startsWith('•')) {
            const txt = props.value.replace('•', '');
            // props.node.type = 'HTML';
            return (
               <span className="flex ml-2 md:ml-10">
                  <span>{'•'}&nbsp;</span>
                  <p>{txt}</p>
               </span>
            );
         }

         return props.value;
      },
      em: (props) => <i style={{ color: 'red' }} {...props} />,
   };
   // const components={{
   //    // Map `h1` (`# heading`) to use `h2`s.
   //    h1: 'h2',
   //    // Rewrite `em`s (`*like so*`) to `i` with a red foreground color.
   //    em: ({node, ...props}) => <i style={{color: 'red'}} {...props} />
   //  }}

   return <ReactMarkdown renderers={renderers} children={props.source}></ReactMarkdown>;
};
