import React from 'react';

export const TextAreaRenderer = ({ field, formValue, handleChange, formState, setFormValueI }) => {
   return (
      <textarea
         className={
            'appearance-none bg-formfield text-formfield block w-full border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-formfield-focus focus:text-formfield-focus '
         }
         name={field.label}
         placeholder={field.placeHolder}
         rows={field.numberOfLine ? parseInt(field.numberOfLine) : 1}
         value={formValue[field.key]}
         onChange={(e) => handleChange(e, field.key)}
      />
   );
};
