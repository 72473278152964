// The image overlay offers different control to show image in fullscreen, show social buttons, like, etc.
// the theme core
import React, { useContext } from 'react';
import c from 'classnames';
import { InlineShareButtons } from 'sharethis-reactjs';
import { MagnifierSvg } from './support/icons/awesome-icon/magnifier';
import { Image } from './image';
import { useStore } from '../utils/store-context';

const transitionClass = 'transition-all duration-400 transform ease-in-out';
export const WithImageControls = (props) => {
   const { image, fullscreenApi } = props;
   const {
      store: { siteMeta, pageQuery },
   } = useStore();

   return (
      <>
         <Image {...image} className={props.className} draggable={false}></Image>
         {image.canShare && (
            <>
               {image.title && (
                  <div className={c('absolute bg-opacity-80 left-0 top-0 right-0 z-20 ')}>
                     <div
                        className={c(
                           'p-2 bg-secondary bg-opacity-95 text-sm text-secondary text max-h-24 overflow-ellipsis overflow-hidden whitespace-nowrap -translate-y-24 group-hover:translate-y-0',
                           transitionClass
                        )}
                     >
                        {image.title}
                     </div>
                  </div>
               )}

               <div className={c('group absolute inset-0 -bottom-1 bg-primary-dark z-10 group-hover:bg-opacity-70 bg-opacity-0', transitionClass)}>
                  <div className={c('flex flex-col h-full w-full')}>
                     <div className="flex-1 flex items-center justify-center ">
                        <MagnifierSvg
                           onClick={() => fullscreenApi.enter(image)}
                           className={c(
                              transitionClass,
                              'hidden transform opacity-0 text-primary scale-150 hover:-translate-y-1 hover:text-primary-light z-30 group-hover:opacity-100 group-hover:scale-100 md:block'
                           )}
                        />
                     </div>
                     <div className="flex h-20 justify-center items-end space-x-4 overflow-hidden ">
                        <div className={c(transitionClass, 'flex items-center justify-center translate-y-8 group-hover:-translate-y-5 custom-share')}>
                           <InlineShareButtons
                              config={{
                                 alignment: 'center', // alignment of buttons (left, center, right)
                                 color: 'white', // set the color of buttons (social, white)
                                 enabled: true, // show/hide buttons (true, false)
                                 font_size: 14, // font size for the buttons
                                 labels: null, // button labels (cta, counts, null)
                                 language: props.locale, // which language to use (see LANGUAGES)
                                 networks: siteMeta?.social?.shareNetworks,
                                 padding: 12, // padding within buttons (INTEGER)
                                 radius: 4, // the corner radius on each button (INTEGER)
                                 show_total: false,
                                 size: 30, // the size of each button (INTEGER)

                                 // OPTIONAL PARAMETERS
                                 url: pageQuery.frontmatter?.pagePath ? siteMeta.siteUrl + pageQuery.frontmatter?.pagePath : siteMeta.siteUrl,
                                 image: image.url,
                                 title: image.title,
                                 message: siteMeta.social?.defaultShareMessage ?? siteMeta.seo?.descriptionSeo, // (only for email sharing)
                                 subject: siteMeta.social?.defaultShareSubject ?? siteMeta.companyName, // (only for email sharing)
                                 username: siteMeta.social?.twitterUsername, // (only for twitter sharing)
                              }}
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </>
         )}
      </>
   );
};
