import React from 'react';
import { SlideCarouselSpringProps } from '../interface';
import { WithImageControls } from '../../../with-image-controls';

export interface CarouselSlideDefaultProps extends React.HTMLAttributes<HTMLElement>, SlideCarouselSpringProps {}
export const CarouselSlideDefault = (props: CarouselSlideDefaultProps) => {
   const { fullscreenApi } = props;
   return (
      <div className="h-full w-full flex flex-col cursor-grab active:cursor-grabbing" style={{ touchAction: 'pan-y', userSelect: 'none' }}>
         <div className="h-full w-full overflow-hidden">
            <WithImageControls className="h-full w-full object-cover" {...props} {...{ image: props.image, fullscreenApi }} draggable={false} />
         </div>
         {props.text ? <div className="italic p-5 text-center text-2xl">{props.text}</div> : null}
      </div>
   );
};
