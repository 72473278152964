import React from 'react';

import { MdStringRenderer, useStore } from '@matix/gatsby-theme-core';
import { GroupCards } from './group-cards';
import { Header } from './header';
import { useCollectionStructure } from './use-collection-structure';
import { FilterProvider } from './filterContext';
import { Breadcrumbs } from '../breadcrumbs';
import { Categories } from './categories-tree/categories';
import { OnlyChildCategories } from './categories-tree/only-child-categories';
import { HeadlineShop } from '../headline-shop';
export const ProductCollection = (props) => {
   const { store } = useStore();
   const collections = useCollectionStructure().allMatixShopifyCollection?.nodes.filter((x) => x.locale === store.pageQuery.locale);
   const products = store.pageQuery.frontmatter?.products;

   const filterOptions = collections?.map((x) => x.name);
   const parentCollectionNames = getBreadcrumbsStructure(props.data.pageQuery, []).reverse();
   props.currentParentCollectionNames = parentCollectionNames;
   const colGlobalTranslate = props.data.pageQuery.frontmatter.texts?.find((x) => x.frontmatter?.identifier === 'global-translation')?.frontmatter
      ?.translations;
   const getTranslation = (id) => {
      const result = colGlobalTranslate.find((x) => x.identifier === id);
      if (!result) {
         return 'N/A';
      }
      return result.value;
   };

   let categoryColl = collections;
   const headerCollectionOrder = store.navigations.find((x) => x.frontmatter.identifier === 'headergray');
   if (headerCollectionOrder) {
      let shopifyColl = headerCollectionOrder.frontmatter.navItems.filter((x) => x.type === 'shopifyCollections');
      categoryColl = [];
      shopifyColl.forEach((x) => {
         const currentColl = collections[0].frontmatter.childCollection.find(
            (y) => y.frontmatter.identifier === x.shopifyCollections.frontmatter.identifier
         );
         if (currentColl) {
            categoryColl.push(currentColl);
         }
      });
   }
   return (
      <FilterProvider>
         <div className="px-5 md:px-10 xl:px-0">
            <HeadlineShop {...props.data.pageQuery.frontmatter} />
            <div className="container xl m-auto max-h-full flex flex-col pb-24">
               <div className="bg-white z-10 sticky top-10 py-2 md:top-10">
                  <Breadcrumbs breadcrumbs={parentCollectionNames} />
                  <div className="block md:hidden">
                     <OnlyChildCategories {...props} />
                  </div>
               </div>
               <div className="flex">
                  <div className="hidden pb-28 md:block md:w-40 lg:w-48">
                     <Categories {...{ ...props, ...{ category: categoryColl } }} />
                  </div>
                  <div className="flex-1">
                     <div className="flex flex-col items-center justify-between flex-nowrap text-base md:text-xl md:ml-4 md:flex-row tracking-widest md:font-extrabold ">
                        <div className="py-2 md:pt-0">
                           <MdStringRenderer source={getTranslation('collheadline')} />
                        </div>
                        {/* <Header filterOptions={filterOptions} {...props} /> */}
                     </div>
                     <GroupCards products={products} {...props} />
                  </div>
               </div>
            </div>
         </div>
      </FilterProvider>
   );
};

const getBreadcrumbsStructure = (parentCollection, names) => {
   if (!parentCollection) {
      return names;
   }
   names.push(`[${parentCollection.frontmatter.headline.title}](${parentCollection.frontmatter.pagePath})`);
   return getBreadcrumbsStructure(parentCollection.frontmatter.parentCollection, names);
};

export default ProductCollection;
