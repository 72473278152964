import React, { useContext } from 'react';
import { MdStringRenderer, Time, Duration, Marker, ShareComponent, useStore, Link } from '@matix/gatsby-theme-core';
import { Image } from '@matix/gatsby-theme-core';
import { useCart } from '@matix/gatsby-theme-shopify';
import dateFormat from 'dateformat';
import { navigate } from 'gatsby-link';
import c from 'classnames';

export const Card = (props) => {
   const { store } = useStore();

   const cart = useCart();
   const frontMatterProps = store.pageQuery.frontmatter;

   const eventDate = frontMatterProps.date ? frontMatterProps.date : null;
   let displayDate = null;
   let isPast = null;
   if (eventDate) {
      displayDate = dateFormat(new Date(eventDate), 'yyyy-mm-dd  h:MM TT ( Z )');

      const now = new Date();
      const xDate = new Date(eventDate);
      isPast = eventDate && xDate < now;
   }

   const addToCart = () => {
      const variantId = btoa(frontMatterProps.shopifyId);
      cart.addVariantToCart(variantId, 1).then(() => {
         navigate(store.shopCartUrl);
      });
   };

   return (
      <div className="flex flex-wrap w-full bg-primary-500 shadow-2xl h-full md:h-80 xl:h-96">
         <div className="flex w-full md:w-1/2 xl:w-2/3 h-60 md:h-full">
            <Image {...props.image} className="object-cover w-full h-full" />
         </div>
         <div className="flex w-full md:w-1/2 xl:w-1/3 h-60 md:h-full">
            <div className="flex flex-col w-full p-6 text-black items-center justify-center space-y-2 md:space-y-5 text-xs md:text-base">
               {eventDate && displayDate ? (
                  <div className="flex items-center">
                     <Time className="w-4 mr-2"></Time>
                     <MdStringRenderer source={displayDate.toString()} />
                  </div>
               ) : null}
               {frontMatterProps.duration ? (
                  <div className="flex items-center">
                     <Duration className="w-4 mr-2" />
                     <MdStringRenderer source={frontMatterProps.duration} />
                  </div>
               ) : null}
               {frontMatterProps.location ? (
                  <div className="flex items-center">
                     <Marker className="w-4 mr-2" />
                     <MdStringRenderer source={frontMatterProps.location} />
                  </div>
               ) : null}
               <MdStringRenderer source={props.price} />
               {frontMatterProps.shopifyId ? (
                  <button
                     className={c(
                        'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50',
                        isPast ? 'cursor-not-allowed' : ''
                     )}
                     disabled={isPast}
                     onClick={() => addToCart()}
                  >
                     <MdStringRenderer source={props.btnMainMd} />
                  </button>
               ) : (
                  <div
                     className={c('font-bold py-2 px-4 rounded text-center')}
                  >
                     <MdStringRenderer source={props.btnMainMd} />
                  </div>
               )}
               {props.shareOpt ? <ShareComponent className="custom-share-card" {...props} /> : null}
            </div>
         </div>
      </div>
   );
};

export default Card;
