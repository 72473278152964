/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import { Link } from '../../../../link';

export const config = (extraScopes) => {
   const components = {
      h1: (props) => <h1 className="text-2xl md:text-4xl xl:text-6xl font-bold pb-5 pt-5 text-center" {...props} />,
      h2: (props) => <h2 className="text-2xl md:text-4xl md:font-semibold xl:font-bold pb-3 pt-5 text-center font-serif" {...props} />,
      h3: (props) => <h3 className="px-4 md:text-3xl xl:text-4xl font-semibold pb-3 pt-5" {...props} />,
      h4: (props) => <h4 className="px-4 md:text-2xl xl:text-3xl font-semibold pb-2 pt-5" {...props} />,
      h5: (props) => <h5 className="px-4 md:text-xl xl:text-2xl leading-tight font-bold pb-2 pt-5" {...props} />,
      h6: (props) => <h6 className="px-4 text-xl leading-tight font-bold pb-2 pt-5" {...props} />,
      p: (props) => <p className="px-4 pb-4 text-justify text-base 2xl:text-xl" {...props}></p>,
      a: (props) => <Link to={props.href} className="italic text-blue-500 underline hover:text-blue-700 hover:no-underline" {...props} />,
      blockquote: (props) => (
         <blockquote
            className="bg-primary text-primary  leading-9 text-2xl font-extralight border-l-4 border-black flex my-5 py-5 pl-8 pr-5 italic"
            {...props}
         >
            {props.children}
         </blockquote>
      ),
      ...extraScopes,
   };
   const shortCodes = {
      ...extraScopes,
   };
   return { components, shortCodes };
};
