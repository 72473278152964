import React from 'react';
import Helmet from 'react-helmet';

export const FacebookPage = ({ url, name, type, title, desc, image, imageWidth, imageHeight, locale }) => (
   <Helmet>
      {name && <meta property="og:site_name" content={name} />}
      {/* TODO: Support better localisation, https://developers.facebook.com/docs/internationalization#locales */}
      {/* <meta property="og:locale:alternate" content={locale} /> */}
      <meta property="og:locale" content={locale + '_CA'} />

      <meta property="og:url" content={url} />
      <meta property="og:type" content={type ?? 'website'} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={desc} />
      {image && (
         <>
            <meta property="og:image" content={image} />
            <meta property="og:image:width" content={imageWidth} />
            <meta property="og:image:height" content={imageHeight} />
            <meta property="og:image:alt" content={desc} />
         </>
      )}
   </Helmet>
);
