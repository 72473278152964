import React from 'react';
import { MdStringRenderer } from '@matix/gatsby-theme-core';
import { Image, ImageProps } from '@matix/gatsby-theme-core';

export const CarouselHero = ({ mainTitle, subTitle, btnMainMd, btnSecondaryMd, image }: CarouselHeroProps) => {
   return (
      <div className="relative w-full bg-white overflow-hidden select-none">
         <div className="relative z-10 w-full h-full">
            <div className="flex flex-col bg-gray-100 shadow-xl h-full">
               <div className="z-10 pb-14 pt-14 md:pb-20 md:pt-40 xl:mt-48 xl:pt-20 bg-gradient-to-t from-black h-full flex content-end">
                  <div className="p-10 pb-2 mt-auto xl:p-10 flex flex-col">
                     <div className="text-2xl md:text-4xl tracking-tight font-semibold md:font-bold py-2 xl:py-6 text-white w-full xl:w-2/3">
                        <MdStringRenderer source={mainTitle} />
                     </div>
                     {subTitle ? (
                        <div className="py-2 xl:py-6 text-xl font-semibold text-white">
                           <MdStringRenderer source={subTitle} />
                        </div>
                     ) : null}
                     {btnMainMd ? (
                        <div className="rounded-md py-0 md:py-2">
                           <div className="flex items-center ">
                              <button
                                 type="button"
                                 className="p-1 px-4 md:px-5 md:py-3 rounded-md bg-primary-500 text-secondary-500 border-2 border-secondary-500 hover:bg-secondary-500 hover:text-primary-500 hover:border-primary-500"
                              >
                                 <MdStringRenderer source={btnMainMd} />
                              </button>
                           </div>
                        </div>
                     ) : null}

                     {btnSecondaryMd ? (
                        <div className="rounded-md shadow">
                           <div className="flex items-center ">
                              <button
                                 type="button"
                                 className="border-transparent p-3 px-5 sm:px-8 py-3 rounded-md bg-primary-500 text-secondary-500 border-2 border-secondary-500 hover:bg-secondary-500 hover:text-primary-500 hover:border-primary-500"
                              >
                                 <MdStringRenderer source={btnSecondaryMd} />
                              </button>
                           </div>
                        </div>
                     ) : null}
                  </div>
               </div>
               <div className="absolute inset-0 z-0 -top-10 md:-bottom-40 xl:-top-0 xl:-bottom-48">
                  <Image className="w-full object-cover h-full" {...{ loading: 'eager', fadeIn: false }} {...image} />
               </div>
            </div>
         </div>
      </div>
   );
};

export interface CarouselHeroProps {
   mainTitle: string;
   subTitle: string;
   btnMainMd: string;
   btnSecondaryMd: string;
   image: ImageProps;
}
