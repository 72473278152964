import React from 'react';
import { FormProps } from '../../form.section-renderer';
import 'react-phone-number-input/style.css';
import { FormHandler } from '../../form-handler';

export const Form: React.FC<FormProps> = (props: FormProps) => {
   return (
      <div className={'flex flex-row flex-wrap space-x-10 justify-center space-y-10 w-full py-4 xl:py-8'}>
         <div className="px-3 z-10">
            <FormHandler className={'relative bg-form text-form rounded px-4 md:px-8 pt-6 pb-8 z-10 border border-form'} {...props} />
         </div>
      </div>
   );
};

export default Form;
