import { AnimatedBurger, Image, isSSR, Link, LoopSvg, MagnifierSvg, PageLocaleSwitcher, useNavigations, useStore } from '@matix/gatsby-theme-core';
import React, { useState, useCallback, useRef, useEffect, useContext } from 'react';
import { MdStringRenderer, extractChildMatixAzureImage, SearchBox, MegaMenu } from '@matix/gatsby-theme-core';
import { ScrollContext } from '@matix/gatsby-theme-core';
import { CartButton } from '@matix/gatsby-theme-shopify';
import c from 'classnames';

export const Header = (props) => {
   const { store } = useStore();
   const {
      pageQuery: { locale },
   } = store;
   const companyName = store.siteMeta.companyName;
   // To do : create link section2
   const usedNav = useNavigations();
   const dataLeft = usedNav.allMatixMdx.nodes.find((n) => n.frontmatter.identifier === 'headerLeft' && locale === n.locale)?.frontmatter;
   const dataRight = usedNav.allMatixMdx.nodes.find((n) => n.frontmatter.identifier === 'headerRight' && locale === n.locale)?.frontmatter;
   const megaMenu = usedNav.allMatixMdx.nodes.find((n) => n.frontmatter.identifier === 'mega-menu' && locale === n.locale)?.frontmatter;
   const secondContext = usedNav.allMatixMdx.nodes.find((n) => n.frontmatter.identifier === 'headergray' && locale === n.locale);
   const secondData = secondContext.frontmatter;

   // The mobile menu concatenates all navigations
   let allNav = [].concat(dataLeft.navItems).concat(dataRight.navItems).concat(secondData.navItems);
   const languageSwitcher = allNav.find((m) => m.type === 'languageSwitcher');
   const cartButton = allNav.find((m) => m.type === 'shopifyCartButton');
   const searchButton = allNav.find((m) => m.type === 'search');
   const mobileMenu = usedNav.allMatixMdx.nodes.find((n) => n.frontmatter.identifier === 'headerMobile' && locale === n.locale)?.frontmatter;
   let setIsActiveMegaMenuBurger;
   const imgHeader = extractChildMatixAzureImage(secondContext, []);

   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
   const [isSearchActive, setSearchActive] = useState(false);
   const [isMegaMenuActive, setIsMegaMenuActive] = useState(false);
   const [isSubMenuOpen, setSubMenuOpen] = useState(false);

   const scrollContext = useContext(ScrollContext);
   return (
      <>
         <div className="bg-white w-full xl:mt-0">
            <div
               className={c(
                  'text-black bg-white z-40 w-full md:px-10 ',
                  scrollContext.isTop && !isSearchActive && !isMegaMenuActive ? '' : 'top-0 fixed'
               )}
            >
               <div className="flex flex-col justify-center content-center h-full md:content-start p-2 md:p-0">
                  <div className="flex flex-grow justify-between items-stretch border-gray-100 md:py-2 md:space-x-10">
                     <div className="relative items-center flex justify-items-stretch w-full">
                        <div className="z-20 w-8">
                           <Image className="object-cover" fluid={imgHeader[0].navigationImages[0].childImageSharp.fluid} />
                        </div>
                        <Link className="w-32" to="/">
                           <div className="pl-2 text-xs font-semibold">
                              <MdStringRenderer source={companyName} />
                           </div>
                        </Link>
                        <nav className="hidden md:flex justify-between md:text-xs w-full">
                           <div className="md:flex mr-10 items-center">
                              {dataLeft?.navItems.map((item: any) => {
                                 if (item.type === 'languageSwitcher') {
                                    return (
                                       <PageLocaleSwitcher
                                          className="mx-4 text-xs underline"
                                          targetLocale={languageSwitcher.link}
                                          title={languageSwitcher.title}
                                       ></PageLocaleSwitcher>
                                    );
                                 } else if (item.type === 'megaMenu') {
                                    return (
                                       <AnimatedBurger
                                          render={(burgerState) => {
                                             setIsActiveMegaMenuBurger = burgerState[1];
                                             setIsMegaMenuActive(burgerState[0]);
                                          }}
                                       />
                                    );
                                 } else {
                                    const link = getLink(item);
                                    if (!link) return null;
                                    return (
                                       <Link
                                          onClick={(e) => {
                                             e.preventDefault();
                                             setIsMegaMenuActive(true);
                                          }}
                                          className="mx-4 underline"
                                          to={link}
                                       >
                                          <MdStringRenderer source={item.title} />
                                       </Link>
                                    );
                                 }
                              })}
                           </div>
                           <div className="md:flex ml-10 items-center">
                              {dataRight?.navItems.map((item: any) => {
                                 if (item.type === 'subMenu') {
                                    return (
                                       <div className="group relative">
                                          <div className="cursor-pointer" onClick={() => setSubMenuOpen(true)}>
                                             <MdStringRenderer source={item.title} />
                                          </div>

                                          <div
                                             className={c(
                                                'px-4 py-2 p w-48 absolute  z-50 top-0 -right-1.5 transform shadow-md  bg-white  transition-transform',
                                                isSubMenuOpen ? 'translate-y-0' : '-translate-y-64'
                                             )}
                                          >
                                             <div
                                                className="flex flex-col z-50 text-black space-y-6 text-center"
                                                onMouseLeave={() => setSubMenuOpen(false)}
                                             >
                                                {item.navItems.map((subItem, idx) => {
                                                   const link = getLink(subItem);
                                                   if (!link) return null;
                                                   return (
                                                      <Link key={idx} className="p-2 mx-4 hover:text-white hover:bg-black" to={link}>
                                                         <MdStringRenderer source={subItem.title} />
                                                      </Link>
                                                   );
                                                })}
                                             </div>
                                          </div>
                                       </div>
                                    );
                                 } else if (item.type === 'link') {
                                    const link = getLink(item);
                                    if (!link) return null;
                                    return (
                                       <Link className="mx-4 underline" to={link}>
                                          <MdStringRenderer source={item.title} />
                                       </Link>
                                    );
                                 } else if (item.type === 'shopifyCartButton') {
                                    const link = getLink(item);
                                    if (!link) return null;
                                    return <CartButton to={link} className="w-5 h-5"></CartButton>;
                                 } else if (item.type === 'search') {
                                    return (
                                       <LoopSvg className="mx-4 w-5 h-5" onClick={() => setSearchActive(true)}>
                                          {searchButton.title}
                                       </LoopSvg>
                                    );
                                 }
                              })}
                           </div>
                        </nav>
                     </div>
                     <div className="md:hidden mr-2 flex items-center">
                        {/* mobile header */}
                        {languageSwitcher && (
                           <PageLocaleSwitcher
                              className="mx-4 text-xs"
                              targetLocale={languageSwitcher.link}
                              title={languageSwitcher.title}
                           ></PageLocaleSwitcher>
                        )}
                        {searchButton && (
                           <LoopSvg className="mx-4 w-5 h-5" onClick={() => setSearchActive(true)}>
                              {searchButton.title}
                           </LoopSvg>
                        )}
                        {cartButton && <CartButton to={getLink(cartButton)} className="w-5 h-5"></CartButton>}

                        <AnimatedBurger render={(burgerState) => setIsMobileMenuOpen(burgerState[0])} />
                     </div>
                  </div>
               </div>

               <div
                  className={c(
                     'absolute z-20 top-14 inset-x-0 transition duration-700 transform  ease-in-out md:hidden',
                     isMobileMenuOpen ? 'translate-x-0 ' : '-translate-x-full'
                  )}
               >
                  <div className="rounded-b-lg shadow-lg bg-secondary divide-y-2 divide-gray-500 h-screen overflow-y-auto">
                     <div className="pt-5 pb-16 mb-32 md:mb-0 md:pb-6 px-5">
                        <div className="flex justify-end">
                           <div className="flex flex-col">
                              <span className="text-black text-xl font-bold">
                                 <MdStringRenderer source={companyName} />
                              </span>
                              <div className="text-xs mb-4">
                                 <MdStringRenderer source={`Supply is our business`} />
                              </div>
                           </div>
                        </div>

                        <div className="mt-3">
                           <nav>
                              <div className="divide-y-2">
                                 {mobileMenu?.navItems?.map((item) => {
                                    const className = 'pl-3 py-3 block hover:bg-primary hover:text-primary';
                                    if (item.type === 'subMenu') {
                                       return (
                                          <div>
                                             <div className="pl-3 pt-3 pb-1 block font-semibold">{item.title}</div>
                                             {item.navItems.map((subItem) => {
                                                const link = getLink(subItem);
                                                if (!link) return null;
                                                return (
                                                   <Link className={c(className, 'pl-5 pt-1 pb-1')} to={link}>
                                                      <MdStringRenderer source={subItem.title} />
                                                   </Link>
                                                );
                                             })}
                                          </div>
                                       );
                                    } else {
                                       const link = getLink(item);
                                       if (!link) return null;
                                       return (
                                          <Link className={className} to={link}>
                                             <MdStringRenderer source={item.title} />
                                          </Link>
                                       );
                                    }
                                 })}
                              </div>
                           </nav>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {isSearchActive && <SearchBox close={() => setSearchActive(false)} />}
            {isMegaMenuActive && (
               <MegaMenu
                  navItems={megaMenu.navItems}
                  close={() => {
                     setIsMegaMenuActive(false);
                     setIsActiveMegaMenuBurger(false);
                  }}
               />
            )}
         </div>
         {props.render && props.render([isMobileMenuOpen || isSearchActive, setIsMobileMenuOpen])}
      </>
   );
};

const getLink = (item: any) => {
   let link = item.page?.frontmatter?.pagePath;
   if (!link) {
      link = item.collection?.frontmatter?.pagePath;
   }
   if (!link) {
      link = item.link;
   }
   return link;
};
