import React, { useState, useRef } from 'react';
import { useSpringCarousel } from 'react-spring-carousel-js';
import { CarouselSpringProps } from '../interface';
import { CarouselSlideDefault } from '../carousel-spring/carousel-slide-default';
import { ThumbnailDefault } from '../carousel-spring/thumbnail-default';
import { useInterval } from '../../../../utils/use-interval';
import c from 'classnames';

export const CarouselSlider = (props: CarouselSpringProps) => {
   const {
      className,
      title,
      withControls = false,
      withThumbnails = false,
      withLoop = false,
      slides,
      springConfig,
      itemsPerSlide = 1,
      thumbsSlideAxis = 'x',
      withFullScreenMode = false,
      autoplay = false,
      delay = 5000,
      customThumbnails = false,
   } = props;

   // Augment slide objects with new data for rendering the slides and thumnails
   const getSlidesForCarousel = () =>
      slides?.map((slide, idx) => ({
         ...slide,
         id: idx.toString(),
         renderItem: slide.slideNode ? React.cloneElement(slide.slideNode, { ...slide }) : <CarouselSlideDefault {...slide} />,
         renderThumb: slide.thumbnailNode ? (
            React.cloneElement(slide.thumbnailNode, {
               ...slide,
               id: idx,
               isActive: (id) => {
                  return getIsActiveItem(id?.toString());
               },
               onClick: () => {
                  setActiveSlide(idx);
                  setAutoDelay(null);
                  slideToItem(idx.toString());
               },
            })
         ) : (
            <ThumbnailDefault {...slide} onClick={() => slideToItem(idx.toString())} />
         ),
      }));

   const slideForCarousel = getSlidesForCarousel();
   const { carouselFragment, thumbsFragment, slideToPrevItem, slideToNextItem, slideToItem, enterFullscreen, getIsActiveItem } = useSpringCarousel({
      springConfig,
      itemsPerSlide,
      withThumbs: withThumbnails,
      withLoop,
      thumbsSlideAxis: thumbsSlideAxis,
      initialActiveItem: 0,
      initialStartingPosition: 'start',
      items: slideForCarousel,
   });

   const [state, setState] = useState(0);
   const [activeSlide, setActiveSlide] = useState(0);
   const [autoDelay, setAutoDelay] = useState(delay);
   if (autoplay) {
      useInterval(() => {
         let nextSlide = activeSlide + 1;
         if (nextSlide >= slides.length) {
            nextSlide = 0;
         }
         slideToItem(nextSlide.toString());
         setActiveSlide(nextSlide);
      }, autoDelay);
   }
   if (customThumbnails) {
      thumbsFragment.props.children.props.style = {};
      thumbsFragment.props.children.props.className = className;
   }

   return (
      <div className="w-full h-full relative">
         <div className="relative mx-auto h-full w-full flex flex-row flex-nowrap ">{carouselFragment}</div>
         <div onClick={() => setState(Date.now())} className={c('z-10 flex cursor-pointer', className ? '' : 'absolute bottom-0 left-0 right-0')}>
            {thumbsFragment}
         </div>
      </div>
   );
};
