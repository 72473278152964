import React, { useState } from 'react';
import { MdStringRenderer } from './md-string-renderer';

export const Banner = ({ banner }) => {
   return (
      <>
         {banner ? (
            <div className="flex bg-gray-400 text-gray-700 h-12 w-full justify-center p-2 items-center -z-1">
               <MdStringRenderer source={banner} />
            </div>
         ) : null}
      </>
   );
};
