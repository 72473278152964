import React from 'react';
import { useSpringCarousel } from 'react-spring-carousel-js';
import { CarouselSlide1 } from './carousel-slide1';
import { ThumbnailSpring1 } from './thumbnail-spring1';
import { MdStringRenderer } from '../../../md-string-renderer';
import { animated, useSprings } from 'react-spring';
import { ChevronLeft, ChevronRight, MagnifierSvg } from '../../icons/awesome-icon';
import { InlineShareButtons } from 'sharethis-reactjs';
import { ShareComponent } from '../../../share-components';
const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;
const calc = (x, y) => [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 2) / 20, 1.3];

export const CarouselSpring1 = (props) => {
   const {
      className,
      title,
      withControls = false,
      withThumbnails = false,
      withLoop = false,
      slides,
      thumbsSlideAxis = 'x',
      springConfig,
      itemsPerSlide = 1,
      withFullScreenMode = false,
      siteMeta,
   } = props;

   if (!slides || !slides.length) {
      return null;
   }
   // Augment slide objects with new data for rendering the clides and thumnails
   const getSlidesForCarousel = () =>
      slides?.map((slide, idx) => ({
         ...slide,
         id: idx.toString(),
         renderItem: slide.slideNode ? React.cloneElement(slide.slideNode, { ...slide }) : <CarouselSlide1 {...slide} />,
         renderThumb: slide.thumbnailNode ? (
            React.cloneElement(slide.thumbnailNode, { ...slide, onClick: () => slideToItem(idx.toString()) })
         ) : (
            <ThumbnailSpring1 {...slide} onClick={() => slideToItem(idx.toString())} />
         ),
      }));

   const { carouselFragment, thumbsFragment, slideToPrevItem, slideToNextItem, slideToItem, enterFullscreen } = useSpringCarousel({
      springConfig,
      itemsPerSlide,
      withThumbs: withThumbnails,
      thumbsSlideAxis: thumbsSlideAxis,
      withLoop,
      items: getSlidesForCarousel(),
   });

   // TODO: Reorganize some springs animation and put in matix/ui
   const AnimatedChevron = animated(ChevronLeft);
   const AnimatedChevronRight = animated(ChevronRight);
   const AnimatedMagnifier = animated(MagnifierSvg);
   const [springs, setSprings] = useSprings(3, () => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 } }));
   thumbsFragment.props.children.props.style.flexDirection = 'column';
   return (
      <div className={className}>
         {title ? (
            <div className="text-3xl p-5 text-center lg:p-10 lg:text-6xl">
               <MdStringRenderer source={title} />
            </div>
         ) : null}

         <div className="relative mx-auto md:h-full flex flex-col-reverse md:flex-row flex-nowrap">
            {slides?.length > 1 ? (
               <div className="flex flex-row md:flex-col md:px-0 md:block scrollbar">
                  {thumbsFragment.props?.children?.props?.children.map((thumb, idx) => {
                     return (
                        <div key={idx} className="">
                           {thumb}
                        </div>
                     );
                  })}
               </div>
            ) : null}
            {withFullScreenMode ? (
               <AnimatedMagnifier
                  className="absolute left-5 top-5 z-10 text-red-600"
                  onClick={() => enterFullscreen()}
                  // onMouseMove={() => setSprings((index) => index === 2 && { xys: [0, 0, 1.8] })}
                  // onMouseLeave={() => setSprings((index) => index === 2 && { xys: [0, 0, 1.5] })}
                  // style={{ transform: springs[2].xys.interpolate(trans) }}
               ></AnimatedMagnifier>
            ) : null}
            {withControls && slides?.length > 1 ? (
               <div className="absolute left-5 top-1/3  z-10" onClick={slideToPrevItem}>
                  <AnimatedChevron
                     className="w-7 h-7 lg:h-24 lg:w-24 text-primary bg-black bg-opacity-60 ease-in-out hover:bg-opacity-90 rounded-full shadow-2xl md:block"
                     // onMouseMove={({ clientX: x, clientY: y }) => setSprings((index) => index === 0 && { xys: calc(x, y) })}
                     // onMouseLeave={() => setSprings((index) => index === 0 && { xys: [0, 0, 1] })}
                     // style={{ transform: springs[0].xys.interpolate(trans) }}
                  />
               </div>
            ) : null}
            <div className="flex flex-col justify-center text-center mx-auto md:px-10 w-full max-h-screen">
               <div className="md:mx-auto w-full overflow-hidden">{carouselFragment}</div>
               {/* {carouselFragment} */}
               <div className="hidden md:block">
                  <ShareComponent {...props} className="custom-share" />
               </div>
            </div>
            {withControls && slides?.length > 1 ? (
               <div className="absolute right-5 top-1/3  z-10 " onClick={slideToNextItem}>
                  <AnimatedChevronRight
                     className="w-7 h-7 lg:h-24 lg:w-24  text-primary bg-black bg-opacity-60 ease-in-out hover:bg-opacity-90 rounded-full shadow-2xl md:block"
                     // onMouseMove={({ clientX: x, clientY: y }) => setSprings((index) => index === 1 && { xys: calc(x, y) })}
                     // onMouseLeave={() => setSprings((index) => index === 1 && { xys: [0, 0, 1] })}
                     // style={{ transform: springs[1].xys.interpolate(trans) }}
                  />
               </div>
            ) : null}
         </div>
      </div>
   );
};
