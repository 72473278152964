import React, { useState } from 'react';
import { ImageProps, Card1, Card2, Card3, Card4, Card5, Card6, Card6_1, Card6_2, Card6_3, Card7, Card9, Card10 } from '@matix/gatsby-theme-core';
import { Card as Card11 } from './11/card';
import { Card as Card8 } from './8/card';

import Fade from 'react-reveal/Fade';
import c from 'classnames';
interface Cards {
   style: string;
   cardItems: CardProps[];
}

export interface CardProps {
   title: string;
   text: string;
   btnMainMd: string;
   image: ImageProps;
}

export interface CardsInternalProps extends CardProps {
   idx: number;
}

const getXlGridCol = (props) => {
   if (props.cardItems?.length % 2 === 0) {
      return 'xl:grid-cols-2';
   } else if (props.cardItems?.length === 1) {
      return 'xl:grid-cols-1';
   }
   return 'xl:grid-cols-3';
};
const getMdGridCol = (props) => {
   if (props.cardItems?.length % 2 === 0) {
      return 'md:grid-cols-2';
   } else if (props.cardItems?.length === 1) {
      return 'md:grid-cols-1';
   }
   return 'md:grid-cols-2';
};

export const CardSectionRenderer = (props) => {
   const [revealed, setRevealed] = useState(false);
   return (
      <div className="w-full ">
         {props.style === '1' || props.style === '2' ? (
            <div className={c(`py-4 grid gap-4 grid-flow-row items-stretch grid-cols-1`, getMdGridCol(props), getXlGridCol(props), 'max-w-full')}>
               {props.style === '1' &&
                  props.cardItems?.map((card: CardProps, idx: number) => {
                     // if (!isLoaded) return null;
                     return (
                        <Fade duration={3000} left onReveal={() => setRevealed(true)}>
                           <Card1 {...card} {...idx} />
                        </Fade>
                     );
                  })}
               {props.style === '2' &&
                  props.cardItems?.map((card: CardProps, idx: number) => {
                     // if (!isLoaded) return null;
                     return (
                        <Fade duration={3000} delay={1000} right onReveal={() => setRevealed(true)}>
                           <Card2 {...card} {...idx} />
                        </Fade>
                     );
                  })}
            </div>
         ) : null}
         {props.style === '3' ? (
            <div className={c(`py-4 grid gap-4 grid-cols-1`, getMdGridCol(props), getXlGridCol(props), `max-w-full`)}>
               {props.cardItems.map((card: CardProps, idx: number) => {
                  // if (!isLoaded) return null;
                  return (
                     <Fade onReveal={() => setRevealed(true)}>
                        <Card3 {...card} {...idx} />
                     </Fade>
                  );
               })}
            </div>
         ) : null}
         {props.style === '4' ? (
            <div className={c(`py-4 grid gap-4 grid-cols-1`, getMdGridCol(props), getXlGridCol(props), `max-w-full`)}>
               {props.style === '4' &&
                  props.cardItems?.map((card: CardProps, idx: number) => {
                     // if (!isLoaded) return null;
                     return (
                        <Fade onReveal={() => setRevealed(true)}>
                           <Card4 {...card} {...idx} />
                        </Fade>
                     );
                  })}
            </div>
         ) : null}
         {props.style === '5' ? (
            <div className={c(`py-4 grid gap-4 grid-cols-1`, getMdGridCol(props), getXlGridCol(props), `max-w-full`)}>
               {props.cardItems.map((card: CardProps, idx: number) => {
                  const cardsProps: CardsInternalProps = {
                     ...card,
                     idx: idx + 1,
                  };
                  return (
                     <Fade onReveal={() => setRevealed(true)}>
                        <Card5 {...cardsProps} />
                     </Fade>
                  );
               })}
            </div>
         ) : null}
         {props.style === '6' ? (
            <div className={c(`py-4 grid gap-4 grid-cols-1 `, getMdGridCol(props), getXlGridCol(props), ` max-w-full`)}>
               {props.cardItems.map((card: CardProps, idx: number) => {
                  const cardsProps: CardsInternalProps = {
                     ...card,
                     idx: idx + 1,
                  };
                  return (
                     <Fade onReveal={() => setRevealed(true)}>
                        <Card6 {...cardsProps} />
                     </Fade>
                  );
               })}
            </div>
         ) : null}
         {props.style === '6.1' ? (
            <div className={c(`py-4 grid gap-4 grid-cols-1 `, getMdGridCol(props), getXlGridCol(props), `max-w-full`)}>
               {props.cardItems.map((card: CardProps, idx: number) => {
                  const idx1 = idx + 1;
                  const cardsProps: CardsInternalProps = {
                     ...card,
                     idx: idx1,
                  };
                  return (
                     <Fade bottom duration={3000} delay={idx1 * 250} onReveal={() => setRevealed(true)}>
                        <Card6_1 {...cardsProps} />
                     </Fade>
                  );
               })}
            </div>
         ) : null}
         {props.style === '6.2' ? (
            <div className={c(`py-4 grid gap-4 grid-cols-1`, getMdGridCol(props), getXlGridCol(props), `max-w-full`)}>
               {props.cardItems.map((card: CardProps, idx: number) => {
                  const cardsProps: CardsInternalProps = {
                     ...card,
                     idx: idx + 1,
                  };
                  return (
                     <Fade bottom duration={3000} onReveal={() => setRevealed(true)}>
                        <Card6_2 {...cardsProps} />
                     </Fade>
                  );
               })}
            </div>
         ) : null}
         {props.style === '6.3' ? (
            <div className={c(`py-4 grid gap-4 grid-cols-1`, getMdGridCol(props), getXlGridCol(props), `max-w-full`)}>
               {props.cardItems.map((card: CardProps, idx: number) => {
                  const cardsProps: CardsInternalProps = {
                     ...card,
                     idx: idx + 1,
                  };
                  return (
                     <Fade bottom duration={3000} onReveal={() => setRevealed(true)}>
                        <Card6_3 {...cardsProps} />
                     </Fade>
                  );
               })}
            </div>
         ) : null}
         {props.style === '7' ? (
            <div className={c(`px-2 md:px-0 py-4 grid gap-4  grid-cols-1 `, getMdGridCol(props), getXlGridCol(props), ` max-w-full`)}>
               {props.cardItems.map((card: CardProps, idx: number) => {
                  const cardsProps: CardsInternalProps = {
                     ...card,
                     idx: idx + 1,
                  };
                  return (
                     <Fade onReveal={() => setRevealed(true)}>
                        <Card7 {...cardsProps} />
                     </Fade>
                  );
               })}
            </div>
         ) : null}
         {props.style === '8' ? (
            <div className={c(`px-2 md:px-0 py-4 grid gap-4  grid-cols-1 `, getMdGridCol(props), getXlGridCol(props), ` max-w-full`)}>
               {props.cardItems.map((card: CardProps, idx: number) => {
                  const cardsProps: CardsInternalProps = {
                     ...card,
                     idx: idx + 1,
                  };
                  return (
                     <Fade duration={0} onReveal={() => setRevealed(true)}>
                        <Card8 {...cardsProps} />
                     </Fade>
                  );
               })}
            </div>
         ) : null}
         {props.style === '9' ? (
            <div className={c(`px-2 md:px-0 py-4 grid gap-4 grid-cols-1`, getMdGridCol(props), getXlGridCol(props), ` max-w-full`)}>
               {props.cardItems.map((card: CardProps, idx: number) => {
                  const cardsProps: CardsInternalProps = {
                     ...card,
                     idx: idx + 1,
                  };
                  return (
                     <Fade onReveal={() => setRevealed(true)}>
                        <Card9 {...cardsProps} />
                     </Fade>
                  );
               })}
            </div>
         ) : null}
         {props.style === '10' ? (
            <div className={c(`px-2 md:px-0 py-4 grid gap-4  grid-cols-1`, getMdGridCol(props), getXlGridCol(props), `max-w-full`)}>
               {props.cardItems.map((card: CardProps, idx: number) => {
                  const cardsProps: CardsInternalProps = {
                     ...card,
                     idx: idx + 1,
                  };
                  return (
                     <Fade onReveal={() => setRevealed(true)}>
                        <Card10 {...cardsProps} />
                     </Fade>
                  );
               })}
            </div>
         ) : null}
         {props.style === '11' ? (
            <div className={c(`px-2 md:px-0 py-4 grid gap-4  grid-cols-1`, getMdGridCol(props), getXlGridCol(props), `max-w-full`)}>
               {props.cardItems.map((card: CardProps, idx: number) => {
                  const cardsProps: CardsInternalProps = {
                     ...card,
                     idx: idx + 1,
                  };
                  return (
                     <Fade onReveal={() => setRevealed(true)}>
                        <Card11 {...cardsProps} />
                     </Fade>
                  );
               })}
            </div>
         ) : null}
      </div>
   );
};

export default CardSectionRenderer;
