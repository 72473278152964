import { CloseSvg, Link } from '@matix/gatsby-theme-core';
import React, { useState } from 'react';
import c from 'classnames';
import produce from 'immer';

// Note: This menu only works with 1 level submenu

export const MegaMenu = (props) => {
   const { navItems } = props;
   // logo type act as place holder for image
   const bgImage = navItems.find((nav) => nav.type === 'logo') ?? {
      image: {
         url:
            'https://images.unsplash.com/photo-1492462543947-040389c4a66c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
      },
   };

   const [state, setState] = useState({ subNavItems: [] });

   const updateState = (key, value) => {
      setState(
         produce((draft) => {
            draft[key] = value;
         })
      );
      // console.log(state);
   };

   return (
      <nav className="fixed inset-0 bg-primary-dark z-20 overflow-y-auto">
         <div className="absolute top-14 right-4 md:top-24 md:right-8 z-40">
            <CloseSvg onClick={() => props.close && props.close()} className="w-12 h-12 md:w-24 md:h-24 text-primary-dark hover:opacity-80" />
         </div>
         <div className="absolute inset-0 z-30 text-white">
            <div className="flex mx-auto  h-full pt-40 pb-40 w-1/2 z-30 divide-x divide-opacity-30  divide-white divide-solid">
               <div className="flex-1 text-3xl">
                  {navItems.map((nav) =>
                     nav.type === 'subMenu' && nav.navItems?.length > 0 ? (
                        <div
                           onMouseEnter={() => updateState('subNavItems', nav.navItems)}
                           className={c('flex justify-between py-5 pr-10  cursor-pointer', state.subNavItems === nav.navItems ? 'text-red-500' : '')}
                        >
                           <span>{nav.title}</span>
                           <span>{'>'}</span>
                        </div>
                     ) : nav.type !== 'logo' && nav.navItems === null ? (
                        // When no submenu,
                        <div
                           onMouseEnter={() => updateState('subNavItems', nav.navItems)}
                           className={c('flex justify-between py-5 pr-10  cursor-pointer', state.subNavItems === nav.navItems ? 'text-red-500' : '')}
                        >
                           <Link to={getLink(nav)}>{nav.title}</Link>
                        </div>
                     ) : null
                  )}
               </div>
               <div className="flex-1 text-xl px-10">
                  {state.subNavItems?.map((nav) => (
                     <div className={c('flex justify-between py-5 pr-10 hover:text-red-500')}>
                        <Link to={getLink(nav)}>
                           <span>{nav.title}</span>
                        </Link>
                     </div>
                  ))}
               </div>
            </div>
         </div>
         <div className="absolute bg-gray-50 inset-0 z-0">
            <span
               className="fixed h-full inset-0 bg-cover bg-no-repeat"
               style={{
                  backgroundImage: `url("${bgImage.image.url}")`,
               }}
            ></span>
            <span className="fixed h-full inset-0 bg-black opacity-80 "></span>
         </div>
      </nav>
   );
};
const getLink = (item: any) => {
   let link = item.page?.frontmatter?.pagePath;
   if (!link) {
      link = item.collection?.frontmatter?.pagePath;
   }
   if (!link) {
      link = item.link;
   }
   return link;
};
