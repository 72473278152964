import React from 'react';
import { MdStringRenderer } from '@matix/gatsby-theme-core';

interface HeadlineProps {
   title: string;
   subTitle: string;
}

export const Headline = (props: HeadlineProps) => {
   return (
      <div className="flex flex-col mx-auto w-full text-center">
         <div className="text-5xl mb-4">
            <MdStringRenderer source={props.title} />
         </div>
         <div className="text-2xl mb-4">
            <MdStringRenderer source={props.subTitle} />
         </div>
      </div>
   );
};
