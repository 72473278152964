import React from 'react';
import { Upload } from '../../../support/icons/awesome-icon';

export const FileRenderer = ({ field, formValue, handleChange }) => {
   const getFileName = (key) => {
      if (formValue[`${key}_0_data`] === '') return '';
      if (formValue[`${key}_1_data`]) return `: ${formValue[`${key}_0_name`]} ...`;

      return `: ${formValue[`${key}_0_name`]}`;
   };
   return (
      <label
         className="flex bg-formfield text-formfielditems-center justify-center cursor-pointer appearance-none w-full border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-formfield-focus focus:text-formfield-focus"
         htmlFor="file"
      >
         <Upload className="w-6 mr-2" />
         {field.label}
         {getFileName(field.key)}

         <input id="file" name="file" type="file" className="hidden" multiple={field.multipleFile} onChange={(e) => handleChange(e, field.key)} />
      </label>
   );
};
