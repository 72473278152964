import React, { useState, useEffect, useRef, useMemo } from 'react';

export const ScrollContext = React.createContext({
   isTop: true,
   isScrollingDown: false,
});

export const ScrollProvider = (props) => {
   const [scroll, setIsScrolled] = useState({ isTop: true, isScrollingDown: false });
   const position = useRef(0);

   const scrollListener = () => {
      let isDown = false;
      if (position.current < window.pageYOffset) {
         isDown = true;
      }
      if (window.pageYOffset > 5) {
         setIsScrolled({ isTop: false, isScrollingDown: isDown });
      } else {
         setIsScrolled({ isTop: true, isScrollingDown: isDown });
      }
      position.current = window.pageYOffset;
   };

   useEffect(() => {
      window.addEventListener('scroll', scrollListener);
      window.addEventListener('load', scrollListener);
      return () => {
         window.removeEventListener('scroll', scrollListener);
         window.removeEventListener('load', scrollListener);
      };
   }, []);
   // avoid expensive rerender, only change value when something happens that is different
   // eslint-disable-next-line
   const memoized = useMemo(() => ({ ...scroll }), [scroll.isTop, scroll.isScrollingDown]);

   return <ScrollContext.Provider value={memoized}>{props.children}</ScrollContext.Provider>;
};
