import React from 'react';
import { MdStringRenderer } from '@matix/gatsby-theme-core';
import c from 'classnames';
import { FieldProps, FormBaseProps } from '../form.section-renderer';
import 'react-phone-number-input/style.css';

import { MielBotRenderer } from './miel-bot';
import { TextAreaRenderer } from './text-area-renderer';
import { SelectRenderer } from './select-renderer';
import { FileRenderer } from './file-renderer';
import { CheckboxRenderer } from './checkbox-renderer';
import { TelephoneRenderer } from './telephone-renderer';
import { GenericInputRenderer } from './generic-input-renderer';
import { ShadowRenderer } from './shadow-renderer';
import { CountryRenderer } from './country-renderer';
export const FormRenderer: React.FC<FormBaseProps> = (props: FormBaseProps) => {
   const { formState, formValue } = props;

   return (
      <div>
         <MielBotRenderer {...props} />

         <div className="flex flex-wrap -mx-3 mb-6 z-10">
            {props.fields?.map((field: FieldProps, index) => (
               <div className={c('px-3 mb-6 md:mb-0', field.className ? `${field.className}` : 'w-full')}>
                  <div key={index}>
                     <div className="flex text-xs font-bold mb-2 z-10">
                        {field.type !== 'file' && field.inputType !== 'checkbox' ? (
                           <div className="flex">
                              {field.label ? (
                                 <>
                                    <MdStringRenderer source={field.label} />
                                    {field.required ? <label>&nbsp;{'*'}</label> : null}
                                 </>
                              ) : null}
                           </div>
                        ) : null}
                        {field.required && formValue[field.key] === '' && formState.valid === false && field.inputType !== 'checkbox' ? (
                           <div className="text-xs flex-initial italic text-center text-red-500 ml-8">
                              <MdStringRenderer source={props.requiredMsg} />
                           </div>
                        ) : null}
                     </div>
                     <label htmlFor={field.label} className="hidden" />
                     {field.type === 'textArea' ? <TextAreaRenderer field={field} {...props} /> : null}
                     {field.type === 'select' ? <SelectRenderer field={field} {...props} /> : null}
                     {field.type === 'input' && field.inputType === 'country' ? <CountryRenderer field={field} {...props} /> : null}
                     {field.type === 'file' ? <FileRenderer field={field} {...props} /> : null}
                     {field.type === 'input' && field.inputType != 'country' && field.inputType === 'checkbox' ? (
                        <CheckboxRenderer field={field} {...props} />
                     ) : null}
                     {field.type === 'input' && field.inputType != 'country' && field.inputType === 'tel' ? (
                        <TelephoneRenderer field={field} {...props} />
                     ) : null}
                     {field.type === 'input' && field.inputType != 'country' && field.inputType !== 'checkbox' && field.inputType !== 'tel' ? (
                        <GenericInputRenderer field={field} {...props} />
                     ) : null}
                     <ShadowRenderer field={field} {...props} />
                  </div>
               </div>
            ))}
         </div>
         <div className="flex justify-center">
            <div>
               <button
                  id="submit"
                  className={c(
                     formState.submitting
                        ? 'bg-primary-dark text-primary-dark'
                        : 'bg-primary-light text-secondary-light hover:bg-primary-dark hover:text-primary-dark',
                     'shadow focus:shadow-outline focus:outline-none py-2 px-4 mb-2 rounded'
                  )}
                  type="submit"
                  disabled={formState.submitting}
               >
                  <MdStringRenderer source={props.sendButtonTitle} />
               </button>
            </div>
         </div>
         {formState.valid !== null ? (
            <div className="ajax-response text-center mt-5" id="contact-response">
               {formState.valid === true ? (
                  <MdStringRenderer source={props.successMessage} />
               ) : (
                  <div className="font-bold text-xl text-red-600">
                     <MdStringRenderer source={props.errorMessage} />
                  </div>
               )}
            </div>
         ) : null}
      </div>
   );
};
