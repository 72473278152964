import React, { useContext, useEffect, useRef, useState } from 'react';
import { MdStringRenderer } from '@matix/gatsby-theme-core';
import { SubCategory } from './sub-categories';
import c from 'classnames';

export const OnlyChildCategories = (props) => {
   const childCategories = props.data.pageQuery.frontmatter.childCollection;
   if (!childCategories || !childCategories.length) {
      return null;
   }
   return (
      <div className={c('mx-auto flex justify-center space-x-3 overflow-x-auto')}>
         {childCategories.map((x) => {
            return (
               <div className="text-xs">
                  <MdStringRenderer source={`[${x.frontmatter.headline.title}](${x.frontmatter.pagePath})`} />
               </div>
            );
         })}
      </div>
   );
};
