import React, { useState } from 'react';
import { ImageProps, ImageWithText1, ImageWithText2, ImageWithText3, ImageWithText4, ImageWithText5 } from '@matix/gatsby-theme-core';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

type Position = 'top' | 'bottom' | 'right' | 'left';
export interface ImageWithTextProps {
   title: string;
   text: string;
   position: Position;
   btnMainMd: string;
   imageWithTextImages: ImageProps[];
}

export const ImageWithTextSectionRenderer = (props) => {
   const [revealed, setRevealed] = useState(false);
   return (
      <section className="w-full" key={props.key}>
         {props.style === '1' && (
            <Zoom duration={3000} left onReveal={() => setRevealed(true)}>
               <ImageWithText1 {...props} />
            </Zoom>
         )}
         {props.style === '2' && (
            <Fade left onReveal={() => setRevealed(true)}>
               <ImageWithText2 {...props} />
            </Fade>
         )}
         {props.style === '3' && (
            <Zoom duration={3000} onReveal={() => setRevealed(true)}>
               <ImageWithText3 {...props} />
            </Zoom>
         )}
         {props.style === '4' && (
            <Fade top onReveal={() => setRevealed(true)}>
               <ImageWithText4 {...props} />
            </Fade>
         )}
         {props.style === '5' && (
            <Fade left onReveal={() => setRevealed(true)}>
               <ImageWithText5 {...props} />
            </Fade>
         )}
      </section>
   );
};
