/**
 * Provides a default mechanism to render Sections.
 */
import React, { ReactNode } from 'react';
import { widgetRegistration } from './widget-registration';
import c from 'classnames';
import { useStore } from '../utils';
type Props = {
   children: ReactNode;
   className?: string;
   [propName: string]: any; // TODO: define proper schema
};

// This maps one to one with all the components in the CMS config, search for sections_template for reference in the config.yml file
let widgetReg;
export const SectionRenderer = (props: Props) => {
   const { store } = useStore();
   /**
    * TODO: This is a temporary hack in order to allow the CMS editor to generate a build from netlify in oder to avoid building
    * everytime there is a change. The final solution will probably include GitHubActions to trigger a build based on some conditions.
    */
   const isInCms = props.cms;
   const deploySite = () => {
      fetch(props.cms.deployUrlHook, { method: 'POST' });
      const shouldDeploy = confirm("Le site sera déployé et prendra environ 5 minutes avant d'être disponible! Désirez-vous déployer?");
      shouldDeploy && fetch(props.cms.deployUrlHook, { method: 'POST' });
   };

   const sections = store?.pageQuery?.frontmatter?.sections ?? props.sections;
   widgetReg = props.widgetRegistration ?? widgetRegistration;
   // eslint-disable-next-line react/jsx-no-useless-fragment
   return (
      <>
         {isInCms ? (
            <button className="fixed bottom-0 right-0 z-50 p-3 bg-red-500  hover:bg-red-600 text-white m-2" onClick={() => deploySite()}>
               Déployer
            </button>
         ) : null}
         {sections ? sections.map((s, idx) => renderSection({ ...s, ...{ isInCms } }, idx)) : null}
      </>
   );
};

interface MatixFrontmatterSection {
   type: string;
   components;
   visible?: boolean;
   sectionPath?: string;
   style?: string;
   className?: string;
   isInCms: unknown;
}

function renderSection(section: MatixFrontmatterSection, key?) {
   const style = {
      0: '',
      1: 'mx-auto w-full pb-10 mb-10' /** Full width section mobile & desktop */,
      2: 'mx-auto w-full md:px-10 mb-10',
      3: 'px-2 mx-auto md:px-10 mb-10',
      4: 'px-2 mx-auto container md:px-10 mb-10 max-w-5xl',
      5: 'px-2 mx-auto my-10 md:mx-10 xl:px-36',
      6: 'px-2 mx-auto container py-10 mb-10 px-4 md:px-20 xl:px-80',
      7: 'px-2 mx-auto container py-4 md:p-10 px-4 xl:px-40',
   };

   return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
         {section.visible ? (
            <section key={key} className={c('', section.className ? section.className : '', style[section.style])}>
               {section.components?.map((c) => {
                  if (c.type === 'heroCarousel' && section.isInCms) return null;
                  if (c.type === 'carousel' && section.isInCms) return null;
                  return c.visible && React.cloneElement(widgetReg.widgets[c.type]({ ...c, isInCms: section.isInCms }), { ...c });
               })}
            </section>
         ) : null}
      </>
   );
}
