// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---libs-gatsby-theme-events-src-matix-gatsby-theme-core-components-queries-page-template-query-tsx": () => import("./../../../../../libs/gatsby/theme-events/src/@matix/gatsby-theme-core/components/queries/page-template-query.tsx" /* webpackChunkName: "component---libs-gatsby-theme-events-src-matix-gatsby-theme-core-components-queries-page-template-query-tsx" */),
  "component---src-matix-gatsby-theme-core-components-queries-shopify-cart-template-query-tsx": () => import("./../../../src/@matix/gatsby-theme-core/components/queries/shopify-cart-template-query.tsx" /* webpackChunkName: "component---src-matix-gatsby-theme-core-components-queries-shopify-cart-template-query-tsx" */),
  "component---src-matix-gatsby-theme-core-components-queries-shopify-collection-template-query-tsx": () => import("./../../../src/@matix/gatsby-theme-core/components/queries/shopify-collection-template-query.tsx" /* webpackChunkName: "component---src-matix-gatsby-theme-core-components-queries-shopify-collection-template-query-tsx" */),
  "component---src-matix-gatsby-theme-core-components-queries-shopify-product-template-query-tsx": () => import("./../../../src/@matix/gatsby-theme-core/components/queries/shopify-product-template-query.tsx" /* webpackChunkName: "component---src-matix-gatsby-theme-core-components-queries-shopify-product-template-query-tsx" */)
}

