import { MdStringRenderer } from '../../../../md-string-renderer';
import { ImageWithTextProps } from '../image-with-text.section-renderer';
import { Image } from '../../../../image';
import React from 'react';
import c from 'classnames';

export const ImageWithText = (props: ImageWithTextProps) => {
   const { imageWithTextImages, text, title, position = 'bottom' } = props;
   return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
         <div className="flex flex-col space-y-10 mb-5 pt-5 pb-10 bg-gray-100 xl:border-opacity-75">
            <div className={c('border-primary-200 border-t-2 w-1/2 mx-auto shadow-lg h-0 lg:col-span-2')}></div>
            <div className={c(`text-2xl md:text-4xl font-extrabold text-center mb-2`)}>
               <MdStringRenderer source={title} />
            </div>
            {props.text ? (
               <div
                  className={c(
                     'px-5 font-light mx-auto md:w-2/3',
                     `text-base 2xl:text-xl text-justify`,
                     position === 'bottom' ? 'order-1' : 'order-2',
                     position === 'right' ? 'lg:row-start-1' : '',
                     position === 'top' || position === 'bottom' ? 'lg:col-span-2 lg:max-w-4xl' : '',
                     position === 'left' || position === 'right'
                        ? 'lg:row-start-3'
                        : position === 'top'
                        ? 'lg:row-start-4'
                        : position === 'bottom'
                        ? 'lg:row-start-3'
                        : ''
                  )}
               >
                  <MdStringRenderer source={text} />
               </div>
            ) : null}

            <div className="grid w-full h-full">
               {imageWithTextImages
                  ? imageWithTextImages.map((img) => (
                       <div className="object-cover mx-auto w-full">
                          <Image {...img} />
                       </div>
                    ))
                  : null}
            </div>

            <div className={c('border-primary-200 border-t-2 w-1/2 mx-auto shadow-lg order-last h-0', 'lg:row-start-4')}></div>
         </div>
      </>
   );
};
