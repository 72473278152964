import React from 'react';
import { ImageProps } from '@matix/gatsby-theme-core';
import { Card1 } from '@matix/gatsby-theme-events';
import { Card as Card2 } from './2/card-event';
interface CardsEvent {
   style: string;
   cardItems: CardEventProps[];
}

export interface CardEventProps {
   date: Date;
   duration: string;
   location: string;
   price: string;
   btnMainMd: string;
   shareOpt: boolean;
   image: ImageProps;
   shopifyId: string;
}

export interface CardsInternalProps extends CardEventProps {
   idx: number;
}

export const CardEventSectionRenderer = (props) => {
   return (
      <div className="w-full px-8 md:px-0 pt-2 pb-8 md:py-16">
         <div className="flex w-full mx-auto">{props.style === '1' && <Card1 {...props} />}</div>
         <div className="flex w-full mx-auto">{props.style === '2' && <Card2 {...props} />}</div>
      </div>
   );
};

export default CardEventSectionRenderer;
