import React from 'react';
import { SlideCarouselSpringProps } from '../interface';
import { Image } from '../../../image';
export interface ThumbnailDefaultProps extends React.HTMLAttributes<HTMLElement>, SlideCarouselSpringProps {}
export const ThumbnailDefault = (props: ThumbnailDefaultProps) => {
   return (
      <div className="h-36 w-48 p-5 cursor-pointer" style={{ touchAction: 'pan-x', userSelect: 'none' }} onClick={props.onClick}>
         <Image className="h-full w-full object-cover" {...{ loading: 'eager', fadeIn: false }} {...props.image} />
      </div>
   );
};
