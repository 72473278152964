import React from 'react';
import { Headline as Headline1 } from './1/headline';
import { Headline as Headline2 } from './2/headline';
import Fade from 'react-reveal/Fade';
export const HeadlineSectionRenderer = (props) => {
   return (
      <section className="md:px-10" key={props.key}>
         {props.style === '1' ? (
            <Fade>
               <Headline1 {...props} />{' '}
            </Fade>
         ) : null}
         {props.style === '2' ? (
            <Fade>
               <Headline2 {...props} />{' '}
            </Fade>
         ) : null}
      </section>
   );
};
