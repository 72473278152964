import React from 'react';
import { MdStringRenderer } from '../../../../md-string-renderer';
import { ImageProps, Image } from '../../../../image';
import { Link } from '../../../../link';
interface CardProps {
   title: string;
   text: string;
   btnMainMd: string;
   image: ImageProps;
}

export const Card = (props: CardProps) => {
   const urlFromBtn = props.btnMainMd?.match(/\(([^)]+)\)+$/);

   if (urlFromBtn && urlFromBtn.length) {
      return (
         <Link to={urlFromBtn[1]}>
            <Result {...props} />
         </Link>
      );
   } else {
      return <Result {...props} />;
   }
};

const Result = (props: CardProps) => {
   return (
      <div className="relative flex flex-col text-center md:mx-2 xl:mx-10 text-black">
         <div className="w-16 h-16 mx-auto">
            <Image {...props.image} className="object-cover object-center h-full" />
         </div>
         <div className="flex-1 py-2 text-xl">
            <MdStringRenderer source={props.title} />
         </div>
         {props.text ? (
            <div className="flex-grow font-semibold">
               <MdStringRenderer source={props.text} />
            </div>
         ) : null}
         {props.btnMainMd ? (
            <div className="flex-none py-2 mx-auto flex items-center">
               <button className="my-5 py-2 px-6 rounded-md bg-primary-500 text-secondary-500 hover:bg-secondary-500 hover:text-primary-500">
                  <MdStringRenderer source={props.btnMainMd} />
               </button>
            </div>
         ) : null}
      </div>
   );
};
export default Card;
