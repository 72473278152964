import produce from 'immer';
import React, { useContext, useEffect, useState } from 'react';
/** This Filter is not persisted, use PersistedFilterContext instead */

// eslint-disable-next-line @typescript-eslint/no-empty-function
const FilterContext = React.createContext(undefined);

export const FilterProvider = (props) => {
   const initialState = {
      filter: '',
      order: '',
   };

   const [filter, updateFilter] = useState(initialState);

   return (
      <FilterContext.Provider
         value={{
            filter,
            setFilter: (item) => updateFilter({ filter: item.filter, order: item.order }),
         }}
      >
         {props.children}
      </FilterContext.Provider>
   );
};

export const useFilter = () => {
   const context = useContext(FilterContext);
   if (context === undefined) throw new Error('You must use it within a FilterContext provider');
   return context;
};
