import { CloseSvg, MdStringRenderer, useAlgolia, useStore } from '@matix/gatsby-theme-core';
import { Link } from 'gatsby';
import * as React from 'react';

export const SearchBox = (props) => {
   const {
      store: {
         pageQuery: { locale },
         translations,
      },
   } = useStore();
   const texts = {
      searchText: translations.find((t) => t.identifier === 'searchText')?.value ?? 'Recherche',
      searchPaceholder: translations.find((t) => t.identifier === 'searchPlaceholder')?.value ?? 'Recherche',
      searchNoResults: translations.find((t) => t.identifier === 'searchNoResults')?.value ?? 'Recherche',
   };

   const { isSearching, search, searchResults, hasNoResults } = useAlgolia('60XE3NW05D', '524435e7d17fdb699b59bcaf006673e7', `dev_${locale}`);

   const getMatch = (hits) => {
      // TODO: move this to algolia to decouple from presentation
      // extract all leaves having matchLevel and value property. A leaf has this shape{fullyHighlighted, matchLevel, matchedWords, value}
      // titleSeo is always used as title so we remove it
      const struct = { ...hits };
      delete struct.titleSeo;
      const leaves = [];
      extractLeaves(hits, leaves);

      // search for full match, then partial match or first record
      const fullMatch = leaves.find((l) => l.matchLevel === 'full');
      if (fullMatch) return fullMatch.value;
      const partialMatch = leaves.find((l) => l.matchLevel === 'partial');
      if (partialMatch) return partialMatch;
      return leaves[0];
   };
   return (
      <div className="fixed inset-0 bg-primary-dark z-20 overflow-y-auto">
         <div className="absolute top-14 right-4 md:top-24 md:right-8">
            <CloseSvg onClick={() => props.close && props.close()} className="w-12 h-12 md:w-24 md:h-24 text-primary-dark hover:opacity-80" />
         </div>
         <div className="pt-16 md:pt-52 container px-5 xl:px-0 mx-auto pb-16">
            <div className="text-3xl md:text-4xl xl:text-6xl font-bold text-primary-dark py-10">{texts.searchText}</div>
            <input
               onChange={(event) => search(event.target.value)}
               type="text"
               autoFocus={true}
               className="border w-full md:w-1/3 rounded-full py-2 px-3 mr-4 text-black"
               placeholder={texts.searchPaceholder}
            />
            <div className="pt-10 pl-5 space-y-5">
               {searchResults?.hits?.map((sr) => (
                  <Link to={sr.pagePath}>
                     <div className="group py-1">
                        <div className="text-primary-dark hover:bg-primary-light hover:text-primary-light p-2">
                           <div className="font-bold">
                              <MdStringRenderer source={sr.titleSeo}></MdStringRenderer>
                           </div>
                           <div className="">
                              <MdStringRenderer source={getMatch(sr._snippetResult)}></MdStringRenderer>
                           </div>
                        </div>
                     </div>
                  </Link>
               ))}
            </div>
            {hasNoResults() && <div className="text-primary-dark font-light">{texts.searchNoResults}</div>}
         </div>
      </div>
   );
};

function extractLeaves(hits, leaves) {
   if (typeof hits === 'object' || Array.isArray(hits)) {
      for (const key in hits) {
         const value = hits[key];
         if (value && value.hasOwnProperty('value')) {
            leaves.push(value);
         } else {
            extractLeaves(value, leaves);
         }
      }
   }
}
