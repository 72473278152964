import React, { useState } from 'react';
import { FormProps, FieldProps, FormHandlerOverride } from './form.section-renderer';
import { isSSR } from '../../../utils';
import 'react-phone-number-input/style.css';
import { FormRenderer } from './renderer/form-renderer';

export const FormHandler: React.FC<FormProps & FormHandlerOverride> = (props: FormProps & FormHandlerOverride) => {
   const pagePath = isSSR() ? '/' : window.location.pathname;
   const defaultFormValue =
      props.fields &&
      props.fields
         .filter((x) => x.type !== 'file' && x.type !== 'label')
         .reduce(
            (acc, field: FieldProps) => ({
               ...acc,
               [field.key]:
                  field.inputType === 'checkbox' && field.type != 'shopifyCheckbox'
                     ? false
                     : field.type === 'select'
                     ? field.options[0].value
                     : field.type === 'shopifySelect'
                     ? { value: field.options[0].product.label, shopifyId: field.options[0].product.shopifyId }
                     : field.type === 'shopifyCheckbox'
                     ? { value: false, shopifyId: field.shopifyId }
                     : '',
            }),
            {}
         );
   const defaultFileValue =
      props.fields &&
      props.fields
         .filter((x) => x.type === 'file')
         .reduce((acc, field: FieldProps) => ({ ...acc, [field.key + '_0_data']: '', [field.key + '_0_name']: '', [field.key + '_0_type']: '' }), {});

   const [formValue, setFormValue] = useState({ ...defaultFormValue, ...defaultFileValue });
   const [formState, setFormState] = useState({ mielBot: '', submitting: false, valid: null });

   function setFormStateI(state) {
      setFormState((currentState) => ({ ...currentState, ...state }));
   }

   function setFormValueI(state) {
      setFormValue((currentState) => ({ ...currentState, ...state }));
   }

   const encode = (data) => {
      return Object.keys(data)
         .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
         .join('&');
   };
   const isInputFile = (key) => {
      const inputModified = props.fields.find((x) => x.key === key);
      return inputModified.type === 'file';
   };

   // provide a default change handler. Can be replaced by using a handleChange prop
   const handleChange = (e, key) => {
      if (props.handleChangeOverride) {
         props.handleChangeOverride(e, key, { formValue, formState, setFormStateI, setFormValueI });
         return;
      }
      setFormStateI({ valid: null });
      if (isInputFile(key)) {
         const nbFiles = e.target.files.length;
         for (let i = 0; i < nbFiles; i++) {
            const file_i = e.target.files[i];

            const fileReader = new FileReader();
            fileReader.onloadend = (fileReader, ev) => {
               setFormValueI({ [`${key}_${i}_data`]: fileReader.currentTarget.result });
            };
            fileReader.readAsDataURL(file_i);
            setFormValueI({ [`${key}_${i}_name`]: e.target.files[i].name, [`${key}_${i}_type`]: e.target.files[i].type });
         }
      } else if (e && e.target) {
         let currentValue = formValue[key];
         if (e.target.type !== 'checkbox') {
            if (currentValue.hasOwnProperty('shopifyId')) {
               setFormValueI({
                  [key]: { value: e.target.options[e.target.selectedIndex].label, shopifyId: e.target.options[e.target.selectedIndex].value },
               });
            } else {
               setFormValueI({ [key]: e.target.value });
            }
         } else {
            if (typeof currentValue === 'boolean') {
               // normal checkbox
               setFormValueI({ [key]: e.target.checked });
            } else {
               // shopify checkbox
               setFormValueI({ [key]: { value: e.target.checked, shopifyId: currentValue.shopifyId } });
            }
         }
      } else {
         //phone
         setFormValueI({ [key]: e });
      }
   };

   // provide a default form handler on submit. Can be replaced by using a handleSubmit prop
   const handleSubmit = async (e) => {
      e.preventDefault();
      if (formState.mielBot !== '') {
         return; // form filled by bot.
      }
      for (let i = 0; i < props.fields.length; i++) {
         const field = props.fields[i];
         if (field.required) {
            if (field.type === 'file') {
               if (!formValue[field.key + '_0_data']) {
                  setFormStateI({ valid: false });
                  return;
               }
            } else if (!formValue[field.key]) {
               setFormStateI({ valid: false });
               return;
            }
         }
      }

      setFormStateI({ submitting: true });

      // Run custom logic if override is present

      if (props.handleSubmitOverride) {
         await props.handleSubmitOverride(e, { formValue, formState, setFormStateI, setFormValueI });
         setFormValueI({ ...defaultFormValue, ...defaultFileValue });
         return;
      }
      fetch('/.netlify/functions/sendMail', {
         method: 'POST',
         headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
         body: encode({ 'form-name': pagePath, ...formValue }),
      }).then((e) => {
         if (e.status !== 200) {
            setFormStateI({ valid: false, submitting: false });
            return;
         } else {
            setFormStateI({ valid: true, submitting: false });
         }
         setFormValueI({ ...defaultFormValue, ...defaultFileValue });
      });
   };

   return (
      <form className={props.className} id={props.title} onSubmit={handleSubmit}>
         <FormRenderer
            formState={formState}
            formValue={formValue}
            setFormValueI={setFormValueI}
            setFormStateI={setFormStateI}
            handleChange={handleChange}
            {...props}
         />
      </form>
   );
};

export default FormHandler;
