import React from 'react';
import { MdStringRenderer } from '../../../md-string-renderer';
import PhoneInput from 'react-phone-number-input';
export const TelephoneRenderer = ({ field, formState, formValue, requiredMsg, handleChange, setFormValueI }) => {
   return (
      <div className="flex flex-col">
         {field.required && formValue[field.key] === false && formState.valid === false ? (
            <div className="text-xs flex-initial italic text-center text-red-500 font-bold">
               <MdStringRenderer source={requiredMsg} />
            </div>
         ) : null}
         <div className="flex w-full items-center mb-4 text-xs font-bold">
            <PhoneInput
               defaultCountry="US"
               className="w-auto border rounded py-3 px-4 mr-2 leading-tight focus:outline-none focus:bg-white"
               name={field.label}
               type={field.inputType}
               placeholder={field.placeHolder}
               value={formValue[field.key]}
               onChange={(e) => handleChange(e, field.key)}
            />
         </div>
      </div>
   );
};
