import { MdStringRenderer } from '../../../../md-string-renderer';
import { ImageWithTextProps } from '../image-with-text.section-renderer';
import { Image } from '../../../../image';
import React from 'react';
import c from 'classnames';

export const ImageWithText = (props: ImageWithTextProps) => {
   const { imageWithTextImages, text, title, position = 'bottom', btnMainMd } = props;
   const image = imageWithTextImages.length > 0 ? imageWithTextImages[0] : null;
   return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
         <div className="flex flex-col my-5">
            {title ? (
               <>
                  <div className="text-xl md:text-2xl pt-5 text-center mb-2">
                     <MdStringRenderer source={title} />
                  </div>
                  <div className="border-primary-200 xl:border-opacity-75 border-t-2 w-1/2 mb-5 mx-auto shadow-lg h-0"></div>
               </>
            ) : null}
            <div
               className={c(
                  'flex flex-col md:flex-row bg-primary-500 md:bg-transparent xl:mb-5 xl:pt-0 ',
                  position === 'right' ? 'md:flex-row-reverse' : ''
               )}
            >
               <div className="w-full self-center md:w-1/2 mx-auto xl:w-1/3">
                  <div className="object-cover w-full h-full">
                     <Image {...image} />
                  </div>
               </div>
               {text || btnMainMd ? (
                  <div className={c(position === 'right' ? 'md:mr-6' : 'md:ml-6', 'flex flex-col p-5 mx-auto md:w-full xl:w-2/3 bg-primary-500')}>
                     {text ? (
                        <div className="flex-grow text-base">
                           <MdStringRenderer source={text} />
                        </div>
                     ) : null}
                     {btnMainMd ? (
                        <div className="text-right font-semibold underline">
                           <MdStringRenderer source={btnMainMd} />
                        </div>
                     ) : null}
                  </div>
               ) : null}
            </div>
            <div className="border-primary-200 border-t-2 w-1/2 mt-5 xl:mt-0 mx-auto shadow-lg order-last h-0"></div>
         </div>
      </>
   );
};
