import React, { useState } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { useStore } from '@matix/gatsby-theme-core';

export const CountryRenderer = ({ field, formState, handleChange, setFormValueI }) => {
   const [selected, setSelected] = useState('');
   const { store } = useStore();

   const texts = {
      searchText: store.translations.find((t) => t.identifier === 'searchCountryLabel')?.value ?? 'Sélectionner le pays',
      searchPaceholder: store.translations.find((t) => t.identifier === 'searchPlaceholder')?.value ?? 'Recherche',
   };

   function setSelectedI(code) {
      setSelected(code);
      const e = { target: { value: code, type: 'input' } };
      handleChange(e, field.key);
   }

   return (
      <div className="relative bg-formfield text-formfield w-full border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-formfield-focus focus:text-formfield-focus ">
         <ReactFlagsSelect
            placeholder={texts.searchText}
            searchable
            searchPlaceholder={texts.searchPaceholder}
            selected={selected}
            onSelect={(code) => setSelectedI(code)}
         />
      </div>
   );
};
