import React, { useContext } from 'react';
import { BaseTemplate, StoreProvider, useStore } from '@matix/gatsby-theme-core';
import { ProductCollection } from '../product-collection';

// TODO: a reséparer en single responsability.. ne pas mixer component widget et page template
export const CollectionTemplate = (props) => {
   return (
      <BaseTemplate {...props}>
         <ProductCollection {...props} />
      </BaseTemplate>
   );
};
