import React from 'react';
import { MdStringRenderer, Image } from '@matix/gatsby-theme-core';
import { HeroProps } from '../image-hero.section-renderer';

export const Hero = ({ mainTitle, subTitle, btnMainMd, btnSecondaryMd, heroImage }: HeroProps) => {
   return (
      <div className="relative w-full bg-white overflow-hidden select-none mb-10">
         <div className="relative z-10 mt-44 h-full w-full">
            <main className="pl-10 pt-4 md:pt-10 h-full">
               <div className="flex flex-col justify-evenly">
                  <div className="text-4xl md:text-7xl tracking-tight font-bold text-black">
                     <div className="block xl:inline">
                        <MdStringRenderer source={mainTitle} />
                     </div>
                  </div>
                  <div className="p-6 md:p-10 font-light text-gray-200 text-xl md:text-3xl">
                     <MdStringRenderer source={subTitle} />
                  </div>
                  <div className=" flex flex-col text-base font-medium text-white sm:pt-2 sm:justify-start sm:flex-row md:pb-48">
                     {btnMainMd ? (
                        <div className="rounded-md shadow py-2 sm:py-0 sm px-2">
                           <div className="flex items-center ">
                              <button
                                 type="button"
                                 className="p-3 px-5 sm:px-8 py-3 rounded-md bg-primary-500 text-secondary-500 border-2 border-secondary-500 hover:bg-secondary-500 hover:text-primary-500 hover:border-primary-500"
                              >
                                 <MdStringRenderer source={btnMainMd} />
                              </button>
                           </div>
                        </div>
                     ) : null}

                     {btnSecondaryMd ? (
                        <div className="rounded-md shadow">
                           <div className="flex items-center ">
                              <button
                                 type="button"
                                 className="border-transparent p-3 px-5 sm:px-8 py-3 rounded-md bg-primary-500 text-secondary-500 border-2 border-secondary-500 hover:bg-secondary-500 hover:text-primary-500 hover:border-primary-500"
                              >
                                 <MdStringRenderer source={btnSecondaryMd} />
                              </button>
                           </div>
                        </div>
                     ) : null}
                  </div>
               </div>
            </main>
         </div>
         <div className="absolute inset-0 z-0 xl:-bottom-80">
            <Image className="w-full object-cover h-full" {...heroImage} />
         </div>
      </div>
   );
};
