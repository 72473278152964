import React, { useContext } from 'react';
import { BaseTemplate, useStore, useSiteMeta } from '@matix/gatsby-theme-core';
import { ProductDetails } from '../product-details';
import { HeadlineShop } from '../headline-shop';
import { useCollectionStructure } from '../product-collection/use-collection-structure';
export const ProductTemplate = (props) => {
   const collections = useCollectionStructure().allMatixShopifyCollection?.nodes.filter((x) => x.locale === props.data.pageQuery.locale);
   return (
      <BaseTemplate {...props}>
         <ProductDetails {...props} collections={collections} />
      </BaseTemplate>
   );
};
