import React, { useContext, useEffect, useRef, useState } from 'react';
import { MdStringRenderer } from '@matix/gatsby-theme-core';
import { SubCategory } from './sub-categories';
import c from 'classnames';

export const Categories = (props) => {
   const category = 'COLLECTIONS';

   return (
      <div className={c('hidden md:block mr-4 pb-2 sticky top-40 overflow-y-auto overflow-x-hidden')}>
         {/* Categories columns */}
         <div className="tracking-widest font-extrabold text-xl">
            <MdStringRenderer source={category} />
         </div>
         <SubCategory {...props} {...{ level: 0 }} />
      </div>
   );
};
