import React from 'react';
import { MdStringRenderer } from '@matix/gatsby-theme-core';
import { Image, ImageProps } from '@matix/gatsby-theme-core';

export const CarouselHero = ({ mainTitle, subTitle, btnMainMd, btnSecondaryMd, image }: CarouselHeroProps) => {
   return (
      <div className="relative w-full min-h-90 bg-white overflow-hidden select-none">
         <div className="relative z-10 w-full h-full">
            <div className="flex flex-col shadow-xl h-full">
               <div className="z-10 pt-14 md:pt-40 xl:mt-72 xl:pt-56 bg-gradient-to-t from-black h-full flex content-end">
                  <div className="px-2 pb-2 xl:pb-10 mt-auto xl:px-10 flex flex-col w-full">
                     <div className="text-4xl md:text-4xl xl:text-8xl font-extrabold tracking-tight py-2 xl:py-6 text-primary-dark w-full md:w-1/2">
                        <MdStringRenderer source={mainTitle} />
                     </div>
                     {subTitle ? (
                        <div className="pl-1 xl:pl-2 py-2 xl:py-3 text-base font-semibold text-primary-dark">
                           <MdStringRenderer source={subTitle} />
                        </div>
                     ) : null}
                     {btnMainMd ? (
                        <div className="pl-1 xl:pl-2 flex items-center">
                           <button type="button" className="pr-4 md:pr-5 xl:py-3 rounded-md text-primary-dark italic text-xs md:text-sm">
                              <MdStringRenderer source={btnMainMd} />
                           </button>
                        </div>
                     ) : null}

                     {btnSecondaryMd ? (
                        <div className="pl-1 xl:pl-2 rounded-md shadow ">
                           <div className="flex items-center ">
                              <button type="button" className="px-5 sm:px-8 xl:py-3 rounded-md text-primary-dark italic text-xs md:text-sm">
                                 <MdStringRenderer source={btnSecondaryMd} />
                              </button>
                           </div>
                        </div>
                     ) : null}
                  </div>
               </div>
               <div className="absolute inset-0 z-0 -top-10 md:-bottom-40 xl:-top-0 xl:-bottom-48">
                  <Image className="w-full object-cover h-full" {...{ loading: 'eager', fadeIn: false }} {...image} />
               </div>
            </div>
         </div>
      </div>
   );
};

export interface CarouselHeroProps {
   mainTitle: string;
   subTitle: string;
   btnMainMd: string;
   btnSecondaryMd: string;
   image: ImageProps;
}
